import { BaseApi, getToken } from './apiFactory';

class EmailsApi extends BaseApi {
  history = async (proposalIds: string[]) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/history`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        proposalIds,
      },
    });
  };
}

export const emailsApi = new EmailsApi({ name: 'email' });
