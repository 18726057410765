import { addInfoToEmailCollection } from '@/utils/emailsStatus.jsx';
const sendEmail = async ({
  to,
  replyTo,
  content,
  storeMail,
  subject,
  sub,
  template = 'emails.default',
  title = '',
  fromEmail = 'travel@crewfare.com',
  fromName = 'Crewfare',
  banner = '',
  cc = [],
  attachment,
  versions,
}) => {
  if (banner?.includes('Default_Banner_image.png')) {
    banner = '';
  }
  const randomNumber = Math.random() * 2000;
  await new Promise(r => setTimeout(r, randomNumber));
  if (typeof to === 'string') to = to.toLowerCase().trim();
  fromEmail = fromEmail.toLowerCase().trim();
  const data = {
    content,
    to,
    replyTo,
    subject,
    sub,
    fromEmail,
    fromName,
    title,
    template,
    banner,
    attachment,
    cc,
    versions,
  };
  const emailSent = await fetch(`${import.meta.env.VITE_NEST_API_URL}/email/send`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(response => response.json() || [])
    .catch(error => {
      alert('Some error was occured in sending email, please check console for more details');
      console.error('Error sending/saving email', error);
      return false;
    });
  return emailSent;
};

const processVariables = (content, variables) => {
  if (!variables) return content;
  let newContent = content;
  Object.keys(variables).forEach(key => {
    const regex = new RegExp(`##${key}##`, 'g');
    newContent = newContent.replace(regex, variables[key]);
  });
  return newContent;
};

export { sendEmail, processVariables };
