import store from '@/store';
import { accountsApi } from './apis/accountsApi';

const save = async ({ name, email, phone, hotelId }) => {
  email = email.toLowerCase();
  const data = await accountsApi.addUpdateContact({ name, email, phone, hotelId }, true);
  if (!data.error) {
    store.commit('setToast', {
      content: `<p>Contacts updated for ${email}</p>`,
    });
  }
};

export { save };
