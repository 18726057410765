<template>
    <div class="flex flex-col gap-4 items-center justify-center">
        <img src="/icons/i-check-circle.svg" class="w-16 h-16" />
        <div class="text-3xl font-bold">Upload Successful</div>
    </div>
</template>

<script>
export default {
};
</script>