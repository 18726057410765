import { parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';

const uniqueSortedList = list => {
  return [...new Set(list)]
    .filter(item => !!item)
    .sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
};

function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

const formatPhone = phoneNumber => {
  let formattedPhoneNumber;
  if (!phoneNumber) return '';

  let cleanedPhoneNumber = phoneNumber.replace(/[^0-9+]/g, '');

  if (!cleanedPhoneNumber.startsWith('+')) {
    cleanedPhoneNumber = '+' + cleanedPhoneNumber;
  }

  try {
    formattedPhoneNumber = parsePhoneNumberWithError(cleanedPhoneNumber);
  } catch (e) {
    if (e instanceof ParseError) {
      console.log(e);
    } else {
      throw e;
    }
  }

  return formattedPhoneNumber ? formattedPhoneNumber?.formatInternational()?.replace(/\s+/g, '-') : phoneNumber;
};

export { debounce, formatPhone, uniqueSortedList };
