<template>
  <div class="flex gap-4 py-4">
    <div class="flex flex-col gap-1 w-full">
      <label class="text-white font-bold">Name</label>
      <input
        type="text"
        v-model="contact.name"
        class="w-full bg-gray-700 rounded px-4"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <label class="text-white font-bold">Role</label>
      <input
        type="text"
        v-model="contact.role"
        class="w-full bg-gray-700 rounded px-4"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <label class="text-white font-bold">Email</label>
      <input
        type="text"
        v-model="contact.email"
        :disabled="contact.contact && contact.contact.id"
        class="w-full bg-gray-700 rounded px-4"
      />
    </div>
    <div class="flex flex-col gap-1 w-full">
      <label class="text-white font-bold">Phone</label>
      <input
        type="text"
        v-model="contact.phone"
        class="w-full bg-gray-700 rounded px-4"
      />
    </div>
    <div class="flex items-center">
      <span v-if="isRemoving" >
        <Spinner />
      </span>
      <span
        v-else
        @click="removeContact"
        class="red-button-small material-symbols-rounded"
      >
        delete
      </span>
    </div>
  </div>
</template>

<script>
import { deleteContact } from "@/utils/hotel.jsx";
import Spinner from "../ui/atoms/Spinner.vue";

export default {
  props: ["contact", "hotel_id"],
  components: {Spinner},
  mounted() {},
  data() {
    return {
      isRemoving: false,
    };
  },
  methods: {
    async removeContact() {
      if (!confirm("Are you sure you want to delete this contact?")) return;
      this.isRemoving = true;
      if (this.hotel_id && this.contact?.contact?.id){
        let deletedRes = await deleteContact(this.hotel_id, this.contact.contact);
        if(deletedRes.error){
          this.isRemoving = false;
          console.log(deletedRes.error);
        }
      }
      this.isRemoving = false;
      this.$emit("removeContact", this.contact?.contact?.id);
    },
  },
};
</script>
