<template>
  <tr>
    <td class="px-4 py-2 text-ellipsis overflow-hidden flex flex-col gap">
      <span class="text-sm">
        {{ account.name }}
        &lt;{{ account.email }}&gt;
      </span>
      <span v-if="account.type">
        <span :class="pillColor">
          {{ account.type }}
        </span>
      </span>
    </td>
    <td class="text-sm px-4 capitalize">{{ formatedLastLogin || "-" }}</td>
    <td class="text-sm px-4 capitalize">{{ formatedCreatedAt || "-" }}</td>
    <td class="px-4 py-2">
      <div class="flex gap-4 justify-end">
        <span
          v-if="!account.owner"
          @click="copyLink"
          class="text-crewfareGreen text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 cursor-pointer"
        >
          person_add
        </span>
        <router-link
          v-tippy="'Edit User'"
          :to="{ name: 'usersEdit', params: { id: account.id } }"
          class="text-crewfareGreen text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900"
        >
          edit
        </router-link>
        <button
          v-tippy="'Remove User'"
          @click="removeUser"
          class="text-red-400 text-[18px] flex items-center px-4 border rounded py-1 border-red-400 hover:bg-red-400 hover:text-white"
        >
          <span v-if="isLoading" class="material-symbols-rounded animate-spin text-[18px]">
            autorenew
          </span>
          <span v-else class="material-symbols-rounded text-[18px]">
            delete
          </span>
        </button>

      </div>
    </td>
  </tr>
</template>

<script>
import { AccountTypes } from "@/enums/AccountTypes";
import { accountsApi } from "@/utils/apis/accountsApi";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";

export default {
  props: ["account"],
  name: "User",
  data() {
    return {
      availablePermissions: [
        "Search",
        "RFPs",
        "Own RFPs",
        "Hotels",
        "Users",
        "Messages",
      ],
      isLoading: false,
    };
  },
  computed: {
    pillColor() {
      switch (this.account.type.toLowerCase()) {
        case "contact":
          return "blue-alt-pill";
        case "internal":
          return "yellow-pill";
        case "group manager":
          return "green-alt-pill";
        case "nso":
          return "green-alt-pill";
        case "admin":
          return "green-pill";
      }
    },
    permissions() {
      if (!this.account || !this.account.permissions) return "";
      if (
        this.account.permissions.length ===
        this.availablePermissions.length
      )
        return "All";
      return this.account.permissions?.join(", ") || "";
    },
    formatedLastLogin() {
      if (!this.account.last_login) return "";
      const last_login = getMomentFromFirebaseDate(this.account.last_login);
      return last_login.fromNow();
    },
    formatedCreatedAt() {
      if (!this.account.created_at) return "";
      const created_at = getMomentFromFirebaseDate(this.account.created_at);
      return created_at.fromNow();
    },
    currentAccount(){
      return this.$store.state.account;
    },
    deleteMessage(){
      return this.currentAccount.type === AccountTypes.Super_Admin ? "This will delete user completely from the site, confirm?" : "This will remove user only from current, confirm?"
    }
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(
        `${import.meta.env.VITE_URL}/invite/${this.account.id}`
      );
      this.$store.commit("setToast", {
        content: `<p>Link copied!</p>`,
      });
    },
    async removeUser() {
      if (this.isLoading || !confirm(this.deleteMessage)) {
        return;
      }
      this.isLoading = true;
      const deleted = await accountsApi.remove(this.account.id);
      if(deleted.error){
        alert(deleted.message)
        this.isLoading = false;
        return;
      }
      this.$store.commit("setToast", {
        content: `<p>User removed</p>`,
      });
      if(this.account.email === this.currentAccount.email){
        window.location.href = "";
      }
      this.$emit("getData");
      this.isLoading = false;
    },
  },
};
</script>
