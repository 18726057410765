export const CONTRACT_HINTS = [
  { fieldName: 'hotel_name', description: 'Hotel name' },
  { fieldName: 'hotel_address', description: 'Hotel address' },
  { fieldName: 'hotel_person_name', description: 'Hotel contact name' },
  { fieldName: 'hotel_email', description: 'Hotel email' },
  { fieldName: 'iata', description: 'IATA' },
  { fieldName: 'crewfare_members', description: 'Crewfare members' },
  { fieldName: 'rebate', description: 'Rebate' },
  { fieldName: 'effective_date_formated', description: 'Effective date' },
  { fieldName: 'rfp_name', description: 'RFP name' },
  { fieldName: 'room_list_table', description: 'Room list table' },
  { fieldName: 'room_rate_table', description: 'Room rate table' },
  { fieldName: 'get_room_list_loop', description: 'Room list loop' },
  { fieldName: 'cutoff_days', description: 'Cutoff days' },
  { fieldName: 'get_total', description: 'Total' },
  { fieldName: 'get_total_by_type_loop', description: 'Total by type loop' },
  { fieldName: 'room_rate_loop', description: 'Room rate loop' },
  { fieldName: 'room_types_table', description: 'Room types table' },
  { fieldName: 'reward_points', description: 'Reward points' },
  { fieldName: 'hotel_taxes', description: 'Hotel taxes' },
  { fieldName: 'resort_fee', description: 'Resort fee' },
  { fieldName: 'other_fees', description: 'Other fees' },
  { fieldName: 'group_date_honored', description: 'Group date honored' },
  { fieldName: 'hotel_state_occupancy_tax', description: 'Hotel state occupancy tax' },
  { fieldName: 'hotel_city_tax', description: 'Hotel city tax' },
  { fieldName: 'hotel_convention_tax', description: 'Hotel convention tax' },
  { fieldName: 'hotel_vat_tax', description: 'Hotel vat tax' },
  { fieldName: 'hotel_sat_tax', description: 'Hotel sat tax' },
  { fieldName: 'hotel_municipal_accomodation_tax', description: 'Hotel municipal accommodation tax' },
  { fieldName: 'hotel_hst_tax', description: 'Hotel hst tax' },
  { fieldName: 'hotel_sustaintability_fee', description: 'Hotel sustaintability fee' },
  { fieldName: 'room_type', description: 'Room type' },
];
