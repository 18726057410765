<template>
  <div
    v-if="rfp"
    class="bg-gray-900 w-full pb-4 flex flex-col stage-height print:text-black"
    :class="!account && 'max-w-[1080px] w-full mx-auto'"
  >
    <LoginDialog
      v-if="!account"
    />
    <div
      class="text-xl text-white print:text-black gap-4 flex z-10 px-4 print:px-0 items-center justify-between py-4 bg-gray-900"
      :class="account ? 'sticky top-0 print:top-0 print:relative' : ''"
    >
      <div class="flex gap-4 items-center">
        <button
          v-if="account"
          @click="$router.go(-1)"
          class="material-symbols-rounded hover:text-crewfareGreen print:hidden"
        >
          chevron_left
        </button>
        RFP - {{ rfp.name }} - {{ rfp.hotel_name }}
      </div>
      <ResumeDialog :rfp="rfp" />
    </div>
    <div
      ref="rfpDownload"
      class="flex flex-col px-4 print:px-0 pt-4 gap-4 text-white print:text-black w-full"
    >
      <RFPHeader :rfp="rfp" />
      <p
        v-if="expiredRFP && expiredRFPDate"
        class="text-red-400 text-xl text-center font-bold my-4"
      >
        It seems that the time to bid expired on {{ expiredRFPDate }}. Please reach out to travel@crewfare.com if you would still like to place a bid.
      </p>
      <p v-if="!expiredRFP && isBlocked" class="text-red-300">
        This RFP is blocked at the moment, please let us know if you want to
        make any changes to this RFP.
      </p>
      <RoomList
        :is_blocked="isBlocked"
        :rfp="rfpLoaded"
        :account="account"
        @refreshData="refreshData"
      />
      <hr class="my-4 print:hidden" />
      <div class="flex justify-between">
        <div class="flex flex-col gap-2">
          <span class="text-2xl">Terms</span>
          <p class="text-sm">
            If you find yourself unable to agree to any term, kindly adjust it
            manually by selecting from the available options in the dropdown
            menu. Alternatively, feel free to send a direct comment to our team,
            and we will promptly assist you.
          </p>
        </div>
      </div>
      <div v-if="rfpLoaded" class="grid grid-cols-3 gap-4 w-full">
        <Attrition
          v-if="!hideAcceptedFields || negotiationFields.includes('attrition')"
          :is_blocked="isBlocked"
          :rfp="rfpLoaded"
          :account="account"
          @refreshData="refreshData"
        />
        <CutOffDate
          v-if="
            !hideAcceptedFields || negotiationFields.includes('cutoffDate')
          "
          :is_blocked="isBlocked"
          :rfp="rfpLoaded"
          :account="account"
          @refreshData="refreshData"
        />
        <Comission
          v-if="
              !hideAcceptedFields ||
              arrayContainsAnyFromArray(negotiationFields, ['commission', 'commissionOtherAmount', 'commissionAmount'])
              "
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <Iata
          v-if="!hideAcceptedFields || negotiationFields.includes('IATA')"
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <Rebate
          v-if="!hideAcceptedFields || negotiationFields.includes('rebate')"
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <GroupDateHonored
          v-if="
            !hideAcceptedFields ||
            arrayContainsAnyFromArray(
              negotiationFields,
              ['groupDateHonored', 'groupDateHonoredPost', 'groupDateHonoredPostRate', 'groupDateHonoredPostDays', 'groupDateHonoredPreDays', 'groupDateHonoredPreRate', 'groupDateHonoredPreDays']
            )
          "
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <CompRooms
          v-if="!hideAcceptedFields || negotiationFields.includes('compRooms')"
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <SuiteUpgrades
          v-if="
            !hideAcceptedFields || negotiationFields.includes('suiteUpgrades')
          "
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <RewardPoints
          v-if="
            !hideAcceptedFields || negotiationFields.includes('rewardPoints')
          "
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <StaffRooms
          v-if="
            !hideAcceptedFields || negotiationFields.includes('staffRooms')
          "
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <StaffDiscount
          v-if="
            !hideAcceptedFields || 
            arrayContainsAnyFromArray(negotiationFields, ['staffDiscount', 'staffDiscountAmount'])
          "
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <ResortFee
          v-if="!hideAcceptedFields ||
                arrayContainsAnyFromArray(negotiationFields, ['resortFee', 'resortFeeAmount'])"
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <ReservationSubmission
          v-if="
            !hideAcceptedFields ||
            negotiationFields.includes('reservationSubmission')
          "
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <Billing
          v-if="
                !hideAcceptedFields ||
                arrayContainsAnyFromArray(negotiationFields, ['billing', 'billingsOther'])"
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
        <Incidentals
          v-if="
            !hideAcceptedFields ||
            arrayContainsAnyFromArray(negotiationFields, ['incidentals', 'incidentalsOther'])
          "
          :rfp="rfpLoaded"
          :is_blocked="isBlocked"
          :account="account"
          @refreshData="refreshData"
        />
      </div>
      <div
        v-if="shouldHideFields"
        @click="ignoreHideFields = !ignoreHideFields"
        class="text-center text-xs py-2 text-underline cursor-pointer"
      >
        {{ ignoreHideFields ? "Hide accepted fields" : "View accepted terms" }}
      </div>
      <div
        @click="additionalTerms = !additionalTerms"
        class="px-4 flex cursor-pointer justify-between py-2 border-b"
      >
        <p>Additional terms</p>
        <font-awesome-icon
          :icon="additionalTerms ? 'caret-up' : 'caret-down'"
        />
      </div>
      <div v-show="additionalTerms" class="flex flex-col gap-4">
        <Terms
          :is_blocked="isBlocked"
          :rfp="rfpLoaded"
          :account="account"
          @refreshData="refreshData"
        />
      </div>
      <div
        v-if="$route.query.admin"
        class="flex gap-4 mt-4 justify-between print:hidden"
      >
        <div></div>
        <div class="flex gap-4 items-center flex-col">
          <div class="flex gap-4">
            <button @click="updateBid" class="green-button">
              Update RFP Values
            </button>
          </div>
        </div>
      </div>
      <div v-if="!isBlocked && !$route.query.admin">
        <div
          v-if="account"
          class="flex gap-4 mt-4 justify-between print:hidden"
        >
          <div>
            <span @click="decline" class="red-button">
              <span> Decline Bid </span>
            </span>
          </div>
          <div v-if="contractPhase" class="flex gap-4 items-center flex-col">
            <div class="flex gap-4">
              <button
                @click="undoChanges"
                v-if="rfpSession.history.length > 0"
                class="text-red-400"
              >
                Undo changes
              </button>
              <span
                @click="sendBid"
                :disabled="!valid"
                class="text-white flex items-center justify-center border rounded py-2 px-4"
                :class="
                  hasRooms && (!hasRates || !valid)
                    ? 'bg-gray-700 border-gray-700 text-gray-900 !border-gray-700'
                    : 'border-crewfarePurple hover:bg-crewfarePurple hover:text-white cursor-pointer border-crewfarePurple hover:bg-crewfarePurple text-white print:text-black'
                "
              >
                <span
                  v-if="loading"
                  class="material-symbols-rounded animate-spin flex items-center"
                >
                  autorenew
                </span>
                <span v-else> Update Bid Terms </span>
              </span>
            </div>
          </div>
          <div
            v-else-if="
              ['bid viewed', 'bid opened', 'sent', 'progress saved'].includes(
                rfp.status.toLowerCase()
              )
            "
            class="flex gap-4 items-center flex-col"
          >
            <span v-if="!hasRates && hasRooms" class="text-red-200 text-right">
              To send a bid you need to add the rates.
            </span>
            <div class="flex gap-4">
              <button
                @click="undoChanges"
                v-if="rfpSession.history.length > 0"
                class="text-red-400"
              >
                Undo changes
              </button>
              <button @click="saveSendLater" class="yellow-button">
                <span
                  v-if="isProgressSaving"
                  class="material-symbols-rounded animate-spin flex items-center"
                >
                  autorenew
                </span>
                Save & send later
              </button>
              <span
                @click="sendComments"
                v-if="
                  rfp.notes && rfp.notes.length > 0 && !hasRates
                "
                class="text-white flex items-center justify-center border rounded py-2 px-4 border-crewfarePurple hover:bg-crewfarePurple hover:text-white cursor-pointer border-crewfarePurple hover:bg-crewfarePurple text-white print:text-black"
              >
                <span
                  v-if="loadingComments"
                  class="material-symbols-rounded animate-spin flex items-center"
                >
                  autorenew
                </span>
                <span> Send comments </span>
              </span>
              <span
                v-else
                @click="sendBid"
                :disabled="!valid"
                class="text-white flex items-center justify-center border rounded py-2 px-4"
                :class="{
                  'bg-gray-700 border-gray-700 text-gray-900 !border-gray-700':
                    hasRooms && (!hasRates || !valid),
                  'border-crewfarePurple bg-crewfarePurple hover:text-white cursor-pointer border-crewfarePurple hover:bg-crewfarePurple text-white print:text-black':
                    valid,
                }"
              >
                <span
                  v-if="loading"
                  class="material-symbols-rounded animate-spin flex items-center"
                >
                  autorenew
                </span>
                <span v-else> Send a Bid</span>
              </span>
            </div>
          </div>
          <div v-else class="flex gap-4 items-center flex-col justify-end">
            <div class="flex gap-4 justify-end w-full">
              <button
                @click="undoChanges"
                v-if="rfpSession.history.length > 0"
                class="text-red-400"
              >
                Undo changes
              </button>
              <button @click="saveSendLater" class="yellow-button">
                <span
                  v-if="isProgressSaving"
                  class="material-symbols-rounded animate-spin flex items-center"
                >
                  autorenew
                </span>
                Save & send later
              </button>
              <div
                v-if="
                  ['internal', 'admin'].includes(
                    account.type.toLowerCase()
                  )
                "
                class="flex gap-4"
              >
                <span v-if="hasChanges || hasCounteredLast">
                  <span
                    v-if="hasChanges"
                    @click="sendBid"
                    class="text-white flex items-center justify-center border rounded py-2 px-4"
                    :class="
                      hasRooms && !hasRates
                        ? 'bg-gray-700 border-gray-700 text-gray-900 !border-gray-700'
                        : 'border-crewfarePurple hover:bg-crewfarePurple hover:text-white cursor-pointer border-crewfarePurple hover:bg-crewfarePurple text-white print:text-black'
                    "
                  >
                    <span
                      v-if="loading"
                      class="material-symbols-rounded animate-spin flex items-center"
                    >
                      autorenew
                    </span>
                    <span>{{
                      hasCounteredLast
                        ? "Update Your Counter"
                        : "Send a Counter Bid"
                    }}</span>
                  </span>
                </span>
                <span
                  v-else
                  @click="accept"
                  class="green-button"
                  :class="
                    !hasRates
                      ? '!bg-gray-700 !border-gray-700 !text-gray-900'
                      : 'cursor-pointer hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen'
                  "
                >
                  <span
                    v-if="loadingAccept"
                    class="material-symbols-rounded animate-spin flex items-center"
                  >
                    autorenew
                  </span>
                  <span> Accept Bid </span>
                </span>
              </div>
              <div v-else class="flex gap-4">
                <span v-if="hasChanges || hasCounteredLast">
                  <span
                    v-if="hasChanges"
                    @click="sendBid"
                    class="text-white flex items-center justify-center border rounded py-2 px-4"
                    :class="
                      hasRooms && (!hasRates || !valid)
                        ? 'bg-gray-700 border-gray-700 text-gray-900 !border-gray-700'
                        : 'border-crewfarePurple hover:bg-crewfarePurple hover:text-white cursor-pointer border-crewfarePurple hover:bg-crewfarePurple text-white print:text-black'
                    "
                  >
                    <span
                      v-if="loading"
                      class="material-symbols-rounded animate-spin flex items-center"
                    >
                      autorenew
                    </span>
                    <span v-else>
                      {{
                        hasCounteredLast
                          ? "Update Countered Bid"
                          : "Send a Counter Bid"
                      }}
                    </span>
                  </span>
                </span>
                <div v-else class="flex gap-4">
                  <span
                    @click="sendComments"
                    class="text-white flex items-center justify-center border rounded py-2 px-4 border-crewfarePurple hover:bg-crewfarePurple hover:text-white cursor-pointer border-crewfarePurple hover:bg-crewfarePurple text-white print:text-black"
                  >
                    <span
                      v-if="loadingComments"
                      class="material-symbols-rounded animate-spin flex items-center"
                    >
                      autorenew
                    </span>
                    <span> Send comments </span>
                  </span>
                  <span
                    @click="accept"
                    class="green-button"
                    :class="
                      !hasRates && !valid
                        ? '!bg-gray-700 !border-gray-700 !text-gray-900'
                        : 'cursor-pointer hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen'
                    "
                  >
                    <span
                      v-if="loadingAccept"
                      class="material-symbols-rounded animate-spin flex items-center"
                    >
                      autorenew
                    </span>
                    <span v-else> Accept Bid </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!account && $route.query.accountId"
      class="my-4 flex flex-col text-white print:text-black"
    >
      <p class="text-center mt-4">
        In order to interact with this RFP please log in
        <router-link
          class="underline"
          :to="{
            name: 'invite',
            params: { accountId: $route.query.accountId },
          }"
          >clicking here</router-link
        >
      </p>
    </div>
    <div
      v-if="changesModal"
      class="z-40 fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-[100] bg-black/50"
      ></div>
      <div
        class="bg-gray-900 z-[101] rounded-lg shadow-md py-4 text-white flex flex-col gap-4 justify-center p-4 items-center"
      >
        <p class="text-xl text-center px-4">
          Your requested change has been submitted
        </p>
        <p class="text-sm text-center px-4">
          Our team will get back to you soon in order to move forward with the
          contract.
        </p>
        <div class="flex w-full px-4 justify-between">
          <button @click="changesModal = false" class="cancel-button">
            Dismiss
          </button>
          <router-link
            :to="{
              name: 'rfpContract',
              params: { rfp: rfp.id },
            }"
            class="green-button"
            >Go to contract</router-link
          >
        </div>
      </div>
    </div>
    <AcceptDialog
      :isHotel="isHotel"
      v-if="messageAcceptStatus"
      @close="redirect"
      :rfp="rfp.id"
    />
  </div>
  <LoadingRFP v-if="!rfp" />
</template>

<script>
import AcceptDialog from "@/components/rfp/AcceptDialog.vue";
import Loading from "@/components/default/Loading.vue";
import router from "@/router";
import { firestore } from "@/utils/firebase";
import {
  setDoc,
  doc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import moment from "moment";
import {
  sendNotificationAdmin,
  sendNotificationHotels,
} from "@/utils/notifications.jsx";
import { sendEmail, processVariables } from "@/utils/emails.jsx";
import { setStatusHistory, contractPhase, isRFPExpired, getRFP, saveRfpSession } from "@/utils/rfp.jsx";
import {
  ResumeDialog,
  LoadingRFP,
  RFPHeader,
} from "@/components/rfp";
import {
  RoomList,
  Attrition,
  Billing,
  Breakfast,
  Parking,
  Wifi,
  OtherFees,
  Comission,
  Terms,
  CutOffDate,
  Iata,
  Rebate,
  GroupDateHonored,
  CompRooms,
  SuiteUpgrades,
  RewardPoints,
  StaffRooms,
  StaffDiscount,
  ResortFee,
  ReservationSubmission,
  Incidentals,
} from "@/components/rfp/form/fields";
import LoginDialog from "@/components/default/LoginDialog.vue";
import { getProposal } from "@/utils/proposals";
import { getDateObjFromFirebaseDate, getMomentFromFirebaseDate } from "@/utils/dateUtils";
import _ from "lodash";
import { proposalsApi } from "@/utils/apis/proposalsApi";
import { isCrewfareAccount } from "@/utils/account";
import { NotificationTypes } from "@/enums/NotificationTypes";
import { arrayContainsAnyFromArray } from "@crewfare/commons/src/utils";
import { ViewsLocations } from "@crewfare/server-shared";

export default {
  components: {
    AcceptDialog,
    Loading,
    ResumeDialog,
    LoginDialog,
    RFPHeader,
    RoomList,
    Attrition,
    Billing,
    Breakfast,
    Parking,
    Wifi,
    OtherFees,
    Comission,
    Terms,
    CutOffDate,
    Iata,
    Rebate,
    GroupDateHonored,
    CompRooms,
    SuiteUpgrades,
    RewardPoints,
    StaffRooms,
    StaffDiscount,
    ResortFee,
    ReservationSubmission,
    Incidentals,
    LoadingRFP,
  },
  data() {
    return {
      loadingSendComments: false,
      changesModal: false,
      additionalTerms: false,
      loadingComments: false,
      loading: false,
      loadingAccept: false,
      newAccount: null,
      rfp: null,
      parentRfp: null,
      ignoreHideFields: false,
      hasRooms: null,
      messageAcceptStatus: false,
      messageAccept: "",
      isProgressSaving: false,
    };
  },
  computed: {
    rfpLoaded(){
      if(!this.rfp) return null;
      return {
        ..._.cloneDeep(this.rfp), 
        ...this.rfpSession.field, 
        
      };
    },
    relatedEmails(){
      return [
        ...new Set([...(this.rfp?.relatedEmails||[]), ...(this.rfpParent?.data()?.ccEmails||[])])
      ]
    },
    isInternal() {
      return isCrewfareAccount(this.account);
    },
    isBlocked() {
      if (this.expiredRFP) return true;
      if (this.isNSO) return true;
      if (this.isInternal) {
        if (this.$route.query.admin) {
          return false;
        }
        return ![
          "bid submited",
          "hotel countered",
          "accepted by hotel",
        ].includes(this.rfp.status.toLowerCase());
      }
      if (this.isHotel) {
        return ![
          "sent",
          "bid viewed",
          "bid opened",
          "progress saved",
          "crewfare counter",
          "reopened by crewfare",
          "reopened by hotel",
        ].includes(this.rfp.status.toLowerCase());
      }
    },
    isHotel() {
      if (!this.account) return;
      return ["contact"].includes(this.account.type.toLowerCase());
    },
    contractPhase() {
      return contractPhase.includes(this.rfp.status.toLowerCase());
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    updateRFP() {
      return this.$store.state.updateRFP;
    },
    hasChanges() {
      return this.rfpSession.changes?.length > 0;
    },
    account() {
      return this.$store.state.account;
    },
    valid() {
      if (!this.hasRooms) return true;
      return this.rfp.cutoffDate;
    },
    hasCounteredLast() {
      const roomRateListHistoric = this.rfp.roomListHistoric || [];
      const currentOwner = ["internal", "admin"].includes(
        this.account?.type.toLowerCase()
      )
        ? "crewfare"
        : "hotel";
      //If there's only one entry it's crewfare
      if (roomRateListHistoric.length == 0) {
        return currentOwner === "crewfare";
      }
      const lastEntry = [...roomRateListHistoric].reverse()[0];
      const rfpLastSignedBy = (!lastEntry.account) || (["internal", "admin"].includes(
        lastEntry.account.type.toLowerCase()
      ))
        ? "crewfare"
        : "hotel";
      return currentOwner === rfpLastSignedBy;
    },
    hasRates() {
      const rfpRoomList =
        this.rfpSession.field.roomList || this.rfp.roomList;
      let hasRate = 0;
      let hasName = 0;
      let count = 0;
      rfpRoomList.forEach((days) => {
        days.rooms.forEach((item) => {
          count++;
          if (item.type) {
            hasName++;
          }
          if (item.amount < 1) {
            hasRate++;
          } else if (
            item.value !== undefined &&
            item.value.toString().length > 0
          ) {
            hasRate++;
          }
        });
      });
      return count === hasName && count === hasRate;
    },
    shouldHideFields() {
      return [
        "signed by both sides",
        "signed by the hotel",
        "signed by crewfare",
        "hotel countered",
        "crewfare counter",
        "accepted by hotel",
        "accepted by crewfare",
        "declined by hotel",
        "declined by crewfare",
        "reopened by crewfare",
        "reopened by hotel",
        "bid submited",
      ].includes(this.rfp.status.toLowerCase());
    },
    hideAcceptedFields() {
      if (this.ignoreHideFields) return false;
      return this.shouldHideFields;
    },
    negotiationFields() {
      const changes = this.rfp.changes || [];
      const notes = this.rfp.notes || [];
      const acceptedFields = [...new Set([...changes, ...notes])];
      return acceptedFields;
    },
    isNSO() {
      if (!this.account) return;
      return this.account.type.toLowerCase() === "nso";
    },
    isContact() {
      if (!this.account) return;
      return this.account.type.toLowerCase() === "contact";
    },
    expiredRFP() {
      return this.parentRfp && isRFPExpired(this.parentRfp);
    },
    expiredRFPDate() {
      if (!this.parentRfp) return null;
      const date = getDateObjFromFirebaseDate(this.parentRfp.bidExpirationDate || this.parentRfp.startDate).toLocaleDateString();
      if (this.parentRfp.bidExpirationDate || this.parentRfp.startDate) return date;
      return null;
    },
  },
  watch: {
    updateRFP() {
      if (this.updateRFP) {
        this.getData();
        this.$store.commit("setUpdateRFP", false);
      }
    },
  },
  async mounted() {
    await this.$store.commit("resetRfpSession");
    this.loading = true;
    this.getData();
    document.title = `Launchpad - Crewfare - RFP - View`;
  },
  methods: {
    arrayContainsAnyFromArray( container, values ) {
      return arrayContainsAnyFromArray( container, values );
    }, 
    getEmptyRFPSession(){
      return {
        id: "",
        field: {},
        changes: [],
        history: [],
      }
    },
    validateResponseDueDate() {
      if (this.isRFPExpired) {
        this.$router.push("/rfps")
        return;
      }
    },
    redirect() {
      if (
        ["admin", "internal"].includes(this.account?.type.toLowerCase())
      ) {
        router.push({
          name: "rfpsHotels",
          params: { id: this.rfp.rfp },
        });
      } else {
        router.push({ name: "rfpAccepted", params: { rfp: this.rfp.id } });
      }
    },
    print() {
      window.print();
    },
    undoChanges() {
      this.$store.commit("setRfpSession", { rfpSession: this.getEmptyRFPSession(), rfp: this.rfp, account: this.account });
    },
    async saveSendLater() {
      this.validateResponseDueDate();
      try {
        this.isProgressSaving = true;
        await saveRfpSession({
          rfp: this.rfp,
          account: this.account,
          rfpSession: this.rfpSession,
        })
        this.isProgressSaving = false;
        this.$store.commit("setToast", {
          content: `<p>Progress saved, you can go back and send later.</p>`,
        });
      } catch (e) {
        this.errorMessage("saveSendLater", e);
      }
    },
    async sendComments() {
      this.validateResponseDueDate();
      this.loadingComments = true;
      try {
        this.$store.commit("setToast", {
          content: `<p>Comments sent!</p>`,
        });
        router.push({ name: "rfpsList" });
      } catch (e) {
        this.errorMessage("saveSendLater", e);
      }
      this.loadingComments = false;
    },
    async setData() {
      this.startDate = getDateObjFromFirebaseDate(this.rfp.startDate);
      this.startDateFormated = getMomentFromFirebaseDate(
        this.rfp.startDate
      ).format("MM-DD-YYYY");
      this.endDateFormated = getMomentFromFirebaseDate(this.rfp.endDate).format(
        "MM-DD-YYYY"
      );
      this.hasRooms = this.rfp.hasRooms;
      if (this.rfp.hasRooms === undefined) this.hasRooms = true;
    },
    async refreshData() {
    },
    async getData() {
      if (!this.isInternal && this.$route.query.admin) {
        this.$router.push("/rfps");
        return;
      }
      this.rfp = await getProposal(this.$route.params.rfp, true);
      this.$store.commit("setGroupId", this.rfp.userGroupId)
      this.parentRfp = await getRFP(this.rfp.rfp);
      if(!this.rfp) {
        this.$router.push("/rfps");
        return;
      }
      const sessionId = `${this.rfp.id}-${this.account.id}${this.$route.query.admin? "_admin":""}`;
      const rfpSession = await getDoc(
        doc(firestore, "rfp_tmp", sessionId)
      );
      if(rfpSession.exists() && rfpSession.data().rfpSession){
        this.$store.commit("setRfpSession", {rfpSession: rfpSession.data().rfpSession, rfp: this.rfp, account: this.account});
      }
      this.validateResponseDueDate();
      this.$store.commit("setCurrentRfp", {...this.rfp})
      if (
        this.account &&
        ["contact", "nso"].includes(this.account.type.toLowerCase()) &&
        this.rfp.status.toLowerCase() === "sent"
      ) {
        let data = _.cloneDeep(this.rfp);
        if (data.status.toLowerCase() === "sent") {
          data = await setStatusHistory({
            data,
            content: "Status change",
            status: "Bid Viewed",
            route: this.$route,
          });
          await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), {
            ...data,
            status: "Bid Viewed",
            updated_at: new Date(),
          });
        }
      }
      if (this.$route.query.accountId) {
        let data = _.cloneDeep(this.rfp);
        const accountRef = doc(
          firestore,
          "accounts",
          this.$route.query.accountId
        );
        this.newAccount = await getDoc(accountRef);
        if (["sent", "bid viewed"].includes(data.status.toLowerCase())) {
          data = await setStatusHistory({
            data,
            content: "Status change",
            status: "Bid Opened",
            route: this.$route,
          });
          await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), {
            ...data,
            status: "Bid Opened",
            updated_at: new Date(),
          });
        }
      }
      this.setData();
      this.loading = false;
    },
    async clearSession(){
      return saveRfpSession({
        rfp: this.rfp,
        account: this.account,
        rfpSession: this.getEmptyRFPSession(),
      });
    },
    async updateBid(){
      if(this.loading) return;
      this.loading = true;
      const updated = await proposalsApi.update(this.rfp.id, this.rfpSession.field);
      if(updated.error){
        alert(updated.message || "There was an error updating the bid");
      }
      else{
        this.$store.commit("setToast", {
          content: `<p>Bid updated!</p>`,
        });
        router.push({ name: "rfpBid", params: { rfp: this.rfp.id } });
        this.clearSession();
      }
      this.loading = false;
    },
    async sendBid() {
      this.validateResponseDueDate();
      if (!this.valid) return;
      try {
        this.loading = true;
        const response = await proposalsApi.bid(this.rfp.id, this.rfpSession.field);
        if(response.error){
          alert(response.message || "There was an error submitting the bid");
          this.loading = false;
          return;
        }
        this.loading = false;
        (!this.isInternal) && sendNotificationAdmin({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          content: `A new bid has been submitted for RFP ${
            this.rfp.name
          } - ${this.rfp.hotel_name} by ${
            this.account?.name
          }`,
          to: { name: ViewsLocations.BID_FORM, params: { id: this.rfp.id, rfp: this.rfp.id } },
        });
        (this.isInternal) && sendNotificationHotels({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          ids: [this.rfp.hotel_id],
          content: `A new bid has been submitted for RFP ${
            this.rfp.name
          } - ${this.rfp.hotel_name} by ${
            this.account?.name
          }`,
          to: { name: "rfpView", params: { rfp: this.rfp.id } },
        });
        router.push({ name: "rfpBid", params: { rfp: this.rfp.id } });
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.errorMessage("sendBid", e, data);
      }
    },
    async accept() {
      this.validateResponseDueDate();
      if (!this.valid) return;
      if (!this.hasRates) return;
      try {
        this.loadingAccept = true;
        const data = await proposalsApi.accept(this.rfp.id)
        if(data.error){
          alert(data.message || "There was an error accepting the bid");
          this.loadingAccept = false;
          return;
        }
        const updatedProposal = data.data.proposal;
        (!this.isInternal) && sendNotificationAdmin({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          content: `RFP ${
            this.rfp.name
          } - ${this.rfp.hotel_name} status has been updated to ${updatedProposal.status} by ${
            this.account?.name
          }`,
          to: { name: ViewsLocations.CONTRACT, params: { id: this.rfp.id, rfp: this.rfp.id } },
        });
        (this.isInternal) && sendNotificationHotels({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          ids: [this.rfp.hotel_id],
          content: `RFP ${
            this.rfp.name
          } - ${this.rfp.hotel_name} status has been updated to ${updatedProposal.status} by ${
            this.account?.name
          }`,
          to: { name: "rfpContract", params: { rfp: this.rfp.id } },
        });
        this.messageAcceptStatus = true;
        this.loadingAccept = false;
      } catch (e) {
        this.errorMessage("accept", e);
      }
    },
    errorMessage(method, e, data) {
      sendEmail({
        to: import.meta.env.ERROR_REPORTS_AT,
        subject: `error - ${method}`,
        content: `<p>${JSON.stringify(
          this.account
        )}</p><p>${JSON.stringify(data)}<p>${e.message}</p><p>${JSON.stringify(
          e
        )}`,
      });
    },
    async decline() {
      router.push({ name: "rfpDeclined", params: this.$route.params });
    },
  },
};
</script>
