<template>
  <div
    v-if="rfp && hotel && account"
    class="bg-gray-800 w-full pb-4 flex flex-col stage-height print:text-black"
  >
    <div class="print:hidden sticky z-10 top-0 p-4 bg-gray-900">
      <div class="text-xl text-white gap-4 flex items-center justify-between">
        <div class="flex gap-2">
          <button
            v-if="!isCrewfare"
            @click="$router.go(-1)"
            class="material-symbols-rounded hover:text-crewfareGreen print:hidden"
          >
            chevron_left
          </button>
          RFP - {{ rfp.data().name }} - {{ rfp.data().hotel_name }} - Contract
        </div>
        <div class="flex gap-4 text-sm">
          <a
            v-if="rfp.data().pdfLink"
            :href="rfp.data().pdfLink"
            target="_blank"
            class="text-sm whitespace-nowrap"
          >
            <span v-if="downloadingContract" class="flex items-center gap-2">
              <span class="material-symbols-rounded animate-spin text-[14px]">
                autorenew
              </span>
              Downloading agreement
            </span>
            <span v-else class="underline">Download Agreement</span>
          </a>
        </div>
      </div>
      <div
        class="bg-gray-900 py-2 flex justify-between text-white items-center"
      >
        <div class="flex gap-4">
          <span v-if="isHotel || isCrewfare" class="flex text-xs">
            <span
              @click="toggleContractMethod"
              class="border cursor-pointer rounded-l border-crewfareGreen px-2 py-1"
            >
              Use Crewfare Contract
            </span>
            <span
              class="border rounded-r bg-crewfareGreen text-gray-900 border-crewfareGreen px-2 py-1"
            >
              Use External Contract
            </span>
          </span>
          <ResumeDialog :rfp="rfp" />
        </div>
        <div class="flex gap-4 flex-col justify-end">
          <div class="flex gap-4">
            <a
              v-if="url"
              :href="url"
              target="_blank"
              class="disabled:text-crewfareGreen disabled:bg-gray-600 disabled:border-gray-600 flex gap-2 text-crewfareGreen flex items-center border rounded py-1 px-2 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 relative"
              download
            >
              <span class="material-symbols-rounded"> download </span>
              Download Current File
            </a>
            <button
              v-if="isHotel || isCrewfare"
              :disabled="upload"
              @click="$refs['fileUpload'].click()"
              class="disabled:text-crewfareGreen disabled:bg-gray-600 disabled:border-gray-600 flex gap-2 text-crewfareGreen flex items-center border rounded py-1 px-2 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 relative"
            >
              <span
                class="material-symbols-rounded"
                :class="upload && 'animate-spin'"
              >
                {{ upload ? "autorenew" : "upload" }}
              </span>
              Upload New PDF/DOC File
            </button>
          </div>
          <div class="flex justify-end w-full text-right">
            <button
              v-if="!loadingRfp"
              @click="showContractHistoryDialog = true"
              class="underline text-sm"
            >
              View History
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="url">
      <iframe
        :src="
          isPDF ? url : `https://docs.google.com/gview?url=${url}&embedded=true`
        "
        frameborder="0"
        height="600px"
        width="100%"
      ></iframe>
    </div>
    <input
      type="file"
      ref="fileUpload"
      id="fileUpload"
      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      @change="uploadFile"
      class="cursor-pointer fixed -left-[100vw]"
    />
    <div v-if="!url" class="py-12 px-4 text-center text-white">
      To start our contract sign process, you need to upload the contract
      <span
        class="underline cursor-pointer"
        @click="$refs['fileUpload'].click()"
      >
        clicking here
      </span>
      or at the button at the top bar.
    </div>
    <div
      v-if="(isHotel || isCrewfare) && url && !isFullySigned"
      class="flex gap-4 items-center mt-4 px-4 justify-between print:hidden"
    >
      <p class="text-white" v-if="!hasBeenSigned">
        This is an external contract, in order to sign it, please download the
        file, sign it at your computer and re-upload the updated file.
      </p>
      <div class="flex gap-4 items-center">
        <span
          v-if="loadingSigned"
          class="border rounded px-4 rounded px-4 flex gap-2 border-gray-600 bg-gray-600 text-gray-200"
        >
          <span class="flex items-center">
            <span class="material-symbols-rounded animate-spin">
              autorenew
            </span>
          </span>
          Signing Contract
        </span>
        <span v-if="!isSigned" @click="accept" class="green-button">
          <span v-if="signingContract" class="flex items-center">
            <span class="material-symbols-rounded animate-spin">
              autorenew
            </span>
          </span>
          Mark contract as signed
        </span>
      </div>
    </div>
    <div
      v-if="hasBeenSigned && !isFullySigned"
      class="text-white text-center py-2"
    >
      You have signed this contract
    </div>
    <div
      v-if="isFullySigned"
      class="text-center mt-4 flex items-center gap-4 flex-col justify-center"
    >
      <div class="text-white">This contract is fully signed.</div>
    </div>
    <div v-if="isCrewfare" class="flex justify-center pt-4">
      <button @click="executeContract" class="green-button gap-2">
        <span v-if="regenerating" class="material-symbols-rounded animate-spin">
          autorenew
        </span>
        Execute contract
      </button>
    </div>
    <ContractHistoryDialog
      v-if="showContractHistoryDialog"
      :contractHistory="rfp.data().contractHistory"
      @hideGroupDialog="showContractHistoryDialog = false"
    />
    <div class="hidden">
      <div
        ref="rfpResume"
        class="overflow-auto max-h-[400px] flex flex-col gap-2 text-base"
      >
        <strong class="px-4 text-xl mb-2">{{ rfp.data().name }}</strong>
        <div v-if="rfp.data().attrition" class="px-4" style="margin-top: 12px">
          Currency:
          <strong>{{ rfp.data().defaultCurrency || "$ USD" }}</strong>
        </div>
        <div
          v-for="(roomItem, index) in rfp.data().roomList"
          :key="index"
          class="flex gap-2 px-4"
          style="margin-top: 12px"
        >
          <div class="flex flex-col gap-2">
            <div>
              Date: <strong>{{ roomItem.date }}</strong>
            </div>
            <div v-for="(room, roomIndex) in roomItem.rooms">
              {{ room.amount }} {{ room.type }} -
              {{ rfp.data().defaultCurrency || "$ USD" }} ${{ room.value }}
            </div>
          </div>
        </div>
        <strong class="px-4" style="margin-top: 12px">Occupancy</strong>
        <div class="px-4" v-for="room in rfp.data().roomTypes">
          {{ room.name }} - Occuppancy: {{ room.occupancy }} - Value:
          {{ rfp.data().defaultCurrency || "$ USD" }} ${{ room.additional }}
        </div>
        <div class="grid grid-cols-3 gap-4 p-4 w-full">
          <div v-if="rfp.data().attrition" style="margin-top: 12px">
            Attrition:
            <strong>{{ rfp.data().attrition }}</strong>
          </div>
          <div v-if="rfp.data().cutoffDate" style="margin-top: 12px">
            Cut-Off Date:
            <strong>{{ cutoffDateFormatted }}</strong>
          </div>
          <div
            v-if="rfp.data().commission"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <span>
              Commission(%):
              <strong>{{ rfp.data().commission }}</strong>
            </span>
            <div v-if="rfp.data().commission === 'Non Commissionable'">
              Commission Amount:
              <strong>{{ rfp.data().commissionAmount }}</strong>
            </div>
          </div>
          <div v-if="rfp.data().iata" style="margin-top: 12px">
            IATA #:
            <strong>{{ rfp.data().iata }}</strong>
          </div>
          <div
            v-if="rfp.data().rebate"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <span>
              Rebate: <strong>{{ rfp.data().rebate }}</strong>
            </span>
            <span>
              Rebate Type: <strong>{{ rfp.data().rebateType }}</strong>
            </span>
          </div>
          <div
            v-if="rfp.data().groupDateHonored"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Group Rate Honored:
              <strong>{{ rfp.data().groupDateHonored }}</strong>
            </div>
            <div v-if="rfp.data().groupDateHonored === 'No'">
              <div>
                <span class="flex px-2 items-center justify-center">$</span>
                <span>
                  {{ rfp.data().groupDateHonoredPreRate }}
                </span>
              </div>
              <div>
                <span class="flex px-2 items-center justify-center">$</span>
                <span>
                  {{ rfp.data().groupDateHonoredPostRate }}
                </span>
              </div>
            </div>
            <div>
              <div v-if="rfp.data().groupDateHonoredPre">
                Days Pre Event:
                <strong>{{ rfp.data().groupDateHonoredPreDays }}</strong>
              </div>
              <div v-if="rfp.data().groupDateHonoredPost">
                Days Post Event
                <strong>{{ rfp.data().groupDateHonoredPostDays }}</strong>
              </div>
            </div>
          </div>
          <div v-if="rfp.data().compRooms" style="margin-top: 12px">
            Comp Rooms:
            <strong>{{ rfp.data().compRooms }}</strong>
          </div>
          <div v-if="rfp.data().suiteUpgrades" style="margin-top: 12px">
            Suite Upgrades:
            <strong>{{ rfp.data().suiteUpgrades }}</strong>
          </div>
          <div v-if="rfp.data().rewardPoints" style="margin-top: 12px">
            Reward Points:
            <strong>{{ rfp.data().rewardPoints }}</strong>
          </div>
          <div v-if="rfp.data().staffDiscount" style="margin-top: 12px">
            Staff Rooms:
            <strong>{{ rfp.data().staffRooms }}</strong>
          </div>
          <div
            v-if="rfp.data().staffDiscount"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Staff Discount:
              <strong>{{ rfp.data().staffDiscount }}</strong>
            </div>
            <div v-if="rfp.data().staffDiscount === 'Other'">
              Staff Discount Amount:
              <strong>{{ rfp.data().staffDiscountAmount }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().resortFee"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Resort Fee:
              <strong>{{ rfp.data().resortFee }}</strong>
            </div>
            <div v-if="rfp.data().resortFee === 'Resort Fee'">
              Resort Fee Amount:
              <strong>{{ rfp.data().resortFeeAmount }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().billing"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Reservation Submission:
              <strong>{{ rfp.data().reservationSubmission }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().billing"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Billing:
              <strong>{{ rfp.data().billing }}</strong>
            </div>
            <div v-if="rfp.data().billing === 'Other'">
              Other: <strong>{{ rfp.data().billingsOther }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().incidentals"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Incidentals:
              <strong>{{ rfp.data().incidentals }}</strong>
            </div>
            <div v-if="rfp.data().incidentals === 'Other'">
              Other: <strong>{{ rfp.data().incidentalsOther }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().wifi"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Wi-Fi:
              <strong>{{ rfp.data().parking }}</strong>
            </div>
            <div v-if="rfp.data().wifi === 'Not included'">
              Price:
              <strong>
                {{ rfp.data().wifi_price }}
              </strong>
            </div>
          </div>
          <div
            v-if="rfp.data().parking"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Parking:
              <strong>{{ rfp.data().parking }}</strong>
            </div>
            <div v-if="rfp.data().parking === 'Not included'">
              Price:
              <strong>
                {{ rfp.data().parkingOther }}
              </strong>
            </div>
          </div>
          <div
            v-if="rfp.data().breakfast"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Breakfast:
              <strong>{{ rfp.data().breakfast }}</strong>
            </div>
            <div v-if="rfp.data().breakfast === 'Not included'">
              Price:
              <strong>
                {{ rfp.data().breakfastOther }}
              </strong>
            </div>
          </div>
          <div
            v-if="rfp.data().otherFees"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Other Fees:
              <strong>{{ rfp.data().otherFees }}</strong>
            </div>
            <div v-if="rfp.data().otherFees === 'Yes'">
              Price:
              <strong>
                {{ rfp.data().otherFeesAmount }}
              </strong>
            </div>
          </div>
        </div>
        <div v-if="rfp.data().terms" class="p-4 flex flex-col gap-1">
          <div>Additional Terms (Optional)</div>
          <div v-html="rfp.data().terms"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { firestore } from "@/utils/firebase";
import {
  doc,
  getDoc,
  query,
  where,
  collection,
  getDocs,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  sendNotificationAdmin,
  sendNotificationHotels,
} from "@/utils/notifications.jsx";
import { setStatusHistory } from "@/utils/rfp.jsx";
import { sendEmail, processVariables } from "@/utils/emails.jsx";
import ContractHistoryDialog from "./ContractHistoryDialog.vue";
import { ResumeDialog } from "@/components/rfp";
import moment from "moment";
import { contractExecutedSubject, contractExecutedEmailContent } from "@/emails/contract-executed";
import { contractExecutedExternalSubject, contractExecutedExternalEmailContent } from "@/emails/contract-executed-external";
import { contractSignedHotelSubject, contractSignedHotelEmailContent } from "@/emails/contract-signed-hotel";
import { contractSignedInternalSubject, contractSignedInternalEmailContent } from "@/emails/contract-signed-internal";
import { getNsoByChain, sendNsoCommunication } from "@/utils/nso";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import { isCrewfareAccount } from "@/utils/account";
import { NotificationTypes } from "@/enums/NotificationTypes";
import { ViewsLocations } from "@crewfare/server-shared";

export default {
  components: {
    ContractHistoryDialog,
    ResumeDialog,
  },
  data() {
    return {
      regenerating: false,
      showContractHistoryDialog: false,
      url: null,
      showMessage: true,
      savingFields: false,
      error: false,
      crewfareHasSigned: false,
      signingContract: false,
      hotelHasSigned: false,
      loadingRfp: true,
      downloadingContract: false,
      contract_content: null,
      crewfare_signature: " ",
      hotel_signature: "",
      hotel_name: "",
      loadingAccept: true,
      message: null,
      rfp: null,
      parentRfp: null,
      hotel: null,
      signed: [],
      contract: "",
      commentButton: false,
      selection: "",
      loadingSigned: false,
      terms: "",
      upload: false,
      appUrl: import.meta.env.VITE_URL || "https://launchpad.crewfare.com",
    };
  },
  computed: {
    relatedEmails(){
      return [...new Set(
        [...(this.rfp?.data()?.relatedEmails||[]) ,
        ...(this.parentRfp?.data()?.ccEmails || [])]
      )]
    },
    cutoffDateFormatted() {
      if (!this.rfp.data().cutoffDate) return "";
      return getMomentFromFirebaseDate(this.rfp.data().cutoffDate).format("D-M-Y");
    },
    isPDF() {
      return this.url.indexOf(".pdf") >= 0;
    },
    hasBeenSigned() {
      if (!this.rfp.data().signed) return false;
      if (this.isHotel) return this.rfp.data().signed.includes("hotel");
      if (this.isCrewfare) return this.rfp.data().signed.includes("crewfare");
      return false;
    },
    isSigned() {
      if (this.isHotel) {
        return this.rfp.data().status.toLowerCase() === "signed by the hotel";
      } else {
        return this.rfp.data().status.toLowerCase() === "signed by crewfare";
      }
    },
    isFullySigned() {
      return this.signed.length === 2;
    },
    account() {
      return this.$store.state.account;
    },
    isHotel() {
      if (!this.account) return;
      return ["contact"].includes(this.account.type.toLowerCase());
    },
    isCrewfare() {
      return isCrewfareAccount(this.account);
    },
  },
  async mounted() {
    this.getData();
    document.title = `Launchpad - Crewfare - RFP - Contract`;
  },
  methods: {
    toggleContractMethod() {
      this.$emit("toggleContractMethod");
    },
    async uploadFile() {
      var file = document.getElementById("fileUpload").files[0];
      if (file) {
        const storage = getStorage();
        const uuid = uuidv4();
        const name = file.name;
        const ext = name.split(".").reverse()[0];
        const storageRef = ref(storage, `${uuid}.${ext}`);
        this.upload = true;
        await uploadBytes(storageRef, file).then(async () => {
          await getDownloadURL(storageRef).then(async (url) => {
            const contractHistory = this.rfp.data().contractHistory || [];
            contractHistory.push({
              url,
              account: this.account,
              created_at: new Date(),
            });
            let data = this.rfp.data();
            data.url = url;
            data.contractHistory = contractHistory;
            data = await setStatusHistory({
              data,
              content: "External Contract Uploaded",
              status: data.status,
            });
            await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
            this.url = url;
            this.upload = false;
          });
        });
      }
      this.getData();
    },
    async getData() {
      this.loadingRfp = true;
      const rfpRef = doc(firestore, "rfp_hotel", this.$route.params.rfp);
      this.rfp = await getDoc(rfpRef);
      const parentRfpRef = doc(firestore, "rfps", this.rfp.data().rfp);
      this.parentRfp = await getDoc(parentRfpRef);

      const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
      this.hotel = await getDoc(hotelRef);
      const file = this.rfp.data().url ?? "";
      if (file.indexOf('firebasestorage') > 0) {
        const storage = getStorage();
        const fileName = file.split("?")[0];
        const storageRef = ref(storage, fileName);
        getDownloadURL(storageRef).then(async (url) => {
          this.url = url;
        });
      } else {
        this.url = file;
      }
      this.crewfare_signature = this.rfp.data().crewfare_signature || "";
      this.hotel_signature = this.rfp.data().hotel_signature || "";
      this.hotel_name = this.rfp.data().hotel_name || "";
      this.signed = this.rfp.data().signed || [];
      this.loadingRfp = false;
    },
    async accept() {
      if (
        !confirm(
          "You are about to mark this contract as signed, have you upload the updated file already?"
        )
      )
        return;
      this.error = false;
      this.signingContract = true;
      let data = this.rfp.data();
      let message = "";
      let pdfLink = "";

      if (this.isHotel) {
        this.signed.push("hotel");
        data = {
          ...data,
          signed: this.signed,
          status:
            this.signed.length === 2
              ? "Signed by both sides"
              : "Signed by the hotel",
          hotel_signed_date: new Date(),
        };
        message = `${this.account.name} (${
          this.hotel.data().name
        }) just signed the contract for ${this.rfp.data().name}`;
        sendNotificationAdmin({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          content: message,
          to: { name: ViewsLocations.CONTRACT, params: { id: this.rfp.id, rfp: this.rfp.id } },
        });
      } else {
        this.signed.push("crewfare");
        data = {
          ...data,
          signed: this.signed,
          status:
            this.signed.length === 2
              ? "Signed by both sides"
              : "Signed by crewfare",
          crewfare_signed_date: new Date(),
        };
        message = `Crewfare just signed the contract for ${
          this.rfp.data().name
        }`;
        sendNotificationHotels({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          ids: [this.rfp.data().hotel_id],
          content: message,
          to: { name: "rfpContract", params: { rfp: this.rfp.id } },
        });
      }

      if (this.signed.length === 1) {
        const link = `${import.meta.env.VITE_URL}/rfp/${this.rfp.id}/contract`;
        this.relatedEmails.forEach((email) => {
          sendEmail({
            template: "emails.new-default",
            to: email,
            banner: this.rfp.data().banner,
            subject: processVariables(this.isHotel ? contractSignedHotelSubject: contractSignedInternalSubject, this.rfp.data()),
            replyTo: this.rfp.data().replyTo,
            content: processVariables(this.isHotel ? contractSignedHotelEmailContent : contractSignedInternalEmailContent, {...this.rfp.data(), link}),
          });
        });
        const qContacts = query(
          collection(firestore, "contacts"),
          where("hotel_id", "==", this.rfp.data().hotel_id)
        );
        const contacts = await getDocs(qContacts);
        const contactList = contacts.docs;
        contactList.forEach((contact) => {
          sendEmail({
            template: "emails.new-default",
            to: contact.data().email,
            banner: this.rfp.data().banner,
            replyTo: this.rfp.data().replyTo,
            subject: processVariables(this.isHotel ? contractSignedHotelSubject: contractSignedInternalSubject, this.rfp.data()),
              content: processVariables(this.isHotel ? contractSignedHotelEmailContent : contractSignedInternalEmailContent, {...this.rfp.data(), link}),
          });
        });
        data = await setStatusHistory({
          data,
          content: "Contract signed",
          status: data.status,
        });
        const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
        const hotel = await getDoc(hotelRef);
        if (hotel.data().chain) {
          sendNsoCommunication({
            chainName: hotel.data().chain,
            subject: contractSignedHotelSubject,
            content: contractSignedHotelEmailContent,
            variables: this.rfp.data(),
          });
        }
        await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
      } else {
        await this.getData();
        data.pdfLink = this.url;
        data = await setStatusHistory({
          data,
          content: "Contract signed",
          status: data.status,
        });
        await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);

        sendEmail({
          to: "execute@crewfare.com",
          template: "emails.new-default",
          subject: processVariables(contractExecutedExternalSubject, this.rfp.data()),
          replyTo: this.rfp.data().replyTo,
          content: processVariables(contractExecutedExternalEmailContent, {...this.rfp.data(), link: this.url}),
        });
        const qContacts = query(
          collection(firestore, "contacts"),
          where("hotel_id", "==", this.rfp.data().hotel_id)
        );
        const contacts = await getDocs(qContacts);
        const contactList = contacts.docs;
        const emailsTo = import.meta.env.VITE_EXECUTIVE_CC_MAILS.split(',') || [];
        emailsTo.push(...this.relatedEmails);
        contactList.forEach((contact) => {
          emailsTo.push(contact.data().email.toLowerCase().trim());
        });
        const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
        const hotel = await getDoc(hotelRef);
        if (hotel.data().chain) {
          const accounts = await getNsoByChain(hotel.data().chain);
          emailsTo.push(...accounts.map(account => account.email));
        }
        const link = `${import.meta.env.VITE_URL}/rfp/${this.rfp.id}/contract`;
        sendEmail({
          banner: this.rfp.data().banner,
          template: "emails.new-default",
          to: emailsTo,
          subject: processVariables(contractExecutedSubject, this.rfp.data()),
          replyTo: this.rfp.data().replyTo,
          content: processVariables(contractExecutedEmailContent, {...this.rfp.data(), link}),
        });
      }

      this.loadingSigned = false;
      if (this.isHotel) {
        this.$store.commit("setToast", {
          content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">The contract is signed! </div>
              </div>`,
        });
        router.push({
          name: "rfpContractSigned",
          params: { rfp: this.rfp.id },
        });
      } else {
        this.$store.commit("setToast", {
          content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">The contract to ${
                  this.rfp.data().name
                } has been signed!</div>
              </div>`,
        });
        router.push({
          name: "rfpsHotels",
          params: { id: this.rfp.data().rfp },
        });
      }
      this.signingContract = false;
    },
    async getRfp() {
      this.hide_signature = true;
      this.regenerating = true;
      const dataContract = {
        name: `${this.$route.params.rfp}${Math.floor(Math.random() * 100000)}`,
        content: this.$refs.rfpResume.innerHTML,
      };
      const url = await fetch(`${import.meta.env.VITE_API_URL}/pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContract),
      }).then((response) => response.json());
      this.regenerating = false;
      this.hide_signature = false;
      return url;
    },
    async executeContract() {
      let data = this.rfp.data();
      const rfp_data = await this.getRfp();
      data.pdfLink = this.url || "";
      data.rfpLink = rfp_data.url || "";
      data = await setStatusHistory({
        data,
        content: "Contract signed",
        status: data.status,
      });
      await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
      const executeEmail = import.meta.env.VITE_EXECUTE_MAIL || "";
      sendEmail({
        to: executeEmail,
        template: "emails.new-default",
        subject: processVariables(contractExecutedExternalSubject, this.rfp.data()),
        replyTo: this.rfp.data().replyTo,
        content: processVariables(contractExecutedExternalEmailContent, {...this.rfp.data(), link: this.url}),
      });
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.rfp.data().hotel_id)
      );
      const contacts = await getDocs(qContacts);
      const contactList = contacts.docs;
      const emailsTo = import.meta.env.VITE_EXECUTIVE_CC_MAILS.split(',') || [];
      emailsTo.push(...this.relatedEmails);
      console.log("CC", emailsTo)
      contactList.forEach((contact) => {
        emailsTo.push(contact.data().email.toLowerCase().trim());
      });
      const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
      const hotel = await getDoc(hotelRef);
      if (hotel.data().chain) {
          const accounts = await getNsoByChain(hotel.data().chain);
          console.log(accounts)
          emailsTo.push(...accounts.map(account => account.email));
      }
      const link = `${import.meta.env.VITE_URL}/rfp/${this.rfp.id}/contract`;
      sendEmail({
        to: emailsTo,
        banner: this.rfp.data().banner,
        template: "emails.new-default",
        subject: processVariables(contractExecutedSubject, this.rfp.data()),
        replyTo: this.rfp.data().replyTo,
        content: processVariables(contractExecutedEmailContent, {...this.rfp.data(), link}),
      });
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">The contract is executed! </div>
              </div>`,
      });
      this.regenerating = false;
    },
  },
};
</script>
