<template>
  <div
    v-if="isCrewfare"
    class="text-white"
  >
    <div class="px-3 text-white py-3 bg-[#23262F]">
      <Breadcrumb icon="th-large" :items="['RFPs']" />
      <div class="bg-[#141416] p-4 mt-6 flex flex-col gap-4 rounded-lg">
        <AdminInternal :showCreate="true" />
      </div>
    </div>
  </div>
  <div v-else class="text-white">
    <div class="px-3 text-white py-3 bg-[#23262F]">
      <Breadcrumb icon="th-large" :items="['RFPs']" />
      <div class="bg-[#141416] p-4 mt-6 flex flex-col gap-4 rounded-lg">
        <RfpHotel />
      </div>
    </div>
  </div>
</template>

<script>
import AdminInternal from "@/components/rfps/lists/AdminInternal.vue";
import Breadcrumb from "@/components/default/Breadcrumb.vue";
import RfpHotel from "@/components/rfps/lists/RfpHotel.vue";
import { isCrewfareAccount } from "@/utils/account";
export default {
  components: {
    AdminInternal,
    Breadcrumb,
    RfpHotel,
  },
  computed: {
    isCrewfare(){
      return isCrewfareAccount(this.account);
    },
    account() {
      return this.$store.state.account;
    },
  },
};
</script>
