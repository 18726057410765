import { BaseApi, generateQueryParams, getToken } from './apiFactory';

class NotificationsApi extends BaseApi {
  notifyHotel = async (data: object) => {
    const token = await getToken();
    return this.axios({
      method: 'POST',
      url: `${this.apiUrl}/notify/hotel`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  };
  notifySource = async (data: object) => {
    const token = await getToken();
    return this.axios({
      method: 'POST',
      url: `${this.apiUrl}/notify/source`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  };
}

export const notificationsApi = new NotificationsApi({ name: 'notifications' });
