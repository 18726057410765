<template>
  <div v-if="rfp" class="bg-gray-800 w-full flex flex-col stage-height text-white">
    <div class="p-4 flex justify-between z-50 bg-gray-900 items-center">
      <div class="flex gap-4">
        <router-link :to="{ name: 'rfpsList' }" class="material-symbols-rounded hover:text-crewfareGreen">
          chevron_left
        </router-link>
        <div class="flex flex-col gap-2">
          <p class="text-xl">{{ rfp.name || 'New RFP' }}</p>
          <div class="flex text-sm items-center -ml-4">
            <span v-if="rfp.event_name && rfp.event_name.length > 0" class="flex flex-col gap-1 px-4">
              <span class="text-xs">Event Name:</span>
              <span class="text-base">{{ rfp.event_name }}</span>
            </span>
            <span v-if="rfp.venue && rfp.venue.length > 0" class="flex flex-col gap-1 px-4">
              <span class="text-xs">Venue:</span>
              <span class="text-base">{{ rfp.venue }}</span>
            </span>
            <span class="flex flex-col gap-1 px-4">
              <span class="text-xs">Status:</span>
              <span class="text-base capitalize">{{ rfp.status }}</span>
            </span>
            <span class="flex flex-col gap-1 px-4">
              <span class="text-xs">Event Dates:</span>
              <span class="text-base">{{ formatedDates }}</span>
            </span>
            <span class="flex flex-col gap-1 px-4">
              <span class="text-xs">Signed Hotels:</span>
              <span class="text-base underline cursor-pointer hover:text-sky-200" @click="showRoomNumbersDialog = true">
                {{ signedHotels | 0 }}
                <span v-if="rfp.hotel_goal && rfp.hotel_goal.length > 0">
                  / {{ rfp.hotel_goal }} -
                  {{ ((signedHotels / parseInt(rfp.hotel_goal)) * 100).toFixed(1) }}%
                </span>
              </span>
            </span>
            <span class="flex flex-col gap-1 px-4">
              <span class="text-xs">Signed Rooms:</span>
              <span class="text-base underline cursor-pointer hover:text-sky-200" @click="showRoomNumbersDialog = true">
                {{ signedRoomHotels | 0 }}
                <span v-if="rfp.room_goal && rfp.room_goal.length > 0">
                  / {{ rfp.room_goal }} -
                  {{ ((signedRoomHotels / parseInt(rfp.room_goal)) * 100).toFixed(1) }}%
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="flex gap-4 relative">
        <button
          v-if="rfp.status.toLowerCase() === 'draft'"
          @click="sendRFP"
          class="green-button"
          :disabled="loadingSend"
        >
          <span v-if="loadingSend" class="flex gap-2 items-center">
            <span class="material-symbols-rounded animate-spin flex items-center"> autorenew </span>
            Sending
          </span>
          <span v-else> Send </span>
        </button>
        <div>
          <dropdown-menu>
            <template #trigger>
              <button
                class="inline-flex relative items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                type="button"
              >
                <span v-if="loadingSend" class="material-symbols-rounded animate-spin flex items-center">
                  autorenew
                </span>
                <span v-else class="material-symbols-rounded">more_vert</span>
                <span
                  v-if="hasInternalMessage"
                  class="bg-red-400 rounded-full text-white px-1 absolute top-0 right-0 h-[12px] w-[12px] flex items-center justify-center"
                ></span>
              </button>
            </template>
            <template #body>
              <FwbListGroup class="absolute top-[40px] right-0 z-30 w-[240px]">
                <FwbListGroupItem class="!px-0 !py-0 dark:bg-gray-700 bg-gray-700">
                  <router-link
                    :to="{ name: 'rfpsForm', params: { id: rfp.id } }"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Edit
                  </router-link>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="!isCompleted && rfp.hotels?.length > 0" class="!px-0 !py-0">
                  <button
                    @click="sendRFP"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                    :disabled="loadingSend"
                  >
                    <span v-if="loadingSend" class="flex gap-2 items-center">
                      <span class="material-symbols-rounded animate-spin flex items-center"> autorenew </span>
                      Sending
                    </span>
                    <span v-else> Send </span>
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="!isCompleted" class="!px-0 !py-0">
                  <button
                    @click="reminderRFP"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                    :disabled="loadingReminder"
                  >
                    <span v-if="loadingReminder" class="flex gap-2 items-center">
                      <span class="material-symbols-rounded animate-spin flex items-center"> autorenew </span>
                      Sending reminder
                    </span>
                    <span v-else> Send reminder </span>
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem class="!px-0 !py-0">
                  <ButtonNotesDialog
                    :parentRFP="rfp"
                    type="Notes on RFP"
                    :subtitle="rfp.name"
                    :title="`Notes at ${rfp.name}`"
                    :id="`${rfp.id}-note`"
                    text="Notes"
                  />
                </FwbListGroupItem>
                <FwbListGroupItem v-if="!isCompleted" class="!px-0 !py-0">
                  <button
                    @click="searchHotel = true"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Add Hotel from Database
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="!isCompleted" class="!px-0 !py-0">
                  <button
                    @click="goToSearch"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Search Hotels
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="!isCompleted" class="!px-0 !py-0">
                  <button
                    @click="showGroupDialog = true"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Add group
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem class="!px-0 !py-0">
                  <button
                    @click="showProposalChartDialog = true"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Proposal Chart
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem class="!px-0 !py-0">
                  <button
                    v-if="!isCompleted"
                    @click="completeRfp"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Mark As Completed
                  </button>
                  <button
                    v-else
                    @click="restoreRfp"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Restore RFP
                  </button>
                </FwbListGroupItem>
              </FwbListGroup>
            </template>
          </dropdown-menu>
        </div>
      </div>
    </div>
    <div class="flex">
      <router-link
        :to="{ name: 'rfpsHotels', params: { id: $route.params.id } }"
        class="text-white px-4 py-2"
        exact-active-class="bg-gray-700"
        >List</router-link
      >
      <router-link
        v-if="rfp.location"
        :to="{ name: 'rfpsMap', params: { id: $route.params.id } }"
        class="text-white px-4 py-2"
        active-class="bg-gray-700"
        >Map</router-link
      >
    </div>
    <RouterView
      v-if="rfp && rfp_hotels && rfp_hotels_filtered"
      @getData="getData"
      @doSearch="search"
      :rfp="rfp"
      :group_status="group_status"
      :rfp_hotels="rfp_hotels"
      :rfp_hotels_filtered="rfp_hotels_filtered"
    />
    <GroupDialog
      v-if="showGroupDialog"
      @hideGroupDialog="showGroupDialog = false"
      :rfp="rfp"
      @getData="getData"
    />
    <ProposalChartDialog
      v-if="showProposalChartDialog"
      @hideGroupDialog="showProposalChartDialog = false"
      :rfp="rfp"
      :rfp_hotels="rfp_hotels"
    />
    <RoomNumbersDialog
      v-if="showRoomNumbersDialog"
      @hideGroupDialog="showRoomNumbersDialog = false"
      :signedHotelsList="signedHotelsList"
      :group_status="group_status"
      :total="total"
    />
    <AddHotels v-if="searchHotel" @close="searchHotel = false" :rfp="rfp" @addedHotels="getData" />
  </div>
</template>

<script>
import router from "@/router";
import { firestore } from "@/utils/firebase";
import {
  where,
  addDoc,
  getDocs,
  getDoc,
  doc,
  query,
  collection,
  orderBy,
} from "firebase/firestore";
import {
  GroupDialog,
  RoomNumbersDialog,
  ProposalChartDialog,
} from "@/components/rfps/hotels";
import moment from "moment";
import {
  sendRFP,
  sendReminderRFP,
  completeRfp,
  restoreRfp,
  groupStatus,
} from "@/utils/rfp.jsx";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";
import AddHotels from "@/components/rfps/hotels/AddHotels.vue";
import { rfpsApi } from "@/utils/apis/rfpsApi";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";

export default {
  components: {
    AddHotels,
    GroupDialog,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
    RoomNumbersDialog,
    ProposalChartDialog,
    ButtonNotesDialog,
  },
  data() {
    return {
      searchHotel: false,
      loadingReminder: false,
      showRoomNumbersDialog: false,
      showGroupDialog: false,
      showNoteDialog: false,
      showProposalChartDialog: false,
      showMenu: false,
      active: 0,
      rfp: null,
      rfp_hotels: null,
      rfp_hotels_filtered: [],
      status: 'All',
      selected: [],
      loadingSend: false,
      internalMessage: null,
      hasReminder: ['accepted by crewfare', 'crewfare counter', 'bid viewed', 'progress saved', 'bid opened', 'sent'],
      hasInternalMessage: false,
      total: 0,
      group_status: {
        signed: 0,
        accepted: 0,
        sent: 0,
        new: 0,
        declined: 0,
        negotiation: 0,
      },
    };
  },
  computed: {
    isCompleted() {
      return (
        this.rfp.status.toLowerCase() === 'completed' ||
        new Date().getTime() > getMomentFromFirebaseDate(this.rfp.startDate).unix() * 1000
      );
    },
    signedHotels() {
      if (!this.rfp_hotels) return [];
      const filterHotels = this.rfp_hotels.filter(rfp => rfp.status.toLowerCase() === 'signed by both sides');
      return filterHotels.length;
    },
    signedRoomHotels() {
      if (!this.rfp_hotels) return [];
      const filterHotels = this.rfp_hotels.filter(rfp => rfp.status.toLowerCase() === 'signed by both sides');
      let roomCount = 0;
      filterHotels.forEach(hotel => {
        hotel.roomList[0].rooms.forEach(room => (roomCount += parseInt(room.amount || 0)));
      });
      return roomCount;
    },
    signedHotelsList() {
      return this.rfp_hotels.filter(rfp => rfp.status.toLowerCase() === 'signed by both sides');
    },
    formatedDates() {
      const startDate = this.rfp.startDate;
      const endDate = this.rfp.endDate;
      return `${getMomentFromFirebaseDate(startDate).format('ddd MMM. DD, YYYY')} to ${getMomentFromFirebaseDate(endDate).format(
        'ddd MMM. DD, YYYY',
      )}`;
    },
    isAllSelected() {
      return this.selected.length === this.rfp_hotels_filtered.length;
    },
    account() {
      const account = this.$store.state.account;
      if (!['internal', 'admin'].includes(account.type.toLowerCase())) {
        router.push({ name: 'rfpsList' });
        return false;
      }
      return account;
    },
    createdAtFormated() {
      const date = this.rfp.created_at;
      return getMomentFromFirebaseDate(date).format('MM-DD-YYYY');
    },
    endDateFormated() {
      const date = this.rfp.endDate;
      return getMomentFromFirebaseDate(date).format('MM-DD-YYYY');
    },
    startDateFormated() {
      const date = this.rfp.startDate;
      return getMomentFromFirebaseDate(date).format('MM-DD-YYYY');
    },
    hotels() {
      return this.rfp.hotels;
    },
    hotelsCount() {
      if (!this.rfp_hotels) return 0;
      return this.rfp_hotels?.length;
    },
  },
  mounted() {
    if (this.account && !['internal', 'admin'].includes(this.account.type.toLowerCase())) {
      router.push({ name: 'rfpsList' });
      return false;
    }
    this.getData();
  },
  methods: {
    async completeRfp() {
      await completeRfp(this.rfp);
      this.getData();
    },
    async restoreRfp() {
      await restoreRfp(this.rfp);
      this.getData();
    },
    async parseData(items) {
      const list = [];
      for (const item of items) {
        list.push({
          id: item.id,
          cutoffDate: item.data().cutoffDate,
          status: item.data().status,
          distance: item.data().distance,
          value: item.data().value,
          updated_at: item.data().updated_at,
          hotel_id: item.data().hotel_id,
          roomList: item.data().roomList,
          startDate: item.data().startDate,
          endDate: item.data().endDate,
          hotel_name: item.data().hotel_name,
          message_id: item.data().message_id,
          reasonDecline: item.data().reasonDecline,
          name: item.data().name,
          hotel_name: item.data().hotel_name,
          roomListHistoric: item.data().roomListHistoric,
          status_history: item.data().status_history,
          accepted: item.data().accepted,
          signed: item.data().signed,
        });
      }
      return list;
    },
    search(search) {
      if (search.length > 0) {
        this.rfp_hotels_filtered = this.rfp_hotels.filter(
          rfp =>
            rfp.hotel_name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            rfp.contact_emails.toLowerCase().indexOf(search.toLowerCase()) >= 0,
        );
      } else {
        this.rfp_hotels_filtered = this.rfp_hotels;
      }
    },
    async reminderRFP({ status }) {
      if (!confirm('Are you sure?')) return;
      this.loadingReminder = true;
      await sendReminderRFP(this.selected, this.rfp.status.toLowerCase());
      this.loadingReminder = false;
    },
    async resendRFP(rfp) {
      if (!confirm('Are you sure?')) return;
      this.loadingSend = true;
      await sendRFP(this.rfp, this.account, rfp);
      this.getData();
      this.loadingSend = false;
    },
    async getEmailContacts() {
      this.rfp_hotels.forEach(async item => {
        const hotelRef = doc(firestore, 'hotels', item.hotel_id);
        const hotel = await getDoc(hotelRef);
        item.contact_emails = hotel
          .data()
          .contacts.map(contact => contact.email)
          .join(',');
      });
    },
    async getData() {
      this.addedHotels = false;
      const proposals = await rfpsApi.listProposals(this.$route.params.id).then(res => res.data||[]);
      this.rfp = await rfpsApi.get(this.$route.params.id).then(res => res.data);
      this.rfp_hotels_filtered = this.rfp_hotels = proposals;
      this.getEmailContacts();
      this.total = this.rfp_hotels.length;
      this.getCountStatus();

      this.selected = [
        ...this.rfp_hotels_filtered.filter(rfp =>
          ['new', 'sent', 'bid viewed', 'progress saved', 'bid opened'].includes(rfp.status.toLowerCase()),
        ),
      ];
      document.title = `Launchpad - Crewfare - RFP - ${this.rfp.name}`;
    },
    getCountStatus() {
      this.rfp_hotels.forEach(rfp => {
        const status = rfp.status.toLowerCase();
        if (groupStatus.signed.includes(status)) this.group_status['signed']++;
        if (groupStatus.accepted.includes(status)) this.group_status['accepted']++;
        if (groupStatus.sent.includes(status)) this.group_status['sent']++;
        if (groupStatus.new.includes(status)) this.group_status['new']++;
        if (groupStatus.declined.includes(status)) this.group_status['declined']++;
        if (groupStatus.negotiation.includes(status)) this.group_status['negotiation']++;
      });
    },
    async sendRFP() {
      if (!confirm('Are you sure?')) return;
      this.loadingSend = true;
      await sendRFP(this.rfp, this.account, this.selected);
      this.getData();
      this.loadingSend = false;
    },
    async goToSearch() {
      this.$store.commit('setSelectedRFP', {
        id: this.$route.params.id,
        name: this.rfp.name,
        hotels: await this.rfp.hotels,
      });
      let rfp_data = this.rfp.data ? this.rfp.data(): {...this.rfp}
      router.push({
        name: 'searchView',
        query: {
          startDate: getMomentFromFirebaseDate(this.rfp.startDate).format('MM-DD-YYYY'),
          endDate: getMomentFromFirebaseDate(this.rfp.endDate).format('MM-DD-YYYY'),
          search: rfp_data.search,
          lat: rfp_data?.location?.lat||"",
          lng: rfp_data?.location?.lng||""
        },
      });
    },
  },
};
</script>
