<template>
  <div
    v-if="messageOpened"
    class="fixed bg-gray-900 z-[100] top-0 right-0 bottom-0 max-w-[500px] w-full flex flex-col border-l border-l-white"
  >
    <div class="flex flex-col min-h-full">
      <div class="text-xl text-white p-4 leading-10 flex justify-between">
        <div class="flex flex-col gap-2">
          <span class="flex items-center leading-6">
            {{ messageOpened.title }}
          </span>
          <p v-if="rfp" class="text-sm text-gray-200">
            RFP: {{ rfp.data().name }}
          </p>
        </div>
        <button
          @click="closeFloat"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          close
        </button>
      </div>
      <div class="flex flex-col flex-grow">
        <div
          ref="messageList"
          class="flex-grow flex flex-col gap-4 h-full overflow-auto p-2 px-4 chat-holder"
        >
          <span
            v-if="loading"
            class="material-symbols-rounded animate-spin py-5 text-crewfareGreen flex items-center"
          >
            autorenew
          </span>
          <ListItem
            v-for="message in messages"
            :message="message"
            :key="message.id"
          />
        </div>
        <Form @addFile="addFile" @addChat="addChat" :message="message" />
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  addDoc,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  collection,
  where,
  orderBy,
} from "firebase/firestore";
import { Form, ListItem } from "@/components/messages/chat";
import { addChat, addFile } from "@/utils/messages.jsx";

export default {
  components: {
    ListItem,
    Form,
  },
  computed: {
    messageOpened() {
      return this.$store.state.messageOpened;
    },
    account() {
      return this.$store.state.account;
    },
  },
  data: () => {
    return {
      message: null,
      rfp: null,
      messages: [],
      hotel: null,
      loading: false,
    };
  },
  watch: {
    async messageOpened() {
      if (!this.messageOpened) {
        this.messages = [];
        this.hotel = null;
        return;
      }
      const messageRef = doc(firestore, "messages", this.messageOpened.id);
      this.message = await getDoc(messageRef);

      if (this.message.data().hotel_id) {
        const hotelRef = doc(firestore, "hotels", this.message.data().hotel_id);
        this.hotel = await getDoc(hotelRef);
      }
      if (this.message.data().rfp_id) {
        const rfpHotelRef = doc(
          firestore,
          "rfp_hotel",
          this.message.data().rfp_id
        );
        this.rfp = await getDoc(rfpHotelRef);
      }
      await setDoc(doc(firestore, "messages", this.message.id), {
        ...this.message.data(),
        new_message: false,
      });
      this.getData();
    },
  },
  methods: {
    closeFloat() {
      this.$store.commit("setMessageOpened", null);
    },
    async getData() {
      this.loading = true;
      onSnapshot(
        query(
          collection(firestore, "chat"),
          where("message_id", "==", this.messageOpened.id),
          orderBy("created_at", "asc")
        ),
        (doc) => {
          this.loading = false;
          this.messages = doc.docs;
          setTimeout(() => {
            this.$refs.messageList.scrollTop =
              this.$refs.messageList?.scrollHeight || 0;
          }, 200);
        }
      );
    },
    async addChat(content) {
      addChat(this.account, this.message, content);
    },
    async addFile({ url, name }) {
      addFile(this.account, this.message, url, name);
    },
  },
};
</script>
