<template>
  <div class="flex flex-col bg-gray-800 rounded pb-4 relative">
    <div
      class="flex px-4 items-center justify-between sticky py-2 top-0 z-50 bg-gray-800"
    >
      <div class="flex flex-col w-full gap-4 items-center">
        <div class="flex justify-between w-full">
          <div class="flex gap-4 items-center">
            <div class="text-crewfareGreen">
              <strong class="rounded-full bg-black px-3 py-1">{{
                count
              }}</strong>
              Hotels
            </div>
            <div class="flex gap-2">
              <div
                class="flex divide-x divide-crewfareGreen border border-crewfareGreen rounded"
              >
                <button
                  @click="filterType = 1"
                  class="px-3 text-sm whitespace-nowrap hover:bg-crewfareGreen hover:text-black"
                  :class="
                    filterType === 1
                      ? 'bg-green-400 text-black'
                      : 'text-green-400'
                  "
                >
                  All
                </button>
                <button
                  @click="filterType = 2"
                  class="px-3 text-sm whitespace-nowrap hover:bg-crewfareGreen hover:text-black"
                  :class="
                    filterType === 2
                      ? 'bg-green-400 text-black'
                      : 'text-green-400'
                  "
                >
                  Has contacts
                </button>
                <button
                  @click="filterType = 3"
                  class="px-3 text-sm whitespace-nowrap hover:bg-crewfareGreen hover:text-black"
                  :class="
                    filterType === 3
                      ? 'bg-green-400 text-black'
                      : 'text-green-400'
                  "
                >
                  No contacts
                </button>
              </div>
              <div class="flex text-white gap-2">
                Sort:
                <select
                  v-model="sorting"
                  @change="toggleSort"
                  class="w-full bg-gray-700 max-w-[170px] text-sm rounded px-2 py-0 border border-gray-500 text-white"
                >
                  <option value="DISTANCE">Distance</option>
                  <option value="PRICE_LOW_TO_HIGH">Value Low - High</option>
                </select>
              </div>
              <div class="flex text-white gap-2">
                Chain:
                <select
                  class="w-full bg-gray-700 max-w-[170px] text-sm rounded px-2 py-0 border border-gray-500 text-white"
                  @change="changeSubSearch"
                  v-model="selectedChain"
                >
                  <option value="">All</option>
                  <option v-for="chain in chains" :key="chain" :value="chain">
                    {{ chain }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <input
            type="text"
            class="bg-gray-700 rounded px-4 py-2 border border-gray-500"
            placeholder="Search Hotel"
            v-model="subSearch"
            @input="changeSubSearch"
          />
        </div>
        <div class="flex items-center justify-end w-full gap-4">
          <div v-if="selectedRFP" class="flex gap-4">
            <button @click="selectAll" class="green-button">
              <span
                >{{
                  isAllSelected
                    ? `Unselect All (${selectedCount})`
                    : "Select All"
                }}
              </span>
            </button>
            <button @click="assignToSelectedRFP" class="green-button">
              <span
                v-if="loading"
                class="material-symbols-rounded animate-spin flex items-center"
              >
                autorenew
              </span>
              <span v-else> Assign To {{ selectedRFP.name }} </span>
            </button>
          </div>
          <div v-else class="flex gap-4">
            <div v-if="selectedCount === 0" class="flex gap-4">
              <button @click="selectAll" class="green-button">
                <span
                  >{{
                    isAllSelected
                      ? `Unselect All (${selectedCount})`
                      : "Select All"
                  }}
                </span>
              </button>
              <button
                @click="download"
                class="flex items-center h-[42px] border border-crewfareGreen text-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 rounded px-4"
              >
                Download list
              </button>
            </div>
            <div v-else class="flex gap-4">
              <button @click="selectAll" class="green-button">
                <span
                  >{{
                    isAllSelected
                      ? `Unselect All (${selectedCount})`
                      : "Select All"
                  }}
                </span>
              </button>
              <button
                @click="assignRFP"
                class="disabled:text-gray-800 disabled:border-gray-600 flex items-center h-[42px] disabled:bg-gray-600 border border-crewfareGreen text-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 rounded px-4"
              >
                <span> Assign to Existing RFP </span>
              </button>
              <button
                @click="createRFP"
                class="disabled:text-gray-800 disabled:border-gray-600 flex items-center h-[42px] disabled:bg-gray-600 border border-crewfareGreen text-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 rounded px-4"
              >
                <span
                  v-if="loading"
                  class="material-symbols-rounded animate-spin"
                >
                  autorenew
                </span>
                <span v-else> Create RFP </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SearchList
      v-if="listMode === 'list'"
      :selected="selected"
      :sliceList="listResults"
      :actual="actual"
      :list="listResults"
      @updateSelected="updateSelected"
      @togglePage="togglePage"
    />
    <SearchMap
      v-if="listMode === 'map'"
      :selected="selected"
      :list="listResults"
      @updateSelected="updateSelected"
    />
  </div>
</template>

<script>
import router from '@/router';
import { firestore } from '@/utils/firebase';
import { addToRfp } from '@/utils/rfp.jsx';
import { addDoc, collection } from 'firebase/firestore';
import moment from 'moment';
import SearchList from './SearchList.vue';
import SearchMap from './SearchMap.vue';

export default {
  props: ['found', 'listHotels', 'selectedHotelsEmpty', 'actual', 'chains'],
  components: {
    SearchList,
    SearchMap,
  },
  computed: {
    hotelsIdWithContact() {
      return this.$store.state.hotelsIdWithContact;
    },
    selectedRFP() {
      return this.$store.state.selectedRFP;
    },
    sliceList() {
      const sliceStart = this.actual * 20;
      return this.listResults.slice(sliceStart, sliceStart + 20);
    },
    selectedCount() {
      return this.selected.length;
    },
    count() {
      return this.listResults.length;
    },
    account() {
      return this.$store.state.account;
    },
    isAllSelected() {
      return this.selected.length === this.hotelsIdWithContact.length;
    },
  },
  data() {
    return {
      listMode: 'list',
      listResults: [],
      filterType: 1,
      loading: false,
      selected: [],
      sorting: this.$store.state.search?.sorting,
      subSearch: '',
      selectedChain: "",
    };
  },
  watch: {
    filterType() {
      this.filterHotels();
    },
    selectedHotelsEmpty() {
      if (this.selectedHotelsEmpty) {
        this.selected = [];
      }
    },
    listHotels() {
      this.filterHotels();
    },
    selectedChain() {
      this.filterHotels();
    },
  },
  mounted() {
    this.listResults = this.listHotels;
  },
  methods: {
    changeSubSearch() {
      this.$emit('inputChanged', { value: this.subSearch, chain: this.selectedChain });
    },
    filterHotels() {
      let hotels = this.listHotels;
      const hotelsIdWithContact = this.hotelsIdWithContact;
      switch (this.filterType) {
        case 1:
          this.listResults = hotels;
          break;
        case 2:
          this.listResults = hotels.filter((hotel) =>
            hotelsIdWithContact.includes(hotel.id)
          );
          break;
        case 3:
          this.listResults = hotels.filter(
            (hotel) => !hotelsIdWithContact.includes(hotel.id)
          );
          break;
      }
      this.togglePage(0);
    },
    togglePage(page) {
      this.$emit("togglePage", page);
    },
    assignRFP() {
      this.$store.commit("setAssignRFP", true);
    },
    selectAll() {
      const available = this.listHotels
        .filter((hotel) => this.hotelsIdWithContact.includes(hotel.id))
        .map((hotel) => {
          if (hotel.crewfare) {
            hotel.crewfare.value = hotel.price.lead.amount;
            hotel.crewfare.distance = hotel.distance;
            hotel.crewfare.name = hotel.name;
            return hotel.crewfare;
          }
          return hotel;
        });
      if (this.selected.length === available.length) {
        this.selected = [];
      } else {
        this.selected = available;
      }
      this.$store.commit("setSelectedHotels", this.selected);
    },
    updateSelected(hotel) {
      const index = this.selected.findIndex(
        (selected) => selected.id === hotel.id
      );
      if (index >= 0) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(hotel);
      }
      this.$store.commit("setSelectedHotels", this.selected);
    },
    async createRFP() {
      this.loading = true;
      const search = this.$store.state.search;
      const cutoffDate = new Date(
        search.dates[0].setDate(new Date().getDate() + 30)
      );
      const decisionDate = new Date(
        search.dates[0].setDate(new Date().getDate() + 21)
      );
      const responseDueDate = new Date(
        search.dates[0].setDate(new Date().getDate() - 24)
      );
      const hotel_ids = this.selected.map((selected) => selected.id);
      const data = {
        hotels: hotel_ids,
        startDate: search.dates[0],
        endDate: search.dates[1],
        cutoffDate,
        isLeisure: true,
        decisionDate,
        responseDueDate,
        created_at: new Date(),
        created_by: this.account.name,
        updated_at: new Date(),
        shared_with: [this.account.id],
        status: "new",
      };
      const rfp = await addDoc(collection(firestore, "rfps"), data);

      this.selected.forEach(async (hotel) => {
        const message = await addDoc(collection(firestore, "messages"), {
          hotel_id: hotel.id,
          created_at: new Date(),
          last_interaction: new Date(),
          accounts: [this.account.email],
          direct: false,
          new_message: true,
          new_message_by: this.account.id,
        });
        const status_history = [
          {
            created_at: new Date(),
            content: "RFP created",
            status: "New",
            account: this.account,
          },
        ];
        await addDoc(collection(firestore, "rfp_hotel"), {
          ...data,
          hotel_id: hotel.id,
          hotel_name: hotel.data().name,
          distance: hotel.data().distance,
          value: hotel.value,
          rfp: rfp.id,
          status_history,
          message_id: message.id,
          created_at: new Date(),
          status: "New",
        });
      });

      router.push({ name: "rfpsForm", params: { id: rfp.id } });
      this.loading = false;
    },
    toggleSort() {
      this.$store.commit("setSearch", {
        ...this.$store.state.search,
        sorting: this.sorting,
      });
      this.$emit("sortSearch");
    },
    async assignToSelectedRFP() {
      this.loading = true;
      await addToRfp({
        rfp: this.selectedRFP,
        hotels: this.$store.state.selectedHotels,
      });
      this.loading = false;
      router.push({ name: "rfpsHotels", params: { id: this.selectedRFP.id } });
    },
    download() {
      let csvFile = "Hotel Name,Lat,Lng,Chain,Contact name,Contact email\n";
      for (const hotel of this.listResults) {
        let line = "";
        if (hotel.mapMarker) {
          line = `"${hotel.name}",${hotel.mapMarker.latLong.latitude},${hotel.mapMarker.latLong.longitude},,,\n`;
        } else {
          line = `"${hotel.data().name}",${hotel.data().lat},${
            hotel.data().lng
          },,,\n`;
        }
        csvFile += line;
      }
      var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
      const date = moment().format("YYYY-MM-DD");
      const filename = `launchpad-hotels-search-${date}.csv`;
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
};
</script>
