<template>
    <span :class='"material-symbols-rounded animate-spin "+sizeClass'>
        autorenew
    </span>
</template>
<script>
export default {
    props: ["size"],
    computed: {
        sizeClass() {
            return this.size ? `text-${this.size}` : "text-[28px]";
        },
    },
};
</script>