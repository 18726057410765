<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height text-white">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 h-[42px] items-center">
        <router-link
          :to="{ name: 'hotelsNso' }"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <h1 class="text-xl">Hotels - {{ title }}</h1>
      </div>
    </div>
    <form
      @submit.prevent="save"
      class="flex flex-col gap-4 text-white w-full p-4"
    >
      <div class="flex flex-col gap-1 w-full">
        <label class="text-white font-bold">Name</label>
        <input
          type="text"
          v-model="name"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-col gap-1 w-full">
        <label class="text-white font-bold">Hotel ID</label>
        <input
          type="text"
          v-model="hotel_id"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-col gap-1 w-full">
        <label class="text-white font-bold">Chain</label>
        <input
          type="text"
          v-model="chain"
          disabled
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-col gap-1 w-full">
        <label class="text-white font-bold">Address</label>
        <input
          type="text"
          v-model="addr"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex gap-4">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">County</label>
          <input
            type="text"
            v-model="county"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">City</label>
          <input
            type="text"
            v-model="city"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
      </div>
      <div class="flex gap-4">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">State</label>
          <input
            type="text"
            v-model="state"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Zip Code</label>
          <input
            type="text"
            v-model="zipCode"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
      </div>
      <Contacts :contacts="contacts" :hotel="hotel" />
      <div class="flex gap-4 mt-4 justify-between">
        <router-link :to="{ name: 'hotelsNso' }" class="cancel-button">
          Cancel
        </router-link>
        <button :disabled="!valid || loading" class="green-button">
          <span
            v-if="loading"
            class="material-symbols-rounded animate-spin flex items-center"
          >
            autorenew
          </span>
          <span v-else> Save </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  doc,
  setDoc,
  addDoc,
  getDocs,
  query,
  collection,
  getDoc,
  where,
} from "firebase/firestore";
import { ListItem, ListItemSkeleton } from "@/components/hotels";
import router from "@/router";
import { save } from "@/utils/contact.jsx";
import Contacts from "@/components/contacts/Contacts.vue";
import {hasUniqueElementsInArray} from '@crewfare/utils'

export default {
  components: {
    ListItem,
    ListItemSkeleton,
    Contacts,
  },
  computed: {},
  data() {
    return {
      search: "",
      hotel: {},
      hotelName: "",
      loading: false,
      name: "",
      hotel_id: "",
      addr: "",
      chain: "",
      county: "",
      city: "",
      state: "",
      zipCode: "",
      accounts: [],
      accountsFiltered: [],
      manager: [],
      contacts: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    title() {
      if (this.$route.params.id) {
        return this.hotelName;
      } else {
        return "Add Hotel";
      }
    },
    valid() {
      return this.name.length > 0 && hasUniqueElementsInArray(this.contacts.map(contact => contact?.email?.toLowerCase()));
    },
  },
  watch: {
    search() {
      if (this.search.length === 0) {
        this.accountsFiltered = [];
      } else {
        this.accountsFiltered = this.accounts
          .filter(
            (account) =>
              account
                .data()
                .name.toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0 ||
              account
                .data()
                .email.toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0
          )
          .slice(0, 5);
      }
    },
  },
  async mounted() {
    let qUsers = query(collection(firestore, "accounts"));
    const accounts = await getDocs(qUsers);
    this.accounts = accounts.docs;
    this.manager.push({ id: this.account.id, ...this.account });
    let qChain = query(
      collection(firestore, "chains"),
      where("manager_ids", "array-contains-any", [this.account.id])
    );
    const chainDocs = await getDocs(qChain);
    const chain = chainDocs.docs[0];
    this.chain = chain.data().name;
    if (this.$route.params.id) {
      const hotelRef = doc(firestore, "hotels", this.$route.params.id);
      this.hotel = await getDoc(hotelRef);
      this.hotelName = this.hotel.data().name;
      document.title = `Launchpad - Crewfare - Hotel - ${this.hotelName}`;
      this.hotel_id = this.hotel.data().hotel_id || "";
      this.name = this.hotel.data().name;
      this.addr = this.hotel.data().addr || "";
      this.county = this.hotel.data().county || "";
      this.city = this.hotel.data().city || "";
      this.state = this.hotel.data().state || "";
      this.zipCode = this.hotel.data().zipCode || "";
      if (this.hotel.data().manager) {
        this.manager = this.hotel.data().manager;
      }
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.hotel.id)
      );
      const contacts = await getDocs(qContacts);
      this.contacts = contacts.docs.map((contact) => {
        return {
          name: contact.data().name || "",
          email: contact.data().email || "",
          role: contact.data().role || "",
          phone: contact.data().phone || "",
          contact,
        };
      });
    } else {
      document.title = `Launchpad - Crewfare - Hotel - Add Hotel`;
    }
  },
  methods: {
    async save() {
      if (this.loading || !this.valid) return;
      this.loading = true;
      if (this.$route.params.id) {
        await setDoc(doc(firestore, "hotels", this.$route.params.id), {
          ...this.hotel.data(),
          name: this.name,
          hotel_id: this.hotel_id,
          addr: this.addr,
          chain: this.chain,
          county: this.county,
          city: this.city,
          state: this.state,
          zipCode: this.zipCode,
          updated_at: new Date(),
        });
        await Promise.all(this.contacts.map(async (contact) => {
          await save({
            name: contact.name || "",
            email: contact.email || "",
            role: contact.role || "",
            phone: contact.phone || "",
            hotelId: this.hotel.id,
          });
        }));
        this.$store.commit("setToast", {
          content: `<p>Hotel Updated</p>`,
        });
      } else {
        const data = {
          name: this.name,
          hotel_id: this.hotel.id,
          addr: this.addr,
          chain: this.chain,
          county: this.county,
          city: this.city,
          state: this.state,
          zipCode: this.zipCode,
          created_at: new Date(),
        };
        const hotel = await addDoc(collection(firestore, "hotels"), data);
        await Promise.all(this.contacts.map(async (contact) => {
          await save({
            name: contact.name || "",
            email: contact.email || "",
            role: contact.role || "",
            phone: contact.phone || "",
            hotelId: this.hotel.id,
          });
        }));
        this.$store.commit("setToast", {
          content: `<p>Hotel Added</p>`,
        });
      }
      router.push({ name: "hotelsNso" });
    },
  },
};
</script>
