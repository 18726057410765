import store from '@/store';
import { firestore } from '@/utils/firebase';
import { getDocs, getDoc, addDoc, doc, query, collection, where, setDoc, orderBy } from 'firebase/firestore';
import { sendNotificationHotels, sendNotificationAdmin } from './notifications.jsx';
import { sendEmail, processVariables } from './emails.jsx';
import { setStatusHistory } from './rfp.jsx';
import { messageNewEmailContent, messageNewSubject } from '@/emails/message-new';
import { messageNewFileEmailContent, messageNewFileSubject } from '@/emails/message-new-file';
import { RECIPIENTS } from '@crewfare/commons/src/model/enumerators/messages';
import { NotificationTypes } from '@/enums/NotificationTypes';
import { isValidEmail, unique } from '@crewfare/utils';
import { isCrewfareAccount } from './account.jsx';
import { INITIATOR_TYPES } from '@crewfare/server-shared/messages/initatorTypes';
import { ViewsLocations } from '@crewfare/server-shared';

const saveMessageNotification = async (message, chatText) => {
  const messageRef = doc(firestore, 'messages', message);
  const messageData = await getDoc(messageRef);
  const data = {
    text: chatText,
    created_name: store.state.account.name,
    account_id: store.state.account.id,
    hotel_id: messageData.data().hotel_id || '',
    message_id: messageData.id,
    unread: true,
    accounts: messageData.data().accounts || '',
    created_at: new Date(),
  };
  await addDoc(collection(firestore, 'message_notification'), data);
  await setDoc(doc(firestore, 'messages', messageData.id), {
    ...messageData.data(),
    new_message: true,
    new_message_by_id: store.state.account.id,
    new_message_by_name: store.state.account.name,
    new_message_content: chatText,
    new_message_date: new Date(),
    last_interaction: new Date(),
  });
};

const getConversations = () => {
  switch (store.state.account.type) {
    case 'Admin':
      return getDataAdmin();
    case 'Internal':
      return getDataInternal();
    default:
      return getDataUser();
  }
};

const getDataInternal = async () => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('accounts', 'in', [store.state.account.id]),
    where('direct', '==', true),
    orderBy('last_interaction', 'desc'),
  );
  const messages = await getDocs(qMessages);
  return messages.docs;
};

const getDataUser = async () => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('direct', '==', true),
    where('hotel_id', 'in', store.state.account.hotelIds),
    orderBy('last_interaction', 'desc'),
  );
  const messages = await getDocs(qMessages);
  return messages.docs;
};

const getDataAdmin = async () => {
  let qMessages = query(
    collection(firestore, 'messages'),
    where('direct', '==', true),
    orderBy('last_interaction', 'desc'),
  );
  const messages = await getDocs(qMessages);
  return messages.docs;
};

const addFile = async (account, message, url, name) => {
  let message_data = message?.data?.() ? message.data() : { ...message };
  await addDoc(collection(firestore, 'files'), {
    url,
    name,
    created_name: account.name,
    created_id: account.id,
    message_id: message.id,
    created_at: new Date(),
  });
  await setDoc(doc(firestore, 'messages', message.id), {
    ...message_data,
    hasMessages: true,
    done: false,
    accounts: unique([...(message_data.accounts || []), account.email]),
    last_interaction: new Date(),
  });
  if (account.type.toLowerCase() === 'contact') {
    sendNotificationAdmin({
      type: NotificationTypes.MESSAGE,
      content: `${account.name} just added a new file to ${message_data.title}`,
      to: { name: ViewsLocations.MESSAGE_FILES, params: { id: message.id } },
    });
    message_data.accounts.forEach(async account => {
      const accountRef = doc(firestore, 'accounts', account);
      const accountData = await getDoc(accountRef);
      sendEmail({
        template: 'emails.new-default',
        to: accountData.data().email,
        subject: messageNewFileSubject,
        content: processVariables(messageNewFileEmailContent, {
          name: account.name,
          link: `${import.meta.env.VITE_URL}/messages/${message.id}/`,
        }),
      });
    });
  } else {
    if (message_data.internal_rfp || message_data.internal_hotel) return;
    sendNotificationHotels({
      type: NotificationTypes.MESSAGE,
      ids: [message_data.hotel_id],
      content: `${account.name} just a uploaded a new file to ${message_data.tile}`,
      to: { name: 'messagesFiles', params: { id: message.id } },
    });
    const qContacts = query(collection(firestore, 'contacts'), where('hotel_id', '==', message_data.hotel_id));
    const contacts = await getDocs(qContacts);
    const contactList = contacts.docs;
    contactList.forEach(contact => {
      sendEmail({
        template: 'emails.new-default',
        to: contact.data().email,
        subject: messageNewFileSubject,
        content: processVariables(messageNewFileEmailContent, {
          name: account.name,
          link: `${import.meta.env.VITE_URL}/messages/${message.id}/`,
        }),
      });
    });
  }
};

const addChat = async (account, message, content) => {
  await addDoc(collection(firestore, 'chat'), {
    text: content,
    created_name: account.name,
    created_id: account.id,
    message_id: message.id,
    created_at: new Date(),
  });
  let message_data = message?.data?.() ? message.data() : { ...message };
  saveMessageNotification(message.id, content);
  await setDoc(doc(firestore, 'messages', message.id), {
    ...message_data,
    direct: true,
    done: false,
    new_message: true,
    new_message_by_id: store.state.account.id,
    new_message_by_name: store.state.account.name,
    new_message_content: content,
    new_message_date: new Date(),
    last_interaction: new Date(),
    accounts: unique([...(message_data.accounts || []), account.email]),
    hasMessages: true,
  });
  let involvedEmails = [...(message_data.accounts || [])];
  if (message_data.hotel_id) {
    const qContacts = query(collection(firestore, 'contacts'), where('hotel_id', '==', message_data.hotel_id));
    const contacts = await getDocs(qContacts);
    const contactList = contacts.docs;
    contactList.forEach(contact => {
      involvedEmails.push(contact.data().email);
    });
  }
  involvedEmails = unique(involvedEmails.filter(email => isValidEmail(email)));
  involvedEmails.forEach(accountEmail => {
    if (accountEmail === account.email) return;
    sendEmail({
      template: 'emails.new-default',
      to: accountEmail,
      subject: messageNewSubject,
      content: processVariables(messageNewEmailContent, {
        name: account.name,
        content: content,
        link: `${import.meta.env.VITE_URL}/messages/${message.id}/`,
      }),
    });
  });
  if (!isCrewfareAccount(account)) {
    sendNotificationAdmin({
      type: NotificationTypes.MESSAGE,
      content: `${account.name} just sent a new message in ${message_data.title}`,
      to: { name: ViewsLocations.CHAT, params: { id: message.id } },
    });
  } else if (message_data.hotel_id) {
    sendNotificationHotels({
      type: NotificationTypes.MESSAGE,
      ids: [message_data.hotel_id],
      content: `${account.name} just sent a new message in ${message_data.title}`,
      to: { name: ViewsLocations.CHAT, params: { id: message.id } },
    });
  }
};

const sendMessageChain = async chain => {
  const account = store.state.account;
  const initiatorType = isCrewfareAccount(account) ? INITIATOR_TYPES.CREWFARE : INITIATOR_TYPES.HOTEL;
  let qMessages = query(
    collection(firestore, 'messages'),
    where('chain', '==', chain),
    where('recipient', '==', RECIPIENTS.CHAIN),
    where('initiatorType', '==', initiatorType),
  );
  const messages = await getDocs(qMessages);
  if (messages.empty) {
    const message = await addDoc(collection(firestore, 'messages'), {
      chain: chain,
      type: 'message',
      title: `${chain} Internal Message`,
      created_at: new Date(),
      last_interaction: new Date(),
      recipient: RECIPIENTS.CHAIN,
      accounts: [account.email],
      initiatorId: account.id,
      initiatorType: isCrewfareAccount(account) ? INITIATOR_TYPES.CREWFARE : INITIATOR_TYPES.HOTEL,
    });
    const messageRef = doc(firestore, 'messages', message.id);
    const messageData = await getDoc(messageRef);
    store.commit('setMessageOpened', messageData);
  } else {
    const message = messages.docs[0];
    store.commit('setMessageOpened', message);
  }
};

const sendHotelMessage = async hotel => {
  const account = store.state.account;
  const initiatorType = isCrewfareAccount(account) ? INITIATOR_TYPES.CREWFARE : INITIATOR_TYPES.HOTEL;
  let qMessages = query(
    collection(firestore, 'messages'),
    where('hotel_id', '==', hotel.id),
    where('recipient', '==', RECIPIENTS.HOTEL),
    where('initiatorType', '==', initiatorType),
  );
  const messages = await getDocs(qMessages);
  if (messages.empty) {
    const message = await addDoc(collection(firestore, 'messages'), {
      hotel_id: hotel.id,
      account_id: account.id,
      type: 'message',
      internal: true,
      hotel_name: hotel.data().name,
      title: hotel.data().name,
      created_at: new Date(),
      recipient: RECIPIENTS.HOTEL,
      last_interaction: new Date(),
      accounts: [account.email],
      ...data,
      initiatorId: account.id,
      initiatorGroupId: store.state.groups.selectedId,
      initiatorType,
    });
    const messageRef = doc(firestore, 'messages', message.id);
    const messageData = await getDoc(messageRef);
    store.commit('setMessageOpened', messageData);
  } else {
    const message = messages.docs[0];
    store.commit('setMessageOpened', message);
  }
};

const sendRFPMessage = async rfp => {
  console.log('rfp', rfp);
  const account = store.state.account;
  let qMessages = query(
    collection(firestore, 'messages'),
    where('hotel_id', '==', rfp.hotel_id),
    where('proposal_id', '==', rfp.id),
    where('recipient', '==', RECIPIENTS.PROPOSAL),
  );
  const messages = await getDocs(qMessages);
  if (messages.empty) {
    const message = await addDoc(collection(firestore, 'messages'), {
      proposal_id: rfp.id,
      hotel_id: rfp.hotel_id,
      type: 'message',
      recipient: RECIPIENTS.PROPOSAL,
      title: `${rfp.name} - ${rfp.hotel_name}`,
      created_at: new Date(),
      last_interaction: new Date(),
      accounts: [account.email],
      initiatorId: account.id,
      initiatorGroupId: rfp.userGroupId,
      initiatorType: isCrewfareAccount(account) ? INITIATOR_TYPES.CREWFARE : INITIATOR_TYPES.HOTEL,
    });
    const messageRef = doc(firestore, 'messages', message.id);
    const messageData = await getDoc(messageRef);
    store.commit('setMessageOpened', messageData);
  } else {
    const message = messages.docs[0];
    store.commit('setMessageOpened', message);
  }
};

const noteAdded = async (field, rfp) => {
  const note = rfp.data().note;
  note.push({ field });
  let data = rfp.data();
  data = await setStatusHistory({
    data,
    content: `Note added at ${field}`,
    status: data.status,
  });
  await setDoc(doc(firestore, 'rfp_hotel', rfp.id), {
    ...data,
    note,
    updated_at: new Date(),
  });
};

const noteDone = async (field, rfp) => {
  const note = rfp.data().note;
  note.splice(
    note.findIndex(note => note.field === field),
    1,
  );
  let data = rfp.data();
  data = await setStatusHistory({
    data,
    content: `Note mark as done at ${field}`,
    status: rfp.data().status,
  });
  await setDoc(doc(firestore, 'rfp_hotel', rfp.id), {
    ...data,
    note,
    updated_at: new Date(),
  });
};

export {
  getConversations,
  saveMessageNotification,
  addChat,
  addFile,
  sendMessageChain,
  sendRFPMessage,
  noteAdded,
  noteDone,
  sendHotelMessage,
};
