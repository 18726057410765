<template>
  <div v-if="account" class="text-white px-6 py-3 bg-[#23262F]">
    <div class="flex flex-row justify-between">
      <Breadcrumb icon="th-large" :items="['Hotels']" />
      <div>
        <div class="flex items-center gap-4 relative z-30">
          <input
                type="search"
                v-model="search"
                class="bg-gray-700 rounded px-4"
                placeholder="Search..."
          />
          <button
            @click="exportHotels"
            class="border border-2 border-[#A4CDE3] hover:bg-[#A4CDE3] hover:text-black text-[#A4CDE3] px-4 py-2 rounded-full flex gap-2"
          >
            <span class="material-symbols-rounded"> download </span>
            Export Hotels
          </button>

          <button
            @click="showModal = true"
            class="border border-2 border-[#A4CDE3] hover:bg-[#A4CDE3] hover:text-black text-[#A4CDE3] px-4 py-2 rounded-full flex gap-2"
          >
            <span
              class="material-symbols-rounded"
              :class="importing && 'animate-spin'"
            >
              {{ importing ? "autorenew" : "upload" }}
            </span>
            {{ importing ? `Importing Hotels` : "Upload Hotels" }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-col rounded pb-4 relative mt-4">
      <Filters
        v-if="selectedState && hotels.length > 0"
        :filteredHotels="filteredHotels"
        @filter="filter"
      />
      <RouterView
        :search="search"
        :actual="actual"
        :loading="loading"
        @togglePage="togglePage"
        @toggleState="toggleState"
      />
    </div>
    <div id="mapHolder"></div>
    <Upload v-if="showModal" @close="showModal = false" />
  </div>
</template>

<script>
import Breadcrumb from "@/components/default/Breadcrumb.vue";
import Pagination from "@/components/default/Pagination.vue";
import { Filters, ListItem, ListItemSkeleton } from "@/components/hotels";
import Upload from "@/components/hotels/Upload.vue";
import router from "@/router";
import { isCrewfareAccount } from "@/utils/account";
import { firestore } from "@/utils/firebase";
import { exportHotels } from "@/utils/hotel.jsx";
import {
  collection,
  getDocs,
  orderBy,
  query
} from "firebase/firestore";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";

export default {
  components: {
    Breadcrumb,
    ListItem,
    ListItemSkeleton,
    Filters,
    Pagination,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
    Upload,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    hotelImporting() {
      return this.$store.state.hotelImporting;
    },
  },
  data() {
    return {
      showModal: false,
      importing: false,
      actual: 0,
      loading: false,
      hotels: [],
      filteredHotels: [],
      stateList: [],
      selectedState: null,
      file: null,
      type: null,
      search: '',
    };
  },
  mounted() {
    if (
      !isCrewfareAccount(this.account)
    ) {
      router.push({ name: "rfpsList" });
    }
  },
  methods: {
    toggleState(state) {
      this.selectedState = state;
    },
    togglePage(page) {
      this.actual = page;
    },
    async exportHotels() {
      const qHotels = query(collection(firestore, "hotels"), orderBy("city"));
      const hotels = await getDocs(qHotels);
      exportHotels(hotels.docs);
    },
  },
};
</script>
