<template>
  <div class="text-white w-full px-4 py-2" :class="className">
    <router-link
      class="flex gap-4 w-full items-center"
      v-if="notification.to"
      :to="notification.to"
    >
      <div class="flex gap-2 w-full flex-col">
        <div>{{ notification.content }}</div>
        <span class="text-xs text-[#777E90] flex justify-between">
          {{ formatedUpdatedAt }}

          <font-awesome-icon icon="bookmark" />
        </span>
      </div>
    </router-link>
    <div v-else class="flex w-full gap-4 items-center">
      <div class="flex gap-2 w-full flex-col">
        <div>{{ notification.content }}</div>
        <span class="text-xs text-[#777E90] flex justify-between">
          {{ formatedUpdatedAt }}

          <font-awesome-icon icon="bookmark" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import moment from "moment";
export default {
  props: ["notification"],
  computed: {
    className() {
      return [
        this.notification.unread ? "bg-gray-800" : "bg-[#272C33]",
        "rounded-lg px-4 py-2 hover:bg-gray-700 hover:text-white",
      ].join(" ");
    },
    formatedUpdatedAt() {
      if (!this.notification.created_at) return "";
      const created_at = getMomentFromFirebaseDate(this.notification.created_at);
      return created_at.fromNow();
    },
  },
};
</script>
