export const DEFAULT_CREWFARE_CONTRACT = `
        <div style="padding: 0 12px">
          <h2 class="text-xl text-center mb-4">CREWFARE HOTEL AGREEMENT</h2>
          <div style="margin-top: 12px" >
            This Master Hotel Agreement (this "Agreement") is made and entered into as
            of
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{effective_date_formated}}</span>
            (the "Effective Date") by and between Crewfare LLC ("Group") and
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_name}}</span>
            (the "Hotel"). Group and Hotel are sometimes referred to herein
            individually as a "Party" and collectively as the "Parties".
          </div>
          <div style="margin-top: 12px" class="text-xl font-bold text-center py-4" >
            TENTATIVE NAME OF EVENT: <span style="color: rgb(202, 138, 4); font-weigth: bold">{{rfp_name}}</span> (the "Event").
          </div>
          <table
            style="margin-top: 12px; width: 100%"
            cellpadding="0"
            cellspacing="0"
            
          >
            <tr>
              <td valign="top" width="50%">
                <h3 class="text-xl">GROUP:</h3>
                <div style="margin-top: 12px">Company: Crewfare LLC</div>
                <div style="margin-top: 12px">Name: Jason van Esso</div>
                <div style="margin-top: 12px">Title: Partner</div>
                <div style="margin-top: 12px">
                  Address: 2678 Edgewater Court, Weston FL 33332
                </div>
                <div style="margin-top: 12px">Telephone: 954-591-9005</div>
                <div style="margin-top: 12px">Email: jason@crewfare.com</div>
              </td>
              <td valign="top" width="50%">
                <h3 class="text-xl">HOTEL:</h3>
                <div style="margin-top: 12px">
                  Hotel Name (that the group will be staying): <span style="color: rgb(202, 138, 4); font-weigth: bold">{{hotel_name}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Name:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_person_name}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Title:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_title}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Address (that the group will be staying):
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_address}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Telephone:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_telephone}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Email:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_email}}</span>
                </div>
              </td>
            </tr>
          </table>
          <h3 style="margin-top: 12px" class="text-xl">1. TERMS.</h3>
          <div style="margin-top: 12px">
            <span class="underline">A. Reservation of Rooms.</span> The Hotel will
            reserve, exclusively for the Event's team, staff, fans, or other guests
            (collectively the "Guests" or the "Group" or the "Patrons"), sleeping
            rooms (the "Rooms") on the event dates below (the "Reservation Period")
            subject to the terms of this Agreement.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">B. Courtesy Room Block.</span> Room rates have
            been established for Group's room block ("Room Block"). The Hotel is
            obligated to hold the Room Block available to the Group up until the
            Cut-Off Date. There is no commitment by Group to pick up these rooms.
          </div>
          <div style="margin-top: 12px" >
            <span class="underline">C. Room Assignments.</span>
            No later than
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{cutoff_days}}</span>
            days prior to the Event Date (the "Cut-Off Date")
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{cutoff_date_formated}}</span>, the Group will notify the Hotel of such assignments by providing a
            Rooming List to the Hotel with the assigned names to the Hotel. It is
            expressly agreed and understood that the Group makes no representations
            that the number of Rooms reserved under this Agreement will be assigned.
            Only assigned Rooms will be paid for. After the Cut-off Date, Rooms
            reserved under this Agreement, but not assigned to specific Patrons, may
            be released, at no cost to the Group, for sale to the general public.
            After the Cut-off Date, Hotel will accept additional reservations from the
            Group, subject to availability, at Group Rates
          </div>
          <div style="margin-top: 12px">
            <span class="underline">D. Room Types.</span>
          </div>
          {{room_types_table}}
          <div style="margin-top: 12px" >
            <span class="underline">E. Room Inventory (the "Inventory").</span>&nbsp;
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{start_date_formated}}</span>
            through
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{end_date_formated}}</span>
            shall be the "Peak Nights". The number of rooms below shall be allocated
            and committed to Group as Rooms for the Guests.
          </div>
          {{room_list_table}}
          <div style="margin-top: 12px">
            <span class="underline">F. Room Rates. (the "Room Rates").</span>
          </div>
          {{room_rate_table}}
        {{group_honored_rate}}
        <div style="margin-top: 12px">
          <span class="underline">G. Taxes & Fees.</span> Rates above are quoted
          exclusive of applicable state and local taxes, fees and other charges
          known as the "Ancillary Costs". All Ancillary Costs must be stated in the
          below chart in order for the Hotel to be compensated for those fees
        </div>
        <table
          style="
            width: 100%;
            border-color: #000;
            border-collapse: collapse;
            margin-top: 12px;
          "
          border="1"
          class="w-full"
          
        >
          <tr>
            <th class="px-4 py-1 border border-black text-center">Charge</th>
            <th class="px-4 py-1 border border-black text-center">
              Per Room Night
            </th>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Local Occupancy Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_local_occupancy_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">State Occupancy Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_state_occupancy_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">City Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_city_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Convention & Tourism Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_convention_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">VAT Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_vat_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">SAT Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_sat_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Municipal Accommodation Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_municipal_accomodation_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">HST Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_hst_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Sustaintability Fee</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_sustaintability_fee}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Other Taxes</td>
            <td class="px-4 py-1 border border-black text-center">
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{hotel_taxes}}
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Resort Fee</td>
            <td class="px-4 py-1 border border-black text-center">
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{resort_fee}}
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Other Fees</td>
            <td class="px-4 py-1 border border-black text-center">
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{other_fees}}
              </span>
            </td>
          </tr>
        </table>
        <div style="margin-top: 12px">
          <span class="underline">H. Pricing Confidentiality.</span> The prices
          contained in this Agreement are considered confidential and Hotel will not
          disclose such information to any third parties or Patrons without Group's
          prior written consent.
        </div>
        <div style="margin-top: 12px">
          <span class="underline">I. Rate Integrity.</span> If, from the date of
          contracting through the Cut-Off Date, it is determined that a qualifying
          lower single/double rate for the same room type, dates, bed type, number
          of guests, same or better restrictions and policies is offered to other
          guests over the Group's scheduled dates, Hotel agrees to use commercially
          reasonable efforts to promptly remove the lower published rate from all
          distribution channels. This commitment only applies to direct published
          rates made available to the general public.
        </div>
        <div style="margin-top: 12px"  >
          <span class="underline">J. Commissions and Rebates.</span> Rooms occupied
          and actualized by Guests shall include a
          <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{commission}}</span>
          commission and
          <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{rebate}}</span>
          rebate, payable per actualized room night booked in the Room Block and all
          room nights found outside of the Room Block, regardless of actual guest
          room rate. For cancelled reservations or No-Shows where the reservation
          was guaranteed with a deposit or major credit card, commission/rebates are
          due on any charges that Hotel is able to collect from the Patrons causing
          the cancellation or No-Show, as applicable. Any commission or rebate must
          be detailed in a final folio to Group, sent no later than seven (7) days
          after the Event. Commissions and rebates are payable to Crewfare LLC (IATA
          #:
          <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{iata}}</span>) no later than thirty (30) days from invoice date.
        </div>
          <div style="margin-top: 12px" >
            <span class="underline">K. Event Planner Program.</span> Jason van Esso
            (collectively, the "Event Planner") is eligible to earn an event planner
            bonus for qualifying events. Event Planner is eligible to earn
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{reward_points}}</span>. The Event Planner's member number is
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{crewfare_members}}</span>.
          </div>
          <div style="margin-top: 12px" >
            <span class="underline">L. Complimentary Rooms.</span> For its use during
            the dates of the Event, and ancillary planning dates, Group will be
            entitled to
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{comp_rooms}}</span>
            complimentary room night for every room nights occupied, on a cumulative
            basis. Hotel agrees to credit to Group's Master Account the actual value
            of the earned complimentary rooms.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">M. Exclusive Right to Use Rooms.</span> Hotel
            acknowledges and agrees that Group shall have the exclusive right to sell,
            rent, or otherwise assign the Rooms. It is expressly agreed that where the
            Group resells Rooms, the rates that Group charges may vary from the Room
            Rates listed in this Agreement. Hotel agrees (i) to keep the rate charged
            to the Group for any given Room confidential between the Parties, and (ii)
            not to disclose the rate charged to the Group to any Patrons, whether
            directly or indirectly, under any circumstances. Notwithstanding anything
            to the contrary in this Agreement, substitution of any reservations (i.e.,
            a change to the name/details included on the Rooming List) by the Group
            shall be allowed at any time up to and through the time of the Event.
          </div>
          <h3 class="text-xl">2. HOTEL TAXES AND OTHER FEES.</h3>
          <div style="margin-top: 12px">
            Hotel sleeping room rates are subject to applicable state and local taxes
            in effect at the time of check-in. During the term of this Agreement,
            Hotel shall not impose any other mandatory charge on guests or master
            accounts associated with the Group or the Event. Hotel will notify Group
            of the tax requirements and any changes in the tax requirements.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">A. Master Account & Payment Arrangements.</span>
            Master account credit card will the expenses for the room and applicable
            tax for the room only. All Guests must provide credit card at check-in for
            their own incidental charges, resort fees and other charges. Group shall
            not be responsible for any incidental charges for any Hotel guests or any
            other charges without written consent from Group
          </div>
          <div style="margin-top: 12px">
            <span class="underline">B. Deposit Schedule.</span> Group and Hotel agree
            to the following deposit schedule. Hotel agrees to obtain approval from
            Crewfare's accounting team,
            <a href="mailto:accounting@crewfare.com" class="underline">
              accounting@crewfare.com</a>, (Tony Tohme) before making any such charges to the credit card on file
            at any time
          </div>
          <table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "
            border="1"
            class="w-full"
          >
            <tr>
              <th></th>
              <th class="border border-black px-4 py-2">Due Date</th>
              <th class="border border-black px-4 py-2">
                Amount (Not Including Service Charge or Tax)
              </th>
            </tr>
            <tr>
              <th class="border border-black px-4 py-2">Upon Signing</th>
              <td class="border border-black px-4 py-2">Upon signing of Agreement</td>
              <td class="border border-black px-4 py-2">
                Credit Card Guarantee Only ($0.00 deposit)
              </td>
            </tr>
            <tr>
              <th class="border border-black px-4 py-2">Second Deposit</th>
              <td class="border border-black px-4 py-2">At Cut-Off Date:</td>
              <td class="border border-black px-4 py-2">
                25% of Anticipated Master Account Balance (Based On Group Pick Up)
              </td>
            </tr>
            <tr>
              <th class="border border-black px-4 py-2">Final Deposit</th>
              <td class="border border-black px-4 py-2">3 Days Ahead of Arrival:</td>
              <td class="border border-black px-4 py-2">
                100% Of Anticipated Master Account Balance.
              </td>
            </tr>
          </table>
          <div style="margin-top: 12px">
            <span class="underline">C. Early Departure.</span> Neither Group nor the
            Guests will be charged for early departure fees.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">D. No-Shows.</span> Hotel agrees that it will not
            release a No-Show accommodation without prior approval from Crewfare. A
            No-Show room night billed to the master account is considered an
            actualized room night and will count towards Group's final pick-up.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">E. Construction, Renovation, Hotel Closings.</span>
            Hotel agrees to immediately notify the Group of any plans for renovation
            or construction. Under such circumstances, the Room Rate shall be reduced
            by fifty percent (50%) and the Group shall have the right to reduce or
            terminate their planned use of the Hotel upon written notice to Hotel and
            if so terminated, the Group shall be indemnified by the Hotel for any
            costs and expenses incurred in hosting the Patrons at the Hotel.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">F. Americans with Disabilities Act.</span> As
            among the parties, Hotel shall be responsible for compliance with the
            Americans with Disabilities Act ("ADA") including, but not limited to,
            compliance with respect to: (i) the Hotel's policies practices, procedures
            and eligibility criteria; (ii) the provision of auxiliary aids and
            services in the Hotel; (iii) architectural, communications and
            transportation barriers in the Hotel; and (iv) the provision of wheelchair
            seating spaces in assembly areas
          </div>
          <div style="margin-top: 12px">
            <span class="underline">G. Insurance.</span> Hotel will maintain full
            insurance coverage throughout the term of this Agreement, in such amounts,
            coverages, types and insurers as are maintained by other hotels of similar
            quality in the State of Florida, against all third party liability,
            including insurance in respect of liability to guests and others using the
            hotel facilities and liability to the employees and agents of the Hotel,
            and will supply certificates of insurance to the Group upon request.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">H. Change in Hotel Management or Ownership.</span>
            Hotel shall notify Group of any change in Hotel management or ownership.
            Group shall have the right to terminate this Agreement without liability
            if, after the time of contracting but before the Event, the Hotel changes
            the hotel brand, such that the level of service provided by the new brand
            is lower than Hotel's original brand at the time of contracting. Any
            change in the point of contact must also be communicated to the Group.
            Conversely, new management or ownership agrees to honor Group under terms
            set forth in this agreement
          </div>
          <div style="margin-top: 12px">
            <span class="underline">I. Concessions</span>
          </div>
          <ol class="upgrade-list" >
            <li>
              Suite upgrades:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{suite_upgrades}}</span>
            </li>
            <li>
              Wi-fi:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{wifi}}</span>
            </li>
            <li>
              Parking:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{parking}}</span>
            </li>
            <li>
              Breakfast:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{breakfast}}</span>
            </li>
            <li>
              Additional terms:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{terms}}</span>
            </li>
            <li>Check-In Age: <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_check_in_age}}</span></li>
            <li>Check-In Time: <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_check_in_time}}</span></li>
            <li>Check-Out Time: <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_check_out_time}}</span></li>
          </ol>
          <div style="margin-top: 12px">
            <span class="font-bold">3. COMPLIANCE WITH LAW.</span> Each of the Parties
            undertakes to comply with all applicable laws, rules regulations and
            ordinances, whether applicable nationally, at federal, state or local
            level or by a competent foreign lawmaker. For the avoidance of doubt, this
            includes applicable laws, rules, regulations and ordinances which relate
            to the processing of personal data/personally identifiable information.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">4. ACCEPTANCE.</span> Once executed, this
            Agreement shall be effective as of the date first above mentioned (the
            "Effective Date")
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">5. INTELLECTUAL PROPERTY.</span> Hotel shall not
            use any Intellectual Property Rights of Group, Event, or any of their
            respective affiliates. No such rights are granted pursuant to this
            Agreement. For these purposes, "Intellectual Property Rights" means all
            patents, trademarks, and copyright of Group or Event
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">6. INDEMNIFICATION.</span> Hotel agrees to
            indemnify, defend and hold harmless Group, Group's owners, managers,
            partners, subsidiaries, affiliates, officers, directors, employees and
            agents (collectively, the "Group Indemnified Parties"), from and against
            any and all Claims (as such term is defined above) arising out of or
            relating to the Event that is the subject of this Agreement but only to
            the extent any such Claim(s) to the extent any such Claim(s) arise out of
            the negligence, gross negligence or intentional misconduct of Hotel's
            employees, agents, or contractors.
          </div>
          <h3 class="text-xl">7. MISCELLANEOUS.</h3>
          <div style="margin-top: 12px">
            <span class="font-bold">A.</span> Severability Hotel agrees that it will
            not release a No-Show accommodation without prior approval from Crewfare.
            A No-Show room night billed to the master account is considered an
            actualized room night and will count towards Group's final pick-up.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">B.</span> Entire Agreement This Agreement,
            including any attachments, constitutes the entire agreement of the parties
            and its provisions supersede any and all prior and contemporaneous
            agreements or understandings relating to the same subject matter
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">C.</span> Governing Law. This Agreement shall be
            governed and construed in accordance with the laws of the State of
            Florida, USA, without regard to its choice-of-law principles. Any claim
            arising under this Agreement shall be prosecuted exclusively in a federal
            or state court of competent jurisdiction located within Miami-Dade
            County,Florida, USA and the parties consent to the jurisdiction of such
            court and to the service of process by mail. Notwithstanding anything to
            the contrary contained herein, each Party hereby waives, to the maximum
            extent not prohibited by law, any right it may have to claim or recover in
            any legal action or proceeding arising under this Agreement any special,
            exemplary, punitive or consequential damages.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">D.</span> Successors and Assigns. This Agreement
            may not be transferred or assigned by any party without the prior written
            consent of the other parties, which may not be unreasonably withheld or
            delayed, provided that F1R may assign this Agreement to its subsidiary
            undertakings, its holding company or holding companies and other
            subsidiary undertakings of each of its holding companies from time to
            time, each, an "Affiliate", and "Affiliates" shall mean all or more than
            one such Affiliate. Any attempted assignment or transfer in violation of
            this Section shall be void and a material breach of this Agreement. This
            Agreement will be binding upon any authorized successors or assigns. Prior
            to any transfer or assignment of this Agreement, the party seeking to
            assign or transfer this Agreement shall obtain a written acknowledgement
            of the binding nature of this Agreement from the successor or assignee
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">E.</span> Authority Hotel hereby represents and
            warrants to Crewfare that: (i) Hotel has the authority to enter into this
            Agreement; (ii) its execution and performance of this Agreement does not
            and will not conflict with or violate any law, regulation or agreement by
            which it is bound; and (iii) Hotel has obtained all consents, approvals
            and/or authorizations necessary to make this Agreement binding upon the
            Hotel, the owner of the Hotel, Hotel's management and all other persons or
            entities which ay have any interest in the Hotel, its management and/or
            premises and upon any and all successors purchaser or transferees which
            may obtain any interest in Hotel, its management and/or premises during
            the term of this Agreement. At Crewfare's request, Hotel shall use its
            best efforts to obtain a written non-interference agreement from any
            person or entity which may have any ownership, mortgage or deb-related
            interest in Hotel. This non-interference agreement shall provide that any
            successor-in-title by foreclosure or to other conveyance will be bound by
            this Agreement.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">F.</span> Notices. All notices, demands, requests
            or other communications relating to this agreement shall be in writing and
            shall be mailed, first class, or transmitted by hand delivery or email,
            addressed to the contacts as included in the beginning of the Agreement.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">G.</span> Amendments. This Agreement may not be
            modified, amended, terminated or otherwise changed, except by a written
            instrument executed by each of the Parties.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">H.</span> Interpretation. No provisions of this
            Agreement shall be construed against or interpreted to the disadvantage of
            any party to this Agreement by any court or other governmental or judicial
            authority by reason of such party having or being deemed to have drafted
            or dictated such provision.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">I.</span> Independent Contractors. The status of
            the Parties under this Agreement shall be that of independent contractors.
            No Party shall be authorized to waive any right, or assume or create any
            contract or obligation of any kind in the name of, or on behalf of, the
            others or to make any statement that it has the authority to do so.
            Nothing in this Agreement shall be construed as establishing a
            partnership, joint venture, agency, employment or other similar
            relationship among the parties hereto
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">J.</span> Waiver. The failure of any party to
            enforce any condition or part of this Agreement at any time shall not be
            construed as a waiver of that condition or part, nor shall it forfeit any
            rights to future enforcement thereof.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">K.</span> Counterparts. This Agreement may be
            executed and delivered in counterparts, each of which shall be deemed an
            original and all of which, taken together, shall constitute one
            instrument. Copies of executed counterparts of this Agreement transmitted
            by email shall be considered original executed counterparts provided that
            receipt of such email is confirmed.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">L.</span> Costs Each Party shall bear its own
            costs in connection with the preparation, negotiation and execution of
            this Agreement
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">M.</span> Force Majeure. Neither party shall be
            liable for any failure or delay in performance of its obligations under
            this Agreement arising out of or caused, directly or indirectly, by
            circumstances beyond its reasonable control, including, without
            limitation, cancellation of the Event for whatever reason; government
            regulations prohibiting travel to the city in which the Hotel is located
            over the Event dates and/or the issuance of a "Level 3 Travel Warning" by
            the Centers for Disease Control that specifically advises travelers to
            avoid all non-essential travel to the city in which the Hotel or the Event
            is located and which travel advisory is in effect over the Event dates;
            acts of God; earthquakes; fires; floods; wars; civil or military
            disturbances; acts of terrorism; sabotage; strikes; epidemics; riots;
            power failures; computer failure and any such circumstances beyond its
            reasonable control as may cause interruption, loss or malfunction of
            utility, transportation, computer (hardware or software) or telephone
            communication service; accidents; labor disputes; acts of civil or
            military authority; governmental actions; or inability to obtain labor,
            material, equipment or transportation. The affected party may terminate
            this Agreement without liability upon providing written notice to the
            other party within ten (10) days of the occurrence. If the Event is
            properly cancelled this shall be considered a valid impossibility force
            majeure occurrence, then upon written request by Group, Hotel agrees to
            refund to Group all prepaid deposits or advance payments paid to Hotel
            without deduction, less any expenses incurred by Hotel in preparation for
            the Event.
          </div>
          <div style="margin-top: 12px">
            IN WITNESS WHEREOF, the Parties have executed this Agreement as of the
            Effective Date.
          </div>
        </div>
      `;

export const DEFAULT_QUINT_CONTRACT = `
  <div style="padding: 0 12px;">
  <p>
    This agreement made and entered into electronically between <span style="font-weight: bold; color: #0000f5">Quint</span> (COMPANY) and <span style="font-weight: bold; color: #0000f5">{{hotel_name}}</span>, also referred to as
    HOTEL and throughout this agreement as You and HOTEL, whose place of business is: <span style="font-weight: bold; color: #0000f5">{{hotel_address}}</span>
  </p>
  <p>
    This Agreement constitutes the entire understanding between the parties and supersedes any previous communications,
    representations or agreements, whether written or oral. HOTEL and COMPANY have agreed to and have executed the
    Agreement by their authorized representative as of the dates on this contract. Any HOTEL generated agreement shall
    be considered supplemental to this agreement. If HOTEL generated agreement and this agreement conflict on any terms,
    the terms of the HOTEL generated agreement shall not supersede the terms of this agreement.
  </p>
  <p>
    All HOTEL information contained herein was entered into an electronic Request For Proposal and converted into this
    document.
  </p>
  <p><b>Event/Group Name:</b> <span style="color: #0000f5">{{event_name}}</span></p>
  <p>
    <b>Rate Type:</b> Commissionable: <span style="color: #0000f5">{{commission}}</span> of total room revenue generated is payable to Quint, IATA Number 45699452
  </p>
  <p><b>Rebate of:</b> <span style="color: #0000f5">{{rebate}}</span> per room night, collected by Quint on behalf of the Event.</p>
  <p>
    You agreed to credit <span style="color: #0000f5">{{reward_points}}</span> travel planner reward point(s) for each revenue dollar produced to account number: <span style="color: #0000f5">{{crewfare_members}}</span>
  </p>
  {{quint_room_list}}
  <p>
    <!-- TODO: Doesn't exist yet -->
    <b>Minimum number of nights per guest during event dates:</b> <span style="color: #0000f5">{{min_nights}}</span><br />
    <b>Complimentary rooms offered:</b> <span style="color: #0000f5">{{comp_rooms}}</span> complimentary rooms offered.<br />
    Unused complimentary rooms, accrued during Event, shall be paid to Quint (cash equivalent based on the average daily
    rate).
  </p>
  <p><b>Block release date:</b> <span style="color: #0000f5">{{cutoff_date_formated}}</span></p>
  <p>
    <b>Tax Rates:</b> The Parties agree to cooperate in good faith to address any changes in applicable hotel taxes. Any
    such changes must be disclosed to Quint within two weeks of hotel's notification of the updated taxes.
  </p>
  <p style="margin-left: 40px">Tax Rate Percent: <span color="#0000f5">{{total_taxes_percentage}}</span></p>
  <p style="margin-left: 40px">Tax Rate Flat: <span color="#0000f5">{{total_taxes_flat}}</span></p>
  <p><b>Minimum Age Requirement to Check In:</b> <span style="color: #0000f5">{{hotel_check_in_age}}</span> years old</p>
  <p><b>Additional Fees:</b> {{parking}}</p>
  <p><b>Breakfast:</b> Complimentary Breakfast Included in Room Rate: <span style="color: #0000f5">{{breakfast}}</span></p>
  <p><b>Other considerations given to guests of this event by hotel:</b> <span style="color: #0000f5">{{terms}}</span></p>
  <p>
    <!-- TODO: Doesn't exist yet -->
    <b>Individual Cancellation Policy:</b> <span style="color: #0000f5">Cancellation must be received by {{cancellation_time}}, {{cancellation_days}} day(s) prior to day of arrival or
    will result in a penalty of {{cancellation_penalty}} night's room plus tax.</span>
  </p>
  <p>
    <b>Reservation Terms & Procedures:</b> As per our mutual agreement and for tracking purposes, you should not book
    participants to this event directly. Instead, please direct all guests that call to make reservations from this
    block to contact QUINT at: rooms@quint.co
  </p>
  <p>
    Should the Hotel receive any calls or inquiries about the Event, please refer them to Quint at
    <a style="color: #0000f5" href="mailto:rooms@quint.co">rooms@quint.co</a> or
  </p>
  <p>+1 (980) 356-­4209</p>
  <p>
    Rooming List Info: Quint agrees to send a confirmed rooming list to the Hotel on, or before the Block Release Date.
    Once rooming list is entered, Hotel shall send Quint a copy of the rooming list from Hotel’s property management
    system so that Quint can review. Hotel to monitor, make and confirm any rooming list changes from Quint.<br />
    Method ofPayment: <span style="color: #0000f5; font-weight: bold">Master Account</span>
  </p>
  <p>
    Hotel to send Quint an invoice and a credit card authorization form for room and tax for all guest rooms in the
    block to be paid. ALL RATES ARE TO BE MASKED. Hotel shall NOT share rates with guests. Any other charges shall be
    the responsibility of the individual guest. Hotel is responsible for collecting guest credit cards for incidental
    charges.
  </p>
  <h2>TERMS AND CONDITION:</h2>
  <p>
    <b>Courtesy Block</b>. Hotel agrees to remove from its inventory the total Group Room Block as set forth in this
    Agreement and provide the inventory and group rate to the Group to utilize. At the cutoff date, Hotel will release
    any unreserved rooms back into Hotel inventory, without penalty or financial obligation to the Group.
  </p>
  <p>
    Hotel agrees to honor the rates, availability and other terms provided above. Hotel shall direct all group/event
    participant reservations through contracted block and stated rate code and apply all reservations made for
    group/participant as reservations payable to Quint according to the commission terms defined above. Contracted block
    is exclusively retained by Quint up to the Block Release Date and Hotel may not knowingly place group/event
    participants at Hotel through another rate code bypassing the Quint block.
  </p>
  <p>Quint may assign or sell guest rooms through Quint’s designated partner(s).</p>
  <p>
    <b>Lowest Rate Clause.</b> Rates are guaranteed to be the lowest rates available for block dates other than Hotel
    contracted airline crew rates or other LNRs, local negotiated rates. Hotel agrees that no lower rates will be
    offered to any other group or posted on any public channels (i.e.: Hotel website, Expedia or other GDS/OTA channels)
    from the date of signature of the agreement until the date of the event. This would include any pre­paid or non­
    refundable rates as well on any of the public channels. If a lower rate is posted, offered, or contracted over block
    dates, Hotel will have 48 hours to remove the lower rate or will reduce the Group’s contracted rate to $10.00 below
    the lowest rate.
  </p>
  <p>
    <b>Invoice Process:</b> Commissions and/or rebates are payable without demand for all guests registered under the
    assigned block name. The hotel will be responsible for paying commissions and/or rebates for all rooms consumed by
    the group under the negotiated block whether or not the guest name matches.
  </p>
  <p>
    <b>No-Show</b><br />
    Guests within the block are allowed to check in at any time during the reservation check-in/check-out period. If a
    guest does not show up, the hotel must notify Quint. Quint will contact the guest to determine whether to reinstate
    or cancel reservation(s). Hotel shall not release such guest’s room without approval from an authorized
    representative of Quint. In the event of a no-show and Quint has released the room(s), Parties agree to a one-night
    net room charge and the balance shall be refunded to the Master Account.
  </p>
  <p>
    <b>No Walk:</b> No guests booked by the Group are to be relocated to another Hotel over the event dates, or pre/post
    dates immediately leading up to, or following, the event. Should the Hotel oversell their inventory, Hotel agrees to
    walk other guests, not identified as being part of the Group rooming list first. In the event the Hotel is not able
    to provide a guest room for a confirmed reservations after relocating all transient and other group reservations
    first, and the room is needed by the Group, Hotel shall notify Group immediately and use commercially reasonable
    efforts, at its own expense, to do each of the following:
  </p>
  <ul>
    <li>
      Relocate the displaced guest to the closest, Group approved, comparable hotel (the “Alternate Hotel”) for the
      nights Hotel is not able to provide guest rooms to event attendees.<br />
      Should Hotel’s availability open back up after relocation, guest will have the option to move back to Hotel for
      the remainder of their stay or remain at the Alternate Hotel. Should guest choose to remain at the Alternate
      Hotel, guest shall pay a rate equal to or less than the rate of their confirmed reservation with Hotel. Any
      difference in rate (and resort fee) plus applicable taxes that is more than their originally confirmed reservation
      with Hotel will be paid for by the Hotel.
    </li>
    <li>
      Provide transportation to and from such Alternative Hotel for each night Hotel is not able to provide guest rooms
      to Event attendees.
    </li>
    <li>
      Provide two (2) long distance/International telephone calls (not to exceed 10 minutes) for each relocated guest
      each day Hotel is not able to provide guest rooms to Event attendees.
    </li>
  </ul>
  <p>
    Group must approve the Alternate Hotel. Any guest rooms relocated to an Alternate Hotel as set forth above will be
    credited to Group’s Contracted Guest Room Revenue Commitment and complimentary guest room count, for such period as
    Hotel is unable to provide guest rooms to Event attendees. Relocated guest rooms will be subject to commission and
    rebate(s).
  </p>
  <p>
    If relocation is not possible, Hotel shall indemnify, defend and hold harmless Group and its affiliates against any
    and all claims that arise from the foregoing and immediately refund to Group any and all payment made for such
    room(s).
  </p>
  <p></p>
  <p>
    <b>Exclusive Right to Use Rooms.</b><br />
    Hotel acknowledges and agrees that Group shall have the exclusive right to sell, rent, or otherwise assign the
    Rooms. It is expressly agreed that where the Group resells Rooms, the rates that Group charges may vary from the
    Room Rates listed in this Agreement. Hotel agrees (i) to keep the rate charged to the Group for any given Room
    confidential between the Parties, and (ii) not to disclose the rate charged to the Group to any guests, whether
    directly or indirectly, under any circumstances. Notwithstanding anything to the contrary in this Agreement,
    substitution of any reservations (i.e., a change to the name/details included on the Rooming List) by the Group
    shall be allowed at any time up to and through the time of the Event.
  </p>
  <p>
    <b>Guest Responsibilities:</b> Group is not responsible for the conduct of the guests or physical damages to the
    Hotel property as a result of misuse. Any damages at fault of Group’s guests shall not be deemed a breach of this
    Agreement, provided, however, that Hotel may require the removal of any such guests from its property after
    consulting with Group. Individual guest(s) shall be responsible for their own conduct and physical damages to the
    hotel property as a result of misuse. Any loss or damage to guest belongings due to Hotel’s negligence or willful
    misconduct shall be the responsibility of the Hotel.
  </p>
  <p>
    <b>Audit.</b> Quint may request an audit at the conclusion of the Event between Hotel occupancy reports and reports
    furnished by Quint. Reports shall include Event guest and company names (if applicable.) Quint shall supply such
    reports within thirty (30) days after Event checkout. If it is reasonably determined that an Event guest, or
    company, is on the Hotel in house guest list, Hotel agrees to credit the occupied room nights towards final guest
    room usage calculations of earned complimentary room nights, commissions, rebates, concessions and attrition
    obligations (if applicable). Hotel will not be responsible to pay two commissions. If a Hotel inhouse guest's rate
    is commissionable (to another agency), then Hotel is not responsible to pay Quint a commission. To protect the
    privacy of all Event participants, Quint and Hotel agree not to disclose any personal information obtained to any
    third parties or for any other purposes other than determining the accurate guest usage and identifying guests, and
    companies, booking outside the Event block.
  </p>
  <p>
    <b>Date Change.</b> Should the Event be canceled or removed from the calendar year, the group may cancel the booking
    without penalty and will receive a full refund on any deposits paid within 5 business days of the cancellation
    notice. Additionally, should Event dates change, Hotel agrees that the group may revise key provisions of this
    agreement to reflect new market conditions, including but not limited to the following: (a) dates, (b) cut­off date,
    and (c) block size. The group agrees to notify the Hotel of their intentions to revise the agreement within 10
    business days after any announcement affecting this agreement.
  </p>
  <p>
    <b>Deterioration in Quality.</b> Should the Hotel suffer a substantial deterioration in the quality of its
    facilities or services to be provided by the Hotel under this agreement, Client/Group shall notify the Hotel’s
    General Manager of its concerns that in Client/Group’s reasonable judgment, such deterioration in the quality of the
    Hotel’s facilities or service may tend to materially and adversely affect the use of facilities or the quality of
    service to be provided under this Agreement during the event dates and 3 days before and 3 days after. Should the
    General Manager be unable or unwilling to correct the deficiencies of the facilities or services to Client/Group’s
    reasonable satisfaction, Client/Group shall have the right to cancel this agreement without liability by written
    notice to the Hotel.
  </p>
  <p>
    <b>Renovation.</b> In the event the Hotel may be undergoing construction or renovation that would affect the
    Client's room block, and/or Hotel function space, services or outlets, the Hotel shall promptly notify the Client,
    and the Client shall have the right to cancel this Agreement, without liability, upon written notice to the Hotel
    if, in the Client's reasonable judgment, such construction or renovation is likely to tend to unreasonably affect
    the use of the facilities or the quality of service to be provided under this Agreement.
  </p>
  <p>
    <b>Dispute Resolution.</b> This Agreement will be subject to the laws of the United States, the State of North
    Carolina, Mecklenburg County with respect to the validity, interpretation, or performance, or of any rights or
    obligations of the parties, or of any litigation arising out of the breach or enforcement of this Agreement. In the
    event of any court proceeding, the losing party agrees to pay any and all reasonable attorney's fees, the judgment,
    and interest equal to 1% every two weeks starting the 31st day from guest checkout.
  </p>
  <p>
    <b>Indemnification.</b> Each party agrees to indemnify and hold the other party harmless from and against any and
    all costs, losses or expenses, including reasonable attorneys' fees, that the other party may incur by reason of:
    (a) the indemnifying party's negligence or intentional misconduct; (b) any claim(s) or law suit(s) brought by a
    party that is not a party to this agreement arising out of, or in connection with, (i) the indemnifying party's
    performance or failure to perform pursuant to this agreement or (ii) any service or product sold or provided by the
    indemnifying party in connection with the event; (c) any claim relating to attrition of reserved rooms; (d) any
    claim relating to the performance of the Courtesy Contract between the parties; and (d) any cancellation of the
    reservation for any reason. Further, the Parties understand and agree that the agreement between them is a Courtesy
    Contract. No addition or modification to this section or any part of this Agreement, which may be controlled by this
    section, can be made unless such change is in a separate document, in writing, and is signed by both Parties.
  </p>
  <p>
    <b>Force Majeure:</b> Any delay or failure of any Party hereto to perform its obligations under this Agreement shall
    not subject such party to any liability to the other Parties or place it in breach of any term or condition of this
    Agreement if the Party’s delay or failure to act is the result of a Force Majeure. Force Majeure means any act
    beyond a Party’s control, including without limitation, the following: (i) such delay or failure is caused by acts
    of God, embargoes, governmental restrictions or regulations, strikes (excluding Hotel staff strike) or other
    concerted acts of workers, fire, flood, hurricane, drought, explosion, riots, wars, terrorism, epidemics, pandemics,
    public health emergencies, disaster, civil disorder, rebellion, sabotage, curtailment of transportation service or
    public utilities, or other similar events that are beyond the reasonable control of the affected party; and (ii)
    such event or occurrence: (a) in the case of the Hotel, prevents the performance of the Hotel’s obligations under
    this Agreement; and (b) in the case of Customer, prevents or materially adversely affects the performance of, either
    Customer’s obligations under this Agreement or makes the performance of such obligations including the Event
    illegal, impossible, or inadvisable, as determined by Customer in its sole and absolute discretion; provided,
    however, that in each case the non performing party shall promptly notify the other Parties of such event or
    occurrence and shall exert commercially reasonable efforts to overcome any such event or occurrence, if possible,
    and to resume performance of its obligation with all possible speed. If such Force Majeure event continues in
    respect of a substantial part of the Rights for a period of more than 1 month then any Party may be entitled to
    terminate this Agreement. Any prepaid deposits shall be refunded within 15 days of terminating this Agreement.
  </p>
  <p>
    All rates, information, terms and conditions in this agreement have been entered and verified by <span style="color: #0000f5; font-weight: bold">{{hotel_person_name}}</span> on
    <span style="color: #0000f5; font-weight: bold">{{hotel_date}}</span> and therefore becomes a binding contract. Upon electronic signature by both parties, Quint and the Hotel shall
    have agreed to and executed this Agreement by their authorized representatives as of the dates indicated below.
  </p>
  <p style="text-align: left;">
    QUINT<br />
    9335 Harris Corners<br />
    Parkway, Suite #500<br />
    Charlotte, NC 28269<br />
  </p>
</div>
`;
