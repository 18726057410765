<template>
  <div class="flex flex-col gap-4 p-4 text-white justify-start" v-if="!isLoading">
    <div class="text-xl">
      {{ !isNewTemplate && template ? `Editing ${template.name} template` : 'Creating new template' }}
    </div>
    <Input v-model="newTemplateName" :placeholder="'Enter template name'" :title="'Template name'" />
    <Hints :insert-text="insertText" />
    <Editor
      ref="editor"
      api-key="d074tealriws5uqr0ziqlwdqanrwu935w4mbmy8c7z05e78j"
      :init="isNewTemplate ? null : init"
    />
    <div class="flex gap-4 items-center justify-end">
      <Button class="w-fit" @click="() => loadDefaultTemplate(defaultCrewfareContract)">Load Crewfare Default</Button>
      <Button class="w-fit" @click="() => loadDefaultTemplate(defaultQuintContract)">Load Quint Default</Button>
      <Button class="w-fit" @click="save">Save</Button>
    </div>
  </div>
  <Loading class="self-center m-4" v-else />
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { templatesApi } from '@/utils/apis/templatesApi';
import Input from '@/components/ui/atoms/Input.vue';
import Button from '@/components/ui/atoms/Button.vue';
import Loading from '@/components/ui/atoms/Loading.vue';
import { DEFAULT_CREWFARE_CONTRACT, DEFAULT_QUINT_CONTRACT } from '@/constants/contract.constant';
import Hints from '../Hints.vue';

export default {
  components: {
    Editor,
    Input,
    Button,
    Loading,
    Hints,
  },
  props: {
    templateId: String,
    isNewTemplate: Boolean,
  },
  data() {
    return {
      init: null,
      template: null,
      newTemplateName: '',
      isLoading: true,
    };
  },
  methods: {
    async setInit() {
      this.template = await templatesApi.get(this.templateId).then(res => res.data);
      this.init = {
        setup: editor => {
          editor.on('init', async () => {
            editor.setContent(this.template.content);
          });
        },
      };
      this.newTemplateName = this.template.name;
      this.isLoading = false;
    },
    async save() {
      this.isLoading = true;
      if (!this.template) {
        await this.addTemplate();
      } else {
        await templatesApi.update(this.templateId, {
          ...this.template,
          name: this.newTemplateName,
          content: this.$refs.editor.getEditor().getContent(),
        });
      }
      this.$router.push({ name: 'settings' });
    },
    async addTemplate() {
      await templatesApi.create({
        name: this.newTemplateName,
        content: this.$refs.editor.getEditor().getContent(),
      });
    },
    loadDefaultTemplate(content = null) {
      this.$refs.editor.getEditor().setContent(content);
    },
    insertText(text) {
      const selection = this.$refs.editor.getEditor().selection;
      this.$refs.editor.getEditor().insertContent(text, selection.getStart());
    },
  },
  mounted() {
    !this.isNewTemplate ? this.setInit() : (this.isLoading = false);
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    defaultCrewfareContract() {
      return DEFAULT_CREWFARE_CONTRACT;
    },
    defaultQuintContract() {
      return DEFAULT_QUINT_CONTRACT;
    },
  },
};
</script>
