<template>
  <tr v-if="rfp" class="text-white">
    <td class="w-[32rem] p-4 text-sm flex gap-2 items-start">
      <button v-if="isSelectable" @click="toggleSelected">
        <span v-if="!isSelected" class="material-symbols-rounded">check_box_outline_blank</span>
        <span v-else class="material-symbols-rounded text-crewfareGreen">check_box</span>
      </button>
      <div class="flex flex-col w-full gap-2">
        <span class="text-lg flex gap-2 items-center">
          <button
            v-if="isPartner"
            class="material-symbols-rounded text-crewfareGreen text-[16px] material-symbols-filled"
          >
            star
          </button>
          {{ rfp.hotel_name }}
        </span>
        <div v-if="loading">
          <Spinner />
        </div>
        <div v-else class="flex w-full gap-3 items-center">
          <router-link
            :to="{
              name: 'rfpsHotelContact',
              params: { id: $route.params.id, hotel_id: rfp.hotel_id },
            }"
            class="text-white hover:text-crewfareGreen"
          >
            <span class="flex justify-items-center">
              <span class="material-symbols-rounded text-sm">{{this.contacts?.length ? "edit": "add"}}</span>
              <span v-if="!contacts?.length">Add Contact</span>
            </span>  
          </router-link>
          <div v-if="contacts?.length" class="w-full flex flex-col divide-y">
            <span
              v-for="contact in contacts"
              :key="contact.id"
              class="text-xs flex justify-between w-full items-center py-2"
            >
              <span class="flex-grow"> {{ contact.name }} &lt;{{ contact.email }}&gt; </span>
              <span
                v-if="showLink"
                @click="copyRFPLink(contact.account_id)"
                class="text-sky-400 flex items-center hover:text-sky-100 gap-4 cursor-pointer"
              >
                Copy RFP link
              </span>
              <span
                v-if="showContractLink"
                @click="copyContractLink(contact.account_id)"
                class="text-sky-400 flex items-center hover:text-sky-100 gap-4 cursor-pointer"
              >
                Copy Contract link
              </span>
            </span>
          </div>
        </div>
        <span v-if="showCutoffDate" class="text-xs">
          Cut off date:
          <span class="text-yellow-200">{{ cutoffDateFormated }}</span>
        </span>
        <div class="flex flex-row gap-2 items-center">
          <span v-if="!toggleStatus" v-html="statusPill"></span>
          <DefineStatus v-if="!rfp.status || rfp.status === '' || toggleStatus" :rfp="rfp" @getData="getData" />
          <button class="material-symbols-rounded text-sm" @click="toggleStatus = !toggleStatus">
            {{ toggleStatus ? 'close' : 'edit' }}
          </button>
        </div>
        <span v-if="contractAvailable">
          <span class="border mt-4 animate-pulse border-crewfareGreen px-4 rounded-full py-1 text-crewfareGreen"
            >Contract Available</span
          >
        </span>
        <div v-if="hasMessage">
          <router-link
            :to="{
              name: 'messagesChat',
              params: { id: rfp.message_id },
            }"
            class="border mt-4 animate-pulse border-red-400 px-4 rounded-full py-1 text-red-400"
          >
            New Message
          </router-link>
        </div>
        <div
          v-if="rfp.reasonDecline && ['Declined by hotel', 'Declined by crewfare'].includes(rfp.status)"
          class="text-red-200 text-xs max-w-[500px]"
        >
          Reason: <span v-html="rfp.reasonDecline"></span>
        </div>
      </div>
    </td>
    <td class="text-sm px-4">
      <span class="flex gap-2 items-center"> <img src="/icons/pin.svg" />{{ distance }} </span>
    </td>
    <td class="text-sm px-4">
      <div class="flex gap-2 items-center">
        <button class="hover:text-crewfareGreen" @click="toggleRateDialog">
          <span class="material-symbols-rounded text-sm">edit</span>
        </button>
        <div class="flex flex-col gap-2">
          <div class="flex w-full items-center justify-between">Online Rate: {{ rate }}</div>
          <div>Proposal: {{ proposalFormated }}</div>
          <div>Delta: <span v-html="delta"></span></div>
        </div>
      </div>
      <RateDialog v-if="showRateDialog" :rfp="rfp" @close="toggleRateDialog" @saveRate="saveRate" />
    </td>
    <td v-if="showEmailStatus" class="text-sm px-4">
      <div v-if="!historyLoading" class="flex gap-2 items-center">
        <div class="flex flex-col gap-2">
          <div>
            <span>
              Total Emails sent:
              {{
                emailStatusCount.delivered +
                emailStatusCount.viewed +
                emailStatusCount.failedToDeliver +
                emailStatusCount.bounced +
                emailStatusCount.processed
              }}
            </span>
          </div>
          <div>
            <span v-if="emailStatusCount.processed"> Emails processed: {{ emailStatusCount.processed }} </span>
          </div>
          <div>
            <span v-if="emailStatusCount.delivered"> Emails in delivered state: {{ emailStatusCount.delivered }} </span>
          </div>
          <div>
            <span v-if="emailStatusCount.viewed"> Emails viewed: {{ emailStatusCount.viewed }} </span>
          </div>
          <div>
            <span v-if="emailStatusCount.failedToDeliver">
              Emails failed to deliver: {{ emailStatusCount.failedToDeliver }}
            </span>
          </div>
          <div>
            <span v-if="emailStatusCount.bounced"> Emails bounced: {{ emailStatusCount.bounced }} </span>
          </div>
        </div>
      </div>
      <div v-else>
        <span class="material-symbols-rounded animate-spin text-[32px]"> autorenew </span>
      </div>
    </td>
    <td class="text-sm px-4 capitalize relative">
      <div class="flex justify-between items-center">
        {{ formatedUpdatedAt }}
        <div class="relative">
          <dropdown-menu>
            <template #trigger>
              <button
                class="inline-flex relative items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                type="button"
              >
                <span v-if="loading" class="material-symbols-rounded animate-spin"> autorenew </span>
                <span v-else class="material-symbols-rounded">more_vert</span>
                <span
                  v-if="hasInternalMessage"
                  class="bg-red-400 rounded-full text-white px-1 absolute top-0 right-0 h-[12px] w-[12px] flex items-center justify-center"
                ></span>
              </button>
            </template>
            <template #body>
              <FwbListGroup class="absolute top-[40px] right-0 z-30 w-[240px] dark:bg-gray-700 bg-gray-700">
                <FwbListGroupItem
                  v-if="['Declined by hotel', 'Declined by crewfare'].includes(rfp.status)"
                  class="!px-0 !py-0"
                >
                  <button
                    @click="reopenRFP"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Reopen RFP
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="rfp.status !== 'New'" class="!px-0 !py-0">
                  <router-link
                    :to="{
                      name: 'rfpView',
                      params: { rfp: rfp.id },
                    }"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    View RFP
                  </router-link>
                </FwbListGroupItem>
                <FwbListGroupItem class="!px-0 !py-0">
                  <router-link
                    :to="{
                      name: 'rfpContract',
                      params: { rfp: rfp.id },
                    }"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Contract
                  </router-link>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="hideDecline" class="!px-0 !py-0">
                  <router-link
                    :to="{ name: 'rfpDeclined', params: { rfp: rfp.id } }"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Decline RFP
                  </router-link>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="showReminder" class="!px-0 !py-0">
                  <button
                    @click="reminderRFP"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Send Reminder
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="rfp.status === 'new'" class="!px-0 !py-0">
                  <button
                    @click="resendRFP"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Send
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="rfp.message_id" class="!px-0 !py-0">
                  <button
                    @click="sendMessage"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Messages
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem class="!px-0 !py-0 relative">
                  <ButtonNotesDialog
                    type="Notes on RFP"
                    :subtitle="rfp.name"
                    :title="`Notes at ${rfp.name} for ${rfp.hotel_name}`"
                    :id="`${rfp.id}-note-${rfp.hotel_id}`"
                    text="Notes"
                  />
                </FwbListGroupItem>
                <FwbListGroupItem class="!px-0 !py-0">
                  <button
                    @click="toggleStatusHistoryDialog"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Status History
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem v-if="rfp.roomListHistoric && rfp.roomListHistoric.length > 1" class="!px-0 !py-0">
                  <button
                    @click="toggleHistoryDialog"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    History rates
                  </button>
                </FwbListGroupItem>
                <FwbListGroupItem class="!px-0 !py-0">
                  <router-link
                    :to="{
                      name: 'rfpView',
                      params: { rfp: rfp.id },
                      query: { admin: true },
                    }"
                    class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Edit RFP Admin
                  </router-link>
                </FwbListGroupItem>
                <FwbListGroupItem class="!px-0 !py-0">
                  <button
                    @click="removeHotel"
                    class="text-red-400 hover:text-red-100 flex items-center gap-2 px-4 py-2 w-full"
                  >
                    Remove hotel
                  </button>
                </FwbListGroupItem>
              </FwbListGroup>
            </template>
          </dropdown-menu>
        </div>
      </div>
    </td>
    <HistoryRateDialog v-if="showHistoryDialog" :roomListHistoric="rfp.roomListHistoric" @close="toggleHistoryDialog" />
    <StatusHistoryDialog
      v-if="showStatusHistoryDialog"
      :rfp="rfp.id"
      @hideStatusHistoryDialog="showStatusHistoryDialog = false"
      :status_history="rfp.status_history"
    />
  </tr>
</template>

<script>
import { sendRFPMessage } from "@/utils/messages.jsx";
import { firestore } from "@/utils/firebase";
import {
  where,
  getDocs,
  getDoc,
  doc,
  query,
  deleteDoc,
  collection,
  setDoc,
} from "firebase/firestore";
import moment from "moment";
import ListItemHotelSkeleton from "./ListItemHotelSkeleton.vue";
import { RFPStatusPill, reopenRFP, setStatusHistory, listEmailHistoryForRFP } from "@/utils/rfp.jsx";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";
import HistoryRateDialog from "@/components/default/HistoryRateDialog.vue";
import RateDialog from "@/components/rfps/hotels/RateDialog.vue";
import StatusHistoryDialog from "./StatusHistoryDialog.vue";
import DefineStatus from "../DefineStatus.vue";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import Spinner from "@/components/ui/atoms/Spinner.vue";

export default {
  props: ['rfp', 'selected', 'isSelectable', 'hasReminder', 'showEmailStatus', 'emailHistoriesForRFPIds','historyLoading'],
  components: {
    HistoryRateDialog,
    RateDialog,
    StatusHistoryDialog,
    ListItemHotelSkeleton,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
    DefineStatus,
    ButtonNotesDialog,
    Spinner
  },
  data() {
    return {
      loading: false,
      hotel: null,
      showMenu: false,
      contacts: null,
      hasMessage: false,
      isPartner: false,
      showRateDialog: false,
      showNoteDialog: false,
      showHistoryDialog: false,
      hasInternalMessage: false,
      showStatusHistoryDialog: false,
      internalMessage: null,
      toggleStatus: false,
      emailsProcessed: 0,
      emailLoading: false,
    };
  },
  computed: {
    emailHistory(){
      return this.emailHistoriesForRFPIds[this.rfp.id] || {};
    },
    emailStatusCount() {
      return this.countByStatus(this.emailHistory);
    },
    cutoffDateFormated() {
      if (!this.rfp.cutoffDate) return '';
      const date = this.rfp.cutoffDate;
      return getMomentFromFirebaseDate(date).format('MM-DD-YYYY');
    },
    showReminder() {
      return [
        'bid viewed',
        'bid opened',
        'progress saved',
        'sent',
        'ropened by crewfare',
        'ropened by hotel',
        'crewfare counter',
      ].includes(this.rfp.status.toLowerCase());
    },
    hideDecline() {
      return [
        'update bid terms',
        'declined by hotel',
        'declined by crewfare',
        'accepted by hotel',
        'accepted by crewfare',
        'signed by the hotel',
        'signed by both sides',
        'accepted by crewfare',
      ].includes(this.rfp.status.toLowerCase());
    },
    showCutoffDate() {
      return [
        'accepted by hotel',
        'accepted by crewfare',
        'signed by the hotel',
        'signed by both sides',
        'accepted by crewfare',
      ].includes(this.rfp.status.toLowerCase());
    },
    showContractLink() {
      return ['accepted by hotel', 'accepted by crewfare', 'signed by the hotel', 'accepted by crewfare'].includes(
        this.rfp.status.toLowerCase(),
      );
    },
    showLink() {
      return ![
        'new',
        'accepted by hotel',
        'accepted by crewfare',
        'signed by the hotel',
        'signed by both sides',
        'accepted by crewfare',
      ].includes(this.rfp.status.toLowerCase());
    },
    isSelected() {
      return this.selected.find(hotel => hotel.id === this.rfp.id);
    },
    distance() {
      if (this.rfp.distance) return `${this.rfp.distance.toFixed(2)} mi`;
      return '-';
    },
    contractAvailable() {
      return this.rfp.accepted?.length === 2 && this.rfp.signed?.length !== 2;
    },
    statusPill() {
      return RFPStatusPill(this.rfp.status);
    },
    account() {
      return this.$store.state.account;
    },
    rate() {
      const rate = parseInt(this.rfp.value || 0);
      if (rate) return `$ ${rate.toFixed(2)}`;
      return '-';
    },
    formatedUpdatedAt() {
      if (!this.rfp.updated_at) return '';
      const updated_at = getMomentFromFirebaseDate(this.rfp.updated_at);
      return updated_at.fromNow();
    },
    proposal() {
      let values = []
      this.rfp.roomList?.forEach(days => {
        days.rooms.forEach(item => {
          const itemValue = parseFloat(item.value);
          if (itemValue && itemValue > 0) {
            values.push(itemValue)
          }
        });
      });
      return values.sort((a, b) => a - b)[0];
    },
    proposalFormated() {
      if (this.proposal > 0)
        return `$ ${this.proposal.toFixed(2)}`;
      return '-';
    },
    delta() {
      const value = this.proposal;
      const rate = this.rfp.value;
      if (!value || !rate) return '-';
      if (isNaN(parseFloat(rate))) return;
      const delta = Math.floor(((rate - value) / rate) * 100);
      let color = '';
      if (delta < 10) {
        color = 'color: rgb(248 113 113);';
      } else if (delta < 20) {
        color = 'color: rgb(250 204 21);';
      } else {
        color = 'color: rgb(74 222 128);';
      }
      return `<span style='${color}'>${delta}%</span>`;
    }
  },
  async mounted() {
    this.loading = true;
    const hotelRef = doc(firestore, 'hotels', this.rfp.hotel_id);
    this.hotel = await getDoc(hotelRef);
    this.isPartner = this.hotel.data().isPartner || false;

    const filteredContacts = [];
    const filteredEmails = [];
    for (const index in this.hotel.data().contacts) {
      const contact = this.hotel.data().contacts[index];
      if (!filteredEmails.includes(contact.email)) {
        const qAccounts = query(collection(firestore, 'accounts'), where('email', '==', contact.email));
        const accountDocs = await getDocs(qAccounts);
        if(accountDocs.empty) {
          continue;
        }
        contact.account_id = accountDocs.docs[0].id;
        filteredEmails.push(contact.email);
        filteredContacts.push(contact);
      }
    }
    this.contacts = filteredContacts;
    this.rfp.contact_emails = this.contacts.map(contact => contact.email).join(',');
    const qMessages = query(collection(firestore, 'messages'), where('proposal_id', '==', this.rfp.id));
    const message = await getDocs(qMessages);
    if (!message.empty) this.hasMessage = message.docs.reverse()[0].data().new_message;
    this.loading = false;
  },
  methods: {
    countByStatus(emailHistory) {
      let count = {
        processed: this.emailsProcessed,
        delivered: 0,
        viewed: 0,
        failedToDeliver: 0,
        bounced: 0,
      };
      if (!Object.keys(emailHistory).length) {
        return count;
      }
      ['rfp_invite', 'rfp_reminder'].forEach(type => {
        if (!emailHistory[type]) {
          return;
        }
        Object.keys(emailHistory[type]).forEach(status => {
          if (status === 'delivered') {
            count.delivered += emailHistory[type][status].length;
          } else if (status === 'open') {
            count.viewed += emailHistory[type][status].length;
          } else if (status === 'bounce') {
            count.bounced += emailHistory[type][status].length;
          } else if (status === 'processed') {
            count.processed += emailHistory[type][status].length;
          } else {
            count.failedToDeliver += emailHistory[type][status].length;
          }
        });
      });
      return count;
    },
    getData() {
      this.$emit('getData');
    },
    async reopenRFP() {
      this.loading = true;
      const rfpRef = doc(firestore, 'rfp_hotel', this.rfp.id);
      const rfpData = await getDoc(rfpRef);
      await reopenRFP(rfpData, this.account);
      this.$emit('getData');
      this.loading = false;
    },
    async copyContractLink(account_id) {
      navigator.clipboard.writeText(`${import.meta.env.VITE_URL}/rfp/${this.rfp.id}/contract?accountId=${account_id}`);
      this.showMenu = false;
      this.$store.commit('setToast', {
        content: `<p>Link copied!</p>`,
      });
    },
    async copyRFPLink(account_id) {
      navigator.clipboard.writeText(`${import.meta.env.VITE_URL}/rfp/${this.rfp.id}/view?accountId=${account_id}`);
      this.showMenu = false;
      this.$store.commit('setToast', {
        content: `<p>Link copied!</p>`,
      });
    },
    async saveRate(rate) {
      const rfpRef = doc(firestore, 'rfp_hotel', this.rfp.id);
      const rfpData = await getDoc(rfpRef);
      let data = rfpData.data();
      data = await setStatusHistory({
        data,
        content: 'Rate updated',
        status: data.status,
      });
      await setDoc(doc(firestore, 'rfp_hotel', this.rfp.id), {
        ...data,
        value: parseInt(rate),
        updated_at: new Date(),
      });
      this.$emit('getData');
      this.showRateDialog = false;
    },
    toggleRateDialog() {
      this.showRateDialog = !this.showRateDialog;
    },
    toggleStatusHistoryDialog() {
      this.showStatusHistoryDialog = !this.showStatusHistoryDialog;
    },
    toggleHistoryDialog() {
      this.showHistoryDialog = !this.showHistoryDialog;
    },
    toggleSelected() {
      this.$emit('toggleSelected', this.rfp);
    },
    resendRFP() {
      this.loading = true;
      this.$emit('resendRFP', [this.rfp]);
      this.emailsProcessed += 1;
      this.loading = false;
    },
    reminderRFP() {
      this.showMenu = false;
      this.$emit('reminderRFP', {
        rfp: [this.rfp],
        status: this.rfp.status.toLowerCase(),
      });
      this.emailsProcessed += 1;
    },
    async sendMessage() {
      sendRFPMessage(this.rfp);
    },
    async removeHotel() {
      if (!confirm('Remove hotel from RFP?')) return;
      this.showMenu = false;
      const hotel_id = this.rfp.hotel_id;
      const rfp_id = this.$route.params.id;
      const rfpRef = doc(firestore, 'rfps', rfp_id);
      const rfpData = await getDoc(rfpRef);
      const hotels = rfpData.data().hotels || [];
      this.showMenu = false;
      if (hotels.length > 0) hotels.splice(hotels.indexOf(hotel_id), 1);
      await setDoc(doc(firestore, 'rfps', rfp_id), {
        ...rfpData.data(),
        hotels,
        updated_at: new Date(),
      });
      this.$store.commit('setToast', {
        content: `<p>Hotel removed from RFP</p>`,
      });
      deleteDoc(doc(firestore, 'rfp_hotel', this.rfp.id));
      this.$emit('getData');
    },
  },
};
</script>
