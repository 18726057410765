<template>
  <div v-if="account" class="relative bg-[#343842] py-4 px-4 z-[60]">
    <div class="flex justify-end gap-4">
      <Dropdown v-if="isCrewfare" @change="handleGroupChange" :selected="selectedGroupId" :options="groupOptions" />
      <Notifications />
      <Account />
    </div>
  </div>
</template>

<script>
import Notifications from "@/components/default/header/Notifications.vue";
import Account from "@/components/default/header/Account.vue";
import Dropdown from "../ui/atoms/Dropdown.vue";
import { userGroupsApi } from "@/utils/apis/userGroupsApi";
import { isCrewfareAccount } from "@/utils/account";

export default {
  components: {
    Dropdown,
    Notifications,
    Account,
  },
  computed: {
    isCrewfare(){
      return isCrewfareAccount(this.account);
    },
    selectedGroupId(){
      return this.$store.state.groups.selectedId || '';
    },
    account() {
      return this.$store.state.account;
    },
    groups() {
      return this.$store.state.groups.data;
    },
    groupOptions() {
      return (this.groups || []).map((group) => ({
        text: group.name,
        value: group.id,
      }));
    },
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    handleGroupChange(value) {
      this.$store.commit("setGroupId", value);
    },
  },
};
</script>
