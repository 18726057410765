<template>
  <div v-if="isMounting" class="flex justify-center min-h-[100vh]">
    <Loading size="text-[30px]"/>
  </div>
  <div v-else class="bg-gray-800 w-full flex flex-col stage-height text-white">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 h-[42px] items-center">
        <router-link
          :to="{ name: 'usersList' }"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <h1 class="text-xl">Users - {{ title }}</h1>
      </div>
    </div>
    <form
      @submit.prevent="save"
      class="flex flex-col gap-4 text-white w-full p-4"
    >
      <div class="flex flex-col gap-1 w-full">
        <label class="text-white font-bold">Name</label>
        <input
          type="text"
          v-model="name"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Email</label>
        <input
          type="text"
          v-model="email"
          :disabled="isEdit"
          class="w-full bg-gray-700 disabled:bg-gray-800 disabled:text-gray-400 rounded px-4"
        />
        <p v-if="isEdit" class="text-red-400 opacity-90 text-xs">
          Emails are not editable
        </p>
      </div>
      <div class="flex gap-4">
        <span
          v-if="this.$route.params.id"
          class="blue-alt-button"
          :class="type === 'contact' && 'blue-alt-button-active'"
          @click="toggleContact()"
        >
          Is Contact
        </span>
        <span
          class="green-alt-button"
          :class="type === 'group manager' && 'green-alt-button-active'"
          @click="toggleGroupManager()"
        >
          Group Manager
        </span>
        <span
          class="green-alt-button"
          :class="type === 'nso' && 'green-alt-button-active'"
          @click="toggleNSO()"
        >
          NSO
        </span>
        <span
          class="green-alt-button"
          :class="type === 'lead manager' && 'green-alt-button-active'"
          @click="toggleLeadManager()"
        >
          Lead Manager
        </span>
        <span
          class="yellow-button"
          :class="type === 'internal' && 'yellow-button-active'"
          @click="toggleInternal()"
        >
          Internal
        </span>
        <span
          class="green-button"
          :class="type === 'admin' && 'green-button-active'"
          @click="toggleAdmin()"
        >
          Admin
        </span>
        <span
          v-if="this.accountLogged.type === types.Super_Admin"
          class="green-button"
          :class="type === types.Super_Admin && 'green-button-active'"
          @click="this.type = types.Super_Admin"
        >
          Super Admin
        </span>
      </div>
      <div v-if="!owner && $route.params.id">
        <p class="my-4 text-white text-center">
          To activate this account share this link with the account owner<br />
          {{ baseUrl }}/invite/{{ $route.params.id }}
        </p>
      </div>
      <div v-if="error">
        <p class="my-4 text-red-400 text-center">
          {{ error }}
        </p>
      </div>
      <div class="flex gap-4 mt-4 justify-between">
        <router-link :to="{ name: 'usersList' }" class="cancel-button">
          Cancel
        </router-link>
        <button :disabled="!valid || loading" class="green-button">
          <span
            v-if="loading"
            class="material-symbols-rounded animate-spin flex items-center"
          >
            autorenew
          </span>
          <span v-else> Save </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import router from "@/router";
import { sendNotificationAdmin } from "@/utils/notifications.jsx";
import { sendEmail, processVariables } from "@/utils/emails.jsx";
import { inviteEmailContent, inviteSubject }  from "@/emails/invite";
import { accountsApi } from "@/utils/apis/accountsApi";
import Loading from "@/components/default/Loading.vue";
import { AccountTypes } from "@/enums/AccountTypes";
import { NotificationTypes } from "@/enums/NotificationTypes";
import { ViewsLocations } from "@crewfare/server-shared";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      error: null,
      name: "",
      email: "",
      type: "",
      permissions: [],
      isMounting: false,
      owner: null,
      account: null,
      loading: false,
      types: AccountTypes,
      availablePermissions: [
        "Search",
        "RFPs",
        "Own RFPs",
        "Hotels",
        "Users",
        "Messages",
        "Files",
      ],
    };
  },
  computed: {
    title() {
      return this.$route.params.id ? "Edit" : "New";
    },
    accountLogged() {
      return this.$store.state.account;
    },
    valid() {
      return this.name.length > 0 && this.email.length > 0;
    },
    isEdit() {
      return this.$route.params.id;
    },
    baseUrl() {
      return import.meta.env.VITE_URL;
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.isMounting = true;
      const account = await accountsApi.get(this.$route.params.id);
      if (account.error) {
        alert(account.message);
        router.push({ name: "usersList" });
        return;
      }
      this.account = account.data;
      console.log(this.account);
      this.name = this.account.name;
      this.email = this.account.email;
      this.type = this.account.type.toLowerCase();
      this.permissions = this.account.permissions;
      this.owner = this.account.owner;
      this.isMounting = false;
    }
    document.title = `Launchpad - Crewfare - Users - ${this.title}`;
  },
  methods: {
    toggleNSO() {
      this.type = "nso";
      this.permissions = [];
    },
    toggleLeadManager() {
      this.type = "lead manager";
      this.permissions = [];
    },
    toggleGroupManager() {
      this.type = "group manager";
      this.permissions = [];
    },
    toggleAdmin() {
      this.type = "admin";
      this.permissions = [
        "Search",
        "RFPs",
        "Hotels",
        "Users",
        "Messages",
        "Files",
      ];
    },
    toggleInternal() {
      this.type = "internal";
      this.permissions = ["Search", "RFPs", "Hotels"];
    },
    toggleContact() {
      this.type = "contact";
      this.permissions = [];
    },
    async save() {
      this.error = null;
      if (!this.valid) return;
      this.loading = true;
      const accountData = {
          name: this.name,
          email: this.email,
          type: this.type,
      }
      if(this.$route.params.id) {
        accountData.id = this.$route.params.id;
      }
      const accountResponse = await accountsApi.create(accountData);
      console.log(accountResponse);
      if(accountResponse.error){
        alert(accountResponse.message);
        this.loading = false;
        return true;
      }
      const account = accountResponse.data;

      if (this.$route.params.id) {
        sendNotificationAdmin({
          type: NotificationTypes.USERS,
          content: `${this.name} account just has been updated by ${
            this.accountLogged.name
          }`,
          to: { name: ViewsLocations.EDIT_USER, params: { id: this.$route.params.id } },
        });
        this.$store.commit("setToast", {
          content: `<p>User updated</p>`,
        });
      } else {
        sendNotificationAdmin({
          type: NotificationTypes.USERS,
          content: `A new user account for ${this.name} has been created by ${
            this.accountLogged.name
          }`,
          to: { name: ViewsLocations.EDIT_USER, params: { id: account.id } },
        });
        if (this.type !== "contact") {
          const link = `${import.meta.env.VITE_URL}/invite/${
              account.id
            }`;
          sendEmail({
            to: this.email,
            template: "emails.new-default",
            subject: processVariables(inviteSubject),
            content: processVariables(inviteEmailContent, {
              link,
            }),
          });
        }
        this.$store.commit("setToast", {
          content: `<p>User added</p>`,
        });
      }
      router.push({ name: "usersList" });
      this.loading = false;
    },
  },
};
</script>
