<template>
  <div
    v-if="hotel"
    class="bg-gray-800 w-full flex flex-col stage-height text-white"
  >
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 h-[42px] items-center">
        <router-link
          :to="{
            name: 'groupHotelsContactsList',
            params: { id: $route.params.id },
          }"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <h1 class="text-xl">{{ hotelName }} - Contacts - {{ title }}</h1>
      </div>
    </div>
    <form
      @submit.prevent="save"
      class="flex flex-col gap-4 p-4 text-white w-full"
    >
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Name</label>
        <input
          type="text"
          v-model="name"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Role</label>
        <input
          type="text"
          v-model="role"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Email</label>
        <input
          type="email"
          v-model="email"
          :disabled="isEdit"
          class="w-full bg-gray-700 rounded px-4"
        />
        <p v-if="isEdit" class="text-red-400 opacity-90 text-xs">
          Emails are not editable - if you need to edit an email, create a new
          contact at this hotel and delete the contact is not active anymore.
        </p>
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Phone</label>
        <input
          type="text"
          v-model="phone"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex gap-4 mt-4 justify-between">
        <router-link
          :to="{
            name: 'groupHotelsContactsList',
            params: { id: $route.params.id },
          }"
          class="cancel-button"
        >
          Cancel
        </router-link>
        <button :disabled="!valid || loading" class="green-button">
          <span
            v-if="loading"
            class="material-symbols-rounded animate-spin flex items-center"
          >
            autorenew
          </span>
          <span v-else> Save </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import router from "@/router";
import { firestore } from "@/utils/firebase";
import { doc, getDoc } from "firebase/firestore";
import { save } from "@/utils/contact.jsx";

export default {
  data() {
    return {
      hotelName: "",
      hotel: null,
      title: this.$route.params.contactId ? "Edit" : "New",
      name: "",
      role: "",
      email: "",
      phone: "",
      contact: {},
      loading: false,
    };
  },
  computed: {
    valid() {
      return this.name.length > 0 && this.email.length > 0;
    },
    account() {
      return this.$store.state.account;
    },
    isEdit() {
      return this.$route.params.contactId;
    },
  },
  async mounted() {
    const hotelRef = doc(firestore, "hotels", this.$route.params.id);
    this.hotel = await getDoc(hotelRef);
    this.hotelName = this.hotel.data().name;
    document.title = `Launchpad - Crewfare - Contacts - ${this.hotelName} - ${this.title}`;

    if (this.$route.params.contactId) {
      const contactRef = doc(
        firestore,
        "contacts",
        this.$route.params.contactId
      );
      this.contact = await getDoc(contactRef);
      this.name = this.contact.data().name;
      this.role = this.contact.data().role;
      this.email = this.contact.data().email;
      this.phone = this.contact.data().phone;
    }
  },
  methods: {
    async save() {
      if (!this.valid) return;
      this.loading = true;
      await save({
        name: this.name,
        email: this.email,
        role: this.role,
        phone: this.phone,
        contact: this.contact,
        hotelId: this.hotel.id,
      });
      router.push({
        name: "groupHotelsContactsList",
        params: { id: this.$route.params.id },
      });
      this.loading = false;
    },
  },
};
</script>
