<template>
  <router-link
    v-if="message"
    :to="{ name: 'messagesChat', params: { id: message.id } }"
    active-class="bg-[#2D2F3A]"
    class="p-4 rounded-lg"
  >
    <div class="flex justify-between gap-2 items-center">
      <span class="flex flex-col gap-2">
        <span
          v-tippy="
            message?.hotel_name?.length > 26
              ? message.hotel_name
              : message?.title?.length > 26
              ? message.title
              : ''
          "
          class="text-ellipsis overflow-hidden h-[22px] text-nowrap w-[230px]"
          v-if="message.type === 'message'"
        >
          {{ message.title || message.hotel_name }}
        </span>
        <div v-else class="flex flex-col gap-2">
          <span
            v-tippy="hotel?.data()?.name?.length > 26 ? hotel?.data()?.name : ''"
            class="font-bold text-ellipsis overflow-hidden h-[22px] text-nowrap w-[230px]"
            v-if="hotel"
          >
            {{ hotel?.data()?.name }}
          </span>
          <span class="font-semibold">{{ message.title }}</span>
          <span class="text-sm">{{ message.subtitle }}</span>
        </div>
        <p v-if="rfp" class="text-sm text-gray-400">
          RFP: {{ rfp.data().name }}
        </p>
        <span class="text-xs">{{ formatedLastInteraction }}</span>
      </span>
      <div class="flex flex-col gap-2 items-end">
        <span
          v-if="
            message.new_message &&
            message.new_message_by_id !== account.id
          "
          class="rounded-full animate-pulse w-[10px] h-[10px] bg-green-200"
        ></span>
        <span
          v-else
          class="rounded-full w-[10px] h-[10px] border border-green-200"
        ></span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import moment from "moment";
import ListItemSkeleton from "./ListItemSkeleton.vue";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";

export default {
  components: {
    ListItemSkeleton,
  },
  props: ["message"],
  data() {
    return {
      rfp: null,
      hotel: null,
      subtitle: null,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    formatedCreated() {
      if (!this.message.created_at) return "";
      const created_at = getMomentFromFirebaseDate(this.message.created_at);
      return created_at.fromNow();
    },
    formatedLastInteraction() {
      if (!this.message.last_interaction) return "";
      const last_interaction = moment(
        getMomentFromFirebaseDate(this.message.last_interaction)
      );
      return last_interaction.fromNow();
    },
  },
  async mounted() {
    if (this.message.hotel_id) {
      const hotelRef = doc(firestore, "hotels", this.message.hotel_id);

      this.hotel = await getDoc(hotelRef);
    }
    if (this.message.rfp_id) {
      const rfpHotelRef = doc(
        firestore,
        "rfp_hotel",
        this.message.rfp_id
      );
      this.rfp = await getDoc(rfpHotelRef);
    }
  },
  methods: {
    async removeMessage() {
      if (!confirm("Delete this Messages?")) {
        return;
      }
      deleteDoc(doc(firestore, "messages", this.message.id));
      this.$emit("getData");
    },
  },
};
</script>
