import { firestore } from '@/utils/firebase';
import { query, getDocs, collection, writeBatch, where, addDoc } from 'firebase/firestore';
import store from '@/store';
import { getHotelsIdsByGroup, getHotelsIdByChain } from './hotel.jsx';
import { notificationsApi } from './apis/notificationsApi';

const sendNotificationAdmin = async ({ content, type, to }) => {
  const notificationPayload = {
    content,
    to,
    type,
  };
  await notificationsApi.notifySource(notificationPayload);
};

const sendNotificationHotels = async ({ content, type = null, to = null, ids }) => {
  ids.map(id => {
    let notificationPayload = {
      type,
      content,
      to,
      hotelId: id,
    };
    return notificationsApi.notifyHotel(notificationPayload);
  });
};

const markNotificationsAsRead = async () => {
  await updateUserNotifications();
};

const updateGroupNotifications = async () => {
  const hotel_ids = await getHotelsIdsByGroup();
  const notifications = await getNotifications([where('hotel_id', 'in', hotel_ids.splice(0, 30))]);
  await updateNotificationsAsRead(notifications);
};

const updateUserNotifications = async () => {
  const account = store.state.account;
  const notifications = await getNotifications([where('account_id', '==', account.id)]);
  await updateNotificationsAsRead(notifications);
};

const updateNSONotifications = async () => {
  const hotel_ids = await getHotelsIdByChain();
  const hotels_filtered = hotel_ids.splice(0, 30);
  const notifications = await getNotifications([where('hotel_id', 'in', hotels_filtered)]);
  await updateNotificationsAsRead(notifications);
};

const updateHotelNotifications = async () => {
  const account = store.state.account;
  const notifications = await getNotifications([where('hotel_id', 'in', account.hotelIds)]);
  await updateNotificationsAsRead(notifications);
};

const updateNotificationsAsRead = async notifications => {
  const batch = writeBatch(firestore);
  for (const notification of notifications.docs) {
    batch.update(notification.ref, { unread: false, updated_at: new Date() });
  }
  await batch.commit();
};

const getNotifications = async params => {
  let qNotifications = query(collection(firestore, 'notifications'), where('unread', '==', true), ...params);
  return await getDocs(qNotifications);
};

export { sendNotificationAdmin, sendNotificationHotels, markNotificationsAsRead };
