<template>
  <div class="w-full flex flex-col gap items-center">
    <div class="flex flex-col gap-2 items-center w-full text-left" v-if="!isLoading">
      <div class="grid grid-cols-[1fr,1fr,0.3fr] gap-y-4 items-center w-full font-bold text-crewfareGreen">
        <div>Name</div>
        <div>Last Updated</div>
        <div>Actions</div>
      </div>
      <Divider class="mb-2" />
      <div class="grid grid-cols-[1fr,1fr,0.3fr] gap-y-4 items-center w-full" v-for="group in groups">
        <div class="text-base">{{ group.name }}</div>
        <div class="text-base">{{ formatDate(group.updated_at) }}</div>
        <div class="flex gap-4">
          <img src="/icons/i-pencil.svg" @click="() => editUserGroup(group.id)" class="cursor-pointer w-4" />
          <img src="/icons/i-change.svg" class="cursor-pointer w-4" />
        </div>
      </div>
      <Button class="w-fit self-end mt-4" @click="addUserGroup">Add Group</Button>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import Button from '@/components/ui/atoms/Button.vue';
import Divider from '@/components/ui/atoms/Divider.vue';
import Loading from '@/components/ui/atoms/Loading.vue';
import { userGroupsApi } from '@/utils/apis/userGroupsApi';
import { getDateObjFromFirebaseDate } from '@/utils/dateUtils';

export default {
  data() {
    return {
      isLoading: true,
      groups: [],
    };
  },
  components: {
    Divider,
    Button,
    Loading,
  },
  methods: {
    formatDate(date) {
      return getDateObjFromFirebaseDate(date).toDateString();
    },
    async getUserGroups() {
      this.groups = await userGroupsApi.list().then(res => res.data);
      this.isLoading = false;
      return;
    },
    addUserGroup() {
      this.$router.push({ path: '/settings/user-groups/editor' });
    },
    editUserGroup(id) {
      this.$router.push({ path: '/settings/user-groups/editor', query: { userGroupId: id } });
    },
  },
  mounted() {
    this.getUserGroups();
  },
};
</script>
