<template>
  <AdminInternal
    v-if="isCrewfare"
  />
  <NSO
    v-if="['nso', 'lead manager'].includes(account.type.toLowerCase())"
  />
  <GroupManager
    v-if="['group manager'].includes(account.type.toLowerCase())"
  />
  <Contact
    v-if="['contact', undefined].includes(account.type.toLowerCase())"
  />
</template>

<script>
import AdminInternal from "@/components/files/lists/AdminInternal.vue";
import NSO from "@/components/files/lists/NSO.vue";
import GroupManager from "@/components/files/lists/GroupManager.vue";
import Contact from "@/components/files/lists/Contact.vue";
import { isCrewfareAccount } from "@/utils/account";
export default {
  components: {
    AdminInternal,
    NSO,
    GroupManager,
    Contact,
  },
  computed: {
    isCrewfare(){
      return isCrewfareAccount(this.account);
    },
    account() {
      return this.$store.state.account;
    },
  },
};
</script>
