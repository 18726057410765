<template>
  <Loading class="self-center mt-24" v-if="isLoading" />
  <div class="w-full flex flex-col gap-4 p-4 text-white" v-else>
    <div class="text-xl text-left font-bold">{{ editorTitle }}</div>
    <div class="grid grid-cols-[1fr,1fr,1fr] gap-4 items-start w-full">
      <Input v-model="groupName" :placeholder="'Type Group Name'" :title="'Group Name'" />
      <Input
        v-model="adminEmail"
        :placeholder="'Type Email'"
        :title="'Primary Admin Email'"
        @update:modelValue="adminEmailChanged"
        :isError="!canUseEmail"
        :errorMessage="errorMessage"
      />
      <Input
        v-model="adminName"
        :placeholder="'Type Name'"
        :title="'Primary Admin Name'"
        :is-error="!checkedEmailData.hasAccount && checkedEmail && !adminName"
        :error-message="
          !checkedEmailData.hasAccount && checkedEmail && !adminName ? 'Name is required for new admin account' : ''
        "
        v-if="!checkedEmailData.hasAccount && checkedEmail"
      />
    </div>
    <div class="grid grid-cols-[1fr,1fr,1fr] gap-4 items-center w-full"></div>
    <div class="grid grid-cols-[1fr,1fr,1fr] gap-4 items-start w-full">
      <div
        class="flex flex-col justify-start gap-4 items-start bg-crewfareBlack p-4 rounded-lg h-[200px] overflow-y-auto"
      >
        <div class="text-base font-medium">Templates</div>
        <Loading v-if="!templates.length" class="self-center" />
        <CheckboxText
          v-for="template in templates"
          v-else
          :key="template.id"
          :value="template.id"
          :label="template.name"
          :modelValue="selectedTemplates.includes(template.id)"
          @update:modelValue="updateSelectedTemplates($event, template.id)"
        />
      </div>
      <Select
        v-model="defaultTemplate"
        :title="'Default Template'"
        v-if="selectedTemplates.length"
        :key="selectedTemplates"
        :options="options"
        :selected="defaultTemplate"
      >
      </Select>
    </div>
    <div class="flex self-end gap-2">
      <Button class="w-fit" :isDisabled="!checkRequiredFields" @click="save">Save</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/atoms/Button.vue';
import CheckboxText from '@/components/ui/atoms/CheckboxText.vue';
import Input from '@/components/ui/atoms/Input.vue';
import Loading from '@/components/ui/atoms/Loading.vue';
import Select from '@/components/ui/atoms/Select.vue';
import { templatesApi } from '@/utils/apis/templatesApi';
import { userGroupsApi } from '@/utils/apis/userGroupsApi';

export default {
  components: {
    Input,
    Button,
    Select,
    Loading,
    CheckboxText,
  },
  props: {
    userGroupId: String,
  },
  data() {
    return {
      groupName: '',
      adminEmail: '',
      adminName: '',
      checkedEmailData: {
        hasAccount: false,
        isAdmin: false,
      },
      templates: [],
      selectedTemplates: [],
      defaultTemplate: {},
      checkedEmail: false,
      isLoading: true,
    };
  },
  async mounted() {
    this.templates = await templatesApi.list().then(res => res.data);
    if (this.userGroupId) {
      await this.setInit();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    updateSelectedTemplates(value, id) {
      this.defaultTemplate = '';
      if (value) {
        this.selectedTemplates.push(id);
      } else {
        const index = this.selectedTemplates.indexOf(id);
        if (index !== -1) {
          this.selectedTemplates.splice(index, 1);
        }
      }
    },
    async checkAdminInfo() {
      this.checkedEmailData = await userGroupsApi.checkAdminEmail(this.adminEmail).then(res => res.data);
      this.checkedEmail = true;
    },
    adminEmailChanged() {
      this.checkedEmail = false;
      this.checkedEmailData = {
        hasAccount: false,
        isAdmin: false,
      };
    },
    async save() {
      this.isLoading = true;
      const data = {
        name: this.groupName,
        adminEmail: this.adminEmail,
        adminName: this.adminName,
        defaultTemplate: this.defaultTemplate.value,
        templates: this.selectedTemplates,
      };

      await this.checkAdminInfo();

      if (!this.canUseEmail || (!this.checkedEmailData.hasAccount && !this.adminName)) {
        this.isLoading = false;
        return;
      }

      if (this.userGroupId) {
        await userGroupsApi.update(this.userGroupId, data).then(res => {
          if (res.error) {
            this.isLoading = false;
            alert(res.message || 'There was an error updating the user group');
            return;
          }
          this.$router.push({ name: 'settingsUserGroups' });
        });
        return;
      }

      await userGroupsApi.create(data).then(res => {
        if (res.error) {
          this.isLoading = false;
          alert(res.message || 'There was an error creating the user group');
          return;
        }
        this.$router.push({ name: 'settingsUserGroups' });
      });
    },
    async setInit() {
      if (this.userGroupId) {
        const userGroup = await userGroupsApi.get(this.userGroupId).then(res => res.data);
        this.groupName = userGroup.name;
        this.adminEmail = userGroup.adminEmail;
        this.adminName = userGroup.adminName;
        this.selectedTemplates = userGroup.templates;
        this.defaultTemplate = userGroup.defaultTemplate;
        await this.checkAdminInfo();
      }
      this.isLoading = false;
    },
  },
  computed: {
    checkRequiredFields() {
      return (
        this.groupName &&
        this.adminEmail &&
        (this.checkedEmail && !this.checkedEmailData.hasAccount ? !!this.adminName : true) &&
        this.selectedTemplates.length > 0 &&
        this.defaultTemplate
      );
    },
    canUseEmail() {
      return this.checkedEmail && this.checkedEmailData.hasAccount ? this.checkedEmailData.isAdmin : true;
    },
    errorMessage() {
      return this.checkedEmail && this.checkedEmailData.hasAccount && !this.checkedEmailData.isAdmin
        ? 'User has some other role than admin'
        : 'Email needs to be checked first';
    },
    editorTitle() {
      return this.userGroupId ? 'Edit User Group' : 'Add User Group';
    },
    options() {
      return this.selectedTemplates.map((templateId) => {
        const template = this.templates.find(t => t.id === templateId);
        return {
          value: templateId,
          name: template ? template.name : '',
        };
      });
    },
  },
};
</script>