<template>
  <div class="flex flex-col gap-2 rounded-md p-4 bg-crewfareGreyDark overflow-y-scroll h-[200px]">
    <div class="text-lg font-bold">Hint</div>
    <div class="flex flex-col gap-2">
      <p class="text-base">You can use the following variables in your template:</p>
        <div v-for="hint in hints" class="grid grid-cols-[1fr,1fr,auto] items-center">
          <span class="text-crewfareYellow">{{ `${leftBrackets}${hint.fieldName}${rightBrackets}` }}</span>
          <span class="text-base"> {{ hint.description }}</span>
          <Button class="w-fit" @click="() => insertText(`${leftBrackets}${hint.fieldName}${rightBrackets}`)">Insert field</Button>
        </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/atoms/Button.vue';
import Divider from '@/components/ui/atoms/Divider.vue';
import { CONTRACT_HINTS } from '@/constants/hints.contants';

export default {
  data() {
    return {
      hints: CONTRACT_HINTS,
      leftBrackets: '{{',
      rightBrackets: '}}',
    };
  },
  props: {
    insertText: Function,
  },
  components: {
    Button,
    Divider
  },
};
</script>
