import Dashboard from '@/layouts/Dashboard.vue';
import UserGroupMenu from './user-group/UserGroupMenu.vue';
import TemplateMenu from './template/TemplateMenu.vue';
import TemplateEditor from './template/TemplateEditor.vue';
import UserGroupEditor from './user-group/UserGroupEditor.vue';

export const settings = [
  {
    path: '/settings',
    name: 'settings',
    component: Dashboard,
    props: true,
    children: [
      {
        path: 'templates',
        name: 'settingsTemplates',
        component: TemplateMenu,
        props: true,
      },
      {
        path: 'templates/editor',
        name: 'settingsEditor',
        component: TemplateEditor,
        props: (route: { query: { templateId: any } }) => ({
          templateId: route.query.templateId,
          isNewTemplate: route.query.templateId ? false : true,
        }),
      },
      {
        path: 'user-groups',
        name: 'settingsUserGroups',
        component: UserGroupMenu,
        props: true,
      },
      {
        path: 'user-groups/editor',
        name: 'settingsUserGroupEditor',
        component: UserGroupEditor,
        props: (route: { query: { userGroupId: string } }) => ({
          userGroupId: route.query.userGroupId,
        }),
      },
    ],
  },
];
