<template>
    <div class="flex flex-col gap-2">
      <div>
        <input
          type="search"
          class="w-full bg-gray-700 px-4 py-2 rounded-full"
          v-model="search"
          @input="doSearch"
          placeholder="Search"
        />
      </div>
      <div v-if="loading" class="flex items-center justify-center">
        <Loading />
      </div>
      <button
        v-else
        class="hover:bg-gray-800 bg-gray-900 rounded-lg w-full py-3 text-left px-4 flex flex-col"
        v-for="(recipient, index) in recipients"
        :key="index"
        @click="sendMessage(recipient)"
      >
        <span>{{ recipient.title }}</span>
        <span class="text-xs">&lt;{{ recipient.subtitle || '' }}&gt;</span>
      </button>
    </div>
    <Pagination
      v-if="total > 0"
      :actual="currentPage"
      :max="per_page"
      :total="total"
      @togglePage="togglePage"
    />
  </template>
<script>
import { messagesApi } from '@/utils/apis/messagesApi';
import Loading from '../ui/atoms/Loading.vue';
import { debounce } from '@/utils/formatter';
import Pagination from '../default/Pagination.vue';

export default {
    components: {
        Loading,
        Pagination
    },
    data(){
        return {
            search: "",
            recipients: [],
            loading: false,
            currentPage: 0,
            total: 0,
            per_page: 100,
            controller: new AbortController(),
        }
    },
    computed: {
      selectedGroupId(){
        return this.$store.state.groups.selectedId;
      },
    },
    watch: {
        selectedGroupId(){
            this.currentPage = 0;
            this.debounceList();
        }
    },
    async mounted(){
        this.loading = true
        this.debounceList();
        this.loading = false;
    },
    methods: {
        togglePage(page){
            this.currentPage = page;
            this.debounceList();
        },
        doSearch(){
            this.currentPage = 0
            this.debounceList();
        },
        async sendMessage(recipient){
            const response = await messagesApi.createMessage({
                recipient
            });
            if(response.error){
                alert(response.message || "Some error occurred sending message");
                return;
            }
            this.$store.commit("setMessageOpened", response.data);
        },
        debounceList: debounce(async function(){
            this.controller.abort();
            this.controller = new AbortController();
            const currentSignal = this.controller.signal;
            this.refreshList(currentSignal);
        }, 500),
        async refreshList(currentSignal){
            this.loading = true;
            const params = {
                search: this.search,
                page: this.currentPage,
                per_page: this.per_page
            }
            const recipients = await messagesApi.listRecipients(params, currentSignal);
            if(currentSignal !== this.controller.signal){
                return;
            }
            if(recipients.error){
                alert(recipients.error || "Some error occurred fetching recipients");
                this.loading = false;
                return;
            }
            this.loading = false;
            const recipientsData = recipients.data;
            this.recipients = recipientsData.data;
            this.currentPage = recipientsData.page;
            this.total = recipientsData.total;
        }
    }
}
</script>
