import store from '@/store';
import { userGroupsApi } from './apis/userGroupsApi';
async function setGroups() {
  const selectedGroupId = store.state.groups?.selectedId;
  store.commit('setGroupLoading', true);
  const groups = await userGroupsApi.list();
  if (groups.error) {
    console.error(groups.message || 'Some error occured in fetching groups');
  } else {
    const groupsData = groups.data;
    const selectedGroup = groupsData.find(group => group.id === selectedGroupId);
    if (!selectedGroup) {
      await store.commit('setGroupId', groupsData[0].id);
    }
    await store.commit('setGroups', groupsData);
  }
}

export { setGroups };
