<template>
<dropdown-menu @opened="isOpen=true" @closed="isOpen=false" :isOpen="isOpen" class="relative">
    <template #trigger>
        <button class="flex items-center pr-3 text-sm text-white transition duration-150 ease-in-out bg-[#23262F] border-2 border-gray-200 rounded-full focus:outline-none focus:shadow-solid w-[200px]">
            <span class="relative rounded-full w-[34px] h-[34px] bg-[#6707FD] text-white flex justify-center items-center">
                {{ selectedOptionText[0] }}
            </span>
            <span class="flex-1 justify-between ml-2 text-white flex justify-center items-center">
                <span>{{ selectedOptionText }}</span>
                <span class="material-symbols-rounded"> expand_more </span>
            </span>
        </button>
    </template>
    <template #body>
        <ul class="bg-[#23262F] absolute top-[40px] z-[100] right-0 p-4 rounded-lg w-[250px] -ml-[360px] text-white z-30 flex flex-col">
            <li @click="()=>handleSelection(option)" v-for="option in options" :key="`group-${option.value}`">
                <a class="block px-4 py-2 text-sm leading-5 text-white-700 transition duration-150 ease-in-out hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100" @click.prevent="() => {}" href="#">{{ option.text }}</a>
            </li>
        </ul>
    </template>
</dropdown-menu>
</template>

<script>
import DropdownMenu from "v-dropdown-menu"
export default {
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        selected: String,
        defaultText: {
            type: String,
            default: "Select an option",
        }
    },
    components: {
        DropdownMenu,
    },
    computed: {
        initials(){
            return this.$store.getters.userInitials
        },
        selectedOptionText(){
            return this.options.find(option => option.value === this.selected)?.text || this.defaultText
        }
    },
    data() {
        return {
            isOpen: false,
        }
    },
    methods: {
        handleSelection(option){
            this.isOpen = false
            this.$emit("change", option.value)
        }
    }
}
</script>
