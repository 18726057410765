import { BaseApi, getToken } from './apiFactory';

class UserGroupsApi extends BaseApi {
  async checkAdminEmail(email: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/check-admin-user`,
      method: 'POST',
      data: { email },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export const userGroupsApi = new UserGroupsApi({ name: 'user-groups' });
