<template>
    <Modal :close="closeModal" :innerClass="'min-w-[800px]'">
        <div class="flex w-full justify-center gap-4">
            <div>
                <p class="text-xl font-semibold my-4">{{ title }} to: </p>
                <form @submit.prevent="assignChain">
                <Select placeholder="Select Chain" class="w-full" v-model="chain" :options="options">
                </Select>
                <Button class="my-4">
                    <Spinner v-if="loading"/>
                    <span v-else>Merge</span>
                </Button>
            </form>
            </div>
        </div>
    </Modal>
</template>
<script>
import { title } from 'process';
import Button from '../ui/atoms/Button.vue';
import Modal from '../ui/atoms/Modal.vue';
import Select from '../ui/atoms/Select.vue';
import { hotelsApi } from '@/utils/apis/hotelsApi';
import Spinner from '../ui/atoms/Spinner.vue';

export default {
    props: {
        chains: Array,
        selectedIds: Set,
        title: String,
    },
    components: {
        Button,
        Modal,
        Select,
        Spinner
    },
    data() {
        return {
            chain: {},
            search: '',
            loading: false,
        }
    },
    computed:{
        options(){
            return this.chains.map((chain) => {
                return {
                    value: chain.id,
                    name: chain.name,
                }
            })
        }
    },
    methods: {
        async assignChain(){
            if(!this.chain.value || this.loading) return;
            this.loading = true;
            const chainId = this.chain.value;
            const selectedHotelIds = Array.from(this.selectedIds)
            const updated = await hotelsApi.mergeToChain(chainId, selectedHotelIds)
            if(!updated.error){
                this.$emit('close', true)
            }
            else{
                alert(updated.message || 'Error merging hotels')
            }
            this.loading = false;
        },
        closeModal() {
            this.$emit('close');
        },
    }
}
</script>