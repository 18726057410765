<template>
  <div class="flex flex-col gap-4">
    <div class="text-white text-base font-medium">{{ title }}</div>
    <div class="flex gap-2 px-4 py-4 border border-crewfareGrey rounded-lg">
      <img
        v-for="index in 5"
        :key="index"
        class="cursor-pointer"
        :style="{ opacity: index <= currentRating ? 1 : 0.5 }"
        src="/icons/i-star.svg"
        @click="updateRating(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    title: String,
    modelValue: {
      type: Number,
      default: 0,
    },
  },

  emits: ['update:modelValue'],

  computed: {
    currentRating() {
      return this.modelValue;
    }
  },

  methods: {
    updateRating(rating: number) {
      this.currentRating = rating;
      this.$emit('update:modelValue', rating);
    },
  },
};
</script>

