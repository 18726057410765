<template>
  <div
    v-if="rfp && hotel && account"
    class="bg-gray-800 w-full pb-4 flex flex-col stage-height print:text-black"
  >
    <div class="print:hidden sticky z-10 top-0 p-4 bg-gray-900">
      <div class="text-xl text-white gap-4 flex items-center justify-between">
        <div class="flex gap-2">
          <button
            v-if="!isHotel"
            @click="$router.go(-1)"
            class="material-symbols-rounded hover:text-crewfareGreen print:hidden"
          >
            chevron_left
          </button>
          RFP - {{ rfp.data().name }} - {{ rfp.data().hotel_name }} - CC
          Authorization
        </div>
        <div class="flex gap-4 text-sm">
          <a
            v-if="rfp.data().authorizationLink"
            :href="rfp.data().authorizationLink"
            target="_blank"
            class="text-sm whitespace-nowrap"
          >
            <span v-if="downloadingContract" class="flex items-center gap-2">
              <span class="material-symbols-rounded animate-spin text-[14px]">
                autorenew
              </span>
              Downloading authorization
            </span>
            <span v-else class="underline">Download authorization</span>
          </a>
        </div>
      </div>
      <div
        class="bg-gray-900 py-2 flex justify-between text-white items-center"
      >
        <div class="flex gap-4"></div>
        <div class="flex gap-4 flex-col justify-end">
          <div class="flex gap-4">
            <a
              v-if="authorization_url"
              :href="authorization_url"
              target="_blank"
              class="disabled:text-crewfareGreen disabled:bg-gray-600 disabled:border-gray-600 flex gap-2 text-crewfareGreen flex items-center border rounded py-1 px-2 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 relative"
              download
            >
              <span class="material-symbols-rounded"> download </span>
              Download current file
            </a>
            <button
              :disabled="upload"
              @click="$refs['fileUpload'].click()"
              class="disabled:text-crewfareGreen disabled:bg-gray-600 disabled:border-gray-600 flex gap-2 text-crewfareGreen flex items-center border rounded py-1 px-2 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 relative"
            >
              <span
                class="material-symbols-rounded"
                :class="upload && 'animate-spin'"
              >
                {{ upload ? "autorenew" : "upload" }}
              </span>
              Upload new PDF/DOC File
            </button>
          </div>
          <div class="flex justify-end w-full text-right">
            <button
              @click="showAuthorizationHistoryDialog = true"
              class="underline text-sm"
            >
              View history
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="authorization_url">
      <iframe
        :src="
          isPDF
            ? authorization_url
            : `https://docs.google.com/gview?url=${authorization_url}&embedded=true`
        "
        frameborder="0"
        height="600px"
        width="100%"
      ></iframe>
    </div>
    <input
      type="file"
      ref="fileUpload"
      id="fileUpload"
      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      @change="uploadFile"
      class="cursor-pointer fixed -left-[100vw]"
    />
    <div v-if="!authorization_url" class="py-12 px-4 text-center text-white">
      To start our authorization process, you need to upload the cc
      authorization file
      <span
        class="underline cursor-pointer"
        @click="$refs['fileUpload'].click()"
      >
        clicking here
      </span>
      or at the button at the top bar.
    </div>
    <div
      v-if="authorization_url && !isFullySigned"
      class="flex gap-4 items-center mt-4 px-4 justify-end print:hidden"
    >
      <div class="flex gap-4 items-center">
        <span
          v-if="loadingSigned"
          class="border rounded px-4 rounded px-4 flex gap-2 border-gray-600 bg-gray-600 text-gray-200"
        >
          <span class="flex items-center">
            <span class="material-symbols-rounded animate-spin">
              autorenew
            </span>
          </span>
          Signing Contract
        </span>
        <span v-if="!hasBeenSigned" @click="accept" class="green-button">
          <span v-if="signingContract" class="flex items-center">
            <span class="material-symbols-rounded animate-spin">
              autorenew
            </span>
          </span>
          Mark authorization as done
        </span>
      </div>
    </div>
    <div
      v-if="hasBeenSigned && !isFullySigned"
      class="text-white text-center py-2"
    >
      You have mark as done this authorization
    </div>
    <div
      v-if="isFullySigned"
      class="text-center mt-4 flex items-center gap-4 flex-col justify-center"
    >
      <div class="text-white">This authorization is fully done.</div>
    </div>
    <AuthorizationHistoryDialog
      v-if="showAuthorizationHistoryDialog"
      :authorizationHistory="rfp.data().authorizationHistory"
      @hideGroupDialog="showAuthorizationHistoryDialog = false"
    />
  </div>
</template>

<script>
import router from "@/router";
import { firestore } from "@/utils/firebase";
import {
  doc,
  getDoc,
  query,
  where,
  collection,
  getDocs,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  sendNotificationAdmin,
  sendNotificationHotels,
} from "@/utils/notifications.jsx";
import { setStatusHistory } from "@/utils/rfp.jsx";
import AuthorizationHistoryDialog from "./AuthorizationHistoryDialog.vue";
import { ResumeDialog } from "@/components/rfp";
import { NotificationTypes } from "@/enums/NotificationTypes";
import { ViewsLocations } from "@crewfare/server-shared";

export default {
  components: {
    AuthorizationHistoryDialog,
    ResumeDialog,
  },
  data() {
    return {
      showAuthorizationHistoryDialog: false,
      authorization_url: null,
      showMessage: true,
      savingFields: false,
      error: false,
      crewfareHasSigned: false,
      signingContract: false,
      hotelHasSigned: false,
      downloadingContract: false,
      contract_content: null,
      crewfare_signature: " ",
      hotel_signature: "",
      hotel_name: "",
      loadingAccept: true,
      message: null,
      rfp: null,
      hotel: null,
      authorization: [],
      contract: "",
      commentButton: false,
      selection: "",
      loadingSigned: false,
      terms: "",
      upload: false,
    };
  },
  computed: {
    isPDF() {
      return this.authorization_url.indexOf(".pdf") >= 0;
    },
    hasBeenSigned() {
      if (!this.rfp.data().signed) return false;
      if (this.isHotel) return this.rfp.data().signed.includes("hotel");
      else return this.rfp.data().signed.includes("crewfare");
    },
    isSigned() {
      return this.rfp.data().status.toLowerCase() === "cc auth complete";
    },
    isFullySigned() {
      return this.authorization.length === 2;
    },
    account() {
      return this.$store.state.account;
    },
    isHotel() {
      if (!this.account) return;
      return ["contact"].includes(this.account.type.toLowerCase());
    },
  },
  async mounted() {
    this.getData();
    document.title = `Launchpad - Crewfare - RFP - CC Authorization`;
  },
  methods: {
    uploadFile() {
      var file = document.getElementById("fileUpload").files[0];
      if (file) {
        const storage = getStorage();
        const uuid = uuidv4();
        const name = file.name;
        const ext = name.split(".").reverse()[0];
        this.storageRef = ref(storage, `${uuid}.${ext}`);
        this.upload = true;
        uploadBytes(this.storageRef, file).then(() => {
          getDownloadURL(this.storageRef).then(async (url) => {
            const authorizationHistory =
              this.rfp.data().authorizationHistory || [];
            authorizationHistory.push({
              url,
              account: this.account,
              created_at: new Date(),
            });
            let data = this.rfp.data();
            data.authorization_url = url;
            data.authorizationHistory = authorizationHistory;
            data = await setStatusHistory({
              data,
              content: "CC Authorization Uploaded",
              status: data.status,
            });
            await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
            this.authorization_url = url;
            this.upload = false;
          });
        });
      }
    },
    async getData() {
      const rfpRef = doc(firestore, "rfp_hotel", this.$route.params.rfp);
      this.rfp = await getDoc(rfpRef);

      const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
      this.hotel = await getDoc(hotelRef);
      this.authorization_url = this.rfp.data().authorization_url;
      this.hotel_name = this.rfp.data().hotel_name || "";
      this.authorization = this.rfp.data().authorization || [];
    },
    async accept() {
      if (
        !confirm(
          "You are about to mark this authorization as done, have you upload the updated file already?"
        )
      )
        return;
      this.error = false;
      this.signingContract = true;
      let data = this.rfp.data();
      let message = "";
      let pdfLink = "";

      if (this.isHotel) {
        this.authorization.push("hotel");
        data = {
          ...data,
          authorization: this.authorization,
          status:
            this.authorization.length === 2
              ? "CC Auth Complete"
              : "CC Auth Pending",
          hotel_authorization_date: new Date(),
        };
        message = `${this.account.name} (${
          this.hotel.data().name
        }) just marked as done the cc authorization for ${
          this.rfp.data().name
        }`;
        sendNotificationAdmin({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          content: message,
          to: { name: ViewsLocations.CONTRACT, params: { id: this.rfp.id } },
        });
      } else {
        this.authorization.push("crewfare");
        data = {
          ...data,
          authorization: this.authorization,
          status:
            this.authorization.length === 2
              ? "CC Auth Complete"
              : "CC Auth Pending",
          crewfare_authorization_date: new Date(),
        };
        message = `Crewfare just marked as done the cc authorization for ${
          this.rfp.data().name
        }`;
        sendNotificationHotels({
          type: NotificationTypes.MESSAGE,
          ids: [this.rfp.data().hotel_id],
          to: { name: ViewsLocations.CONTRACT, params: { id: this.rfp.id } },
          content: message,
        });
      }

      this.loadingSigned = false;
      if (this.isHotel) {
        this.$store.commit("setToast", {
          content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">The authorization is done! </div>
              </div>`,
        });
        router.push({
          name: "ccAuthorizationDone",
          params: { rfp: this.rfp.id },
        });
      } else {
        this.$store.commit("setToast", {
          content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">The authorization for ${
                  this.rfp.data().name
                } is done!</div>
              </div>`,
        });
        router.push({
          name: "rfpsHotels",
          params: { id: this.rfp.data().rfp },
        });
      }
      this.signingContract = false;
    },
  },
};
</script>
