import { AnyObject } from 'chart.js/dist/types/basic';
import { BaseApi, getToken } from './apiFactory';

class AccountsApi extends BaseApi {
  async me() {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/me`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async createPassword(body: AnyObject) {
    return this.axios({
      url: `${this.apiUrl}/password`,
      method: 'POST',
      data: body,
    });
  }

  async addUpdateContact({ name, email, phone, hotelId }: any, alertOnError = false) {
    const token = await getToken();
    return this.axios(
      {
        url: `${this.apiUrl}/hotels/${hotelId}/contact`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { name, email, hotelId, phone },
      },
      alertOnError,
    );
  }
  deleteContact = async (contactId: string, hotelId: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/hotels/${hotelId}/contact/${contactId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  requestPasswordReset(email: string) {
    return this.axios({
      url: `${this.apiUrl}/request-password-reset`,
      method: 'POST',
      data: { email },
    });
  }
}

export const accountsApi = new AccountsApi({ name: 'account' });
