import { BaseApi, getToken } from './apiFactory';

class HotelsApi extends BaseApi {
  listStates = async (search: string, signal: any) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/states?search=${search}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        signal,
      },
    });
  };

  listChains = async () => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  listAccountChains = async () => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains/account`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  mergeToChain = async (chainId: string, hotelIds: string[]) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains/${chainId}/merge`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        hotelIds,
      },
    });
  };
  checkIfHotelsExist = async (name: string, address: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/check`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name,
        address,
      },
    });
  };
  listChainStats = async () => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chain-stats`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  getChainById = async (id: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

export const hotelsApi = new HotelsApi({ name: 'hotels' });
