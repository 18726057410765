<template>
  <button
    @click="accountMessage"
    class="text-crewfareGreen hover: flex gap-3 items-center whitespace-nowrap justify-between"
  >
    {{ rfp.data().created_by }}
    <span class="material-symbols-rounded text-[18px]">chat</span>
  </button>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { query, collection, where, getDocs } from "firebase/firestore";
import { sendRFPMessage } from "@/utils/messages.jsx";

export default {
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  props: ["rfp"],
  data() {
    return {
      created_by: null,
    };
  },
  async mounted() {
    const qAccounts = query(
      collection(firestore, "accounts"),
      where("name", "==", this.rfp.data().created_by)
    );
    const account = await getDocs(qAccounts);
    this.created_by = account.docs[0];
  },
  methods: {
    accountMessage() {
      sendRFPMessage(this.rfp.data());
      this.$emit("toggleMessage");
    },
  },
};
</script>
