<template>
  <div class="flex items-center gap-2 cursor-pointer">
    <input type="checkbox" v-model="model" :value="value" class="hidden" />
    <div class="w-6 h-6 rounded bg-crewfareGrey flex items-center justify-center" @click="toggleInput">
      <img src="/icons/i-check.svg" v-if="model" />
    </div>
    <div @click="toggleInput">{{ label }}</div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    modelValue: { type: [Array, Boolean] },
    value: { type: [Boolean, Object] },
    label: { type: String },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value: any) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  emits: ['update:modelValue'],
  methods: {
    toggleInput() {
      this.$emit('update:modelValue', !this.modelValue);
    }
  },
};
</script>
