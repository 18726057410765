<template>
  <div class="pt-8 text-white mt-8 border-t">
    <p class="mb-2">
      Select the notifications that you want to receive.
    </p>
    <div class="flex flex-col gap-4 mt-4">
      <!-- <div class="flex flex-col gap-2">
        <p class="font-bold">Emails</p>
        <div class="flex flex-row gap-4 text-base text-white">
          <label
            v-for="(item, index) in emails"
            :key="index"
            class="flex gap-2 items-center"
          >
            <input v-model="item.value" type="checkbox" />
            {{ item.name }}
          </label>
        </div>
      </div> -->
      <div class="flex flex-col gap-2">
        <p class="font-bold">Notifications</p>
        <div class="flex flex-row gap-3 flex-wrap">
          <label
            v-for="(item, index) in notifications"
            :key="index"
            class="flex gap-2 items-center"
          >
            <input v-model="item.value" type="checkbox" />
            {{ item.name }}
          </label>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <button @click="save" class="green-button gap-2">
        <span
          v-if="loading"
          class="material-symbols-rounded animate-spin flex items-center"
        >
          autorenew
        </span>
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { NotificationTypes } from "@/enums/NotificationTypes";
import { updateAccount } from "@/utils/account.jsx";

export default {
  data() {
    return {
      loading: false,
      notifications: null,
      emails: null,
      notificationsDefault: [
        {
          name: "New RFP",
          value: true,
          type: NotificationTypes.NEW_RFP,
        },
        {
          name: "RFP Status Changes",
          value: true,
          type: NotificationTypes.RFP_STATUS_CHANGE
        },
        {
          name: "New messages",
          value: true,
          type: NotificationTypes.MESSAGE
        },
        {
          name: "Contract signed",
          value: true,
          type: NotificationTypes.CONTRACT_SIGNED
        },
        {
          name: "Users",
          value: true,
          type: NotificationTypes.USERS
        }
      ],
      emailsDefault: [
        {
          name: "New RFP",
          value: true,
        },
        {
          name: "RFP Status Changes",
          value: true,
        },
        {
          name: "New messages",
          value: true,
        },
        {
          name: "Contract signed",
          value: true,
        },
      ],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  mounted() {
    this.notifications = this.notificationsDefault.map(setting=>{
      if(!this.account.notifications){
        return setting;
      }
      return {
            ...setting,
            value: this.account.notifications.includes(setting.type)
          }
      });
    this.emails = this.account.emails || this.emailsDefault;
  },
  methods: {
    async save() {
      this.loading = true;
      await updateAccount({
          notifications: this.notifications.filter(_=>_.value).map(_=>_.type),
          emails: this.emails,
      });
      this.$store.commit("setToast", {
        content: `<p>Account updated</p>`,
      });
      this.loading = false;
    },
  },
};
</script>
