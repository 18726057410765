<template>
  <button @click="toogleDialog" :class="class">
    <span
      v-if="loading"
      class="material-symbols-rounded animate-spin text-[12px]"
    >
      autorenew
    </span>
    {{ text }}
    <span
      v-if="has_message"
      class="bg-red-400 rounded-full text-white text-[10px] absolute -top-[12px] right-0 px-4 h-[18px] w-[18px] flex items-center justify-center"
    >
      new
    </span>
  </button>
</template>

<script>
import { firestore } from "@/utils/firebase";
import { RECIPIENTS } from "@crewfare/commons/src/model/enumerators/messages";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getProposal } from "@/utils/proposals";

export default {
  props: [
    "text",
    "type",
    "subtitle",
    "title",
    "id",
    "rfp",
    "field",
    "hotel_id",
    "no_default_classes",
    "parentRFP"
  ],
  data() {
    return {
      message: null,
      loading: false,
      has_message: null,
      proposal: null,
    };
  },
  computed: {
    class() {
      let default_classes = !this.no_default_classes ? "flex gap-2": "";
      if (this.type === "Comment on concession")
        return default_classes +"green-button-small relative";
      if (this.type === "Notes on RFP")
        return default_classes + "text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full justify-between";
      return default_classes
    },
  },
  async mounted() {
    if(this.rfp && !this.parentRFP) {
      this.proposal = await getProposal(this.rfp);
    }
    const messageData = await getDoc(doc(firestore, "messages", this.id));
    if (messageData.exists()) {
      this.message = messageData;
      if (this.message.data())
        this.has_message = this.message.data().new_message;
    }
  },
  methods: {
    async toogleDialog() {
      if (!this.message) {
        this.loading = true;
        const data = {
          title: this.title,
          type: this.type,
          hotel_id: this.hotel_id || "",
          subtitle: this.subtitle,
          created_at: new Date(),
          rfp: this.rfp || "",
          proposal_id: this.rfp || "",
          field: this.field || "",
          recipient: this.parentRFP ? RECIPIENTS.RFP: RECIPIENTS.PROPOSAL,
          last_interaction: new Date(),
          parentRFP: this.parentRFP?.id || "",
          initiatorGroupId: this.parentRFP?.userGroupId || this.proposal?.userGroupId || "",
        };
        await setDoc(doc(firestore, "messages", this.id), data);
        const messageRef = doc(firestore, "messages", this.id);
        const messageData = await getDoc(messageRef);
        this.message = messageData;
        this.loading = false;
        this.$store.commit("setNotesOpened", messageData);
      } else {
        const messageData = await getDoc(doc(firestore, "messages", this.id));
        this.$store.commit("setNotesOpened", messageData);
      }
    },
  },
};
</script>
