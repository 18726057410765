<template>
  <div class="bg-gray-900 w-full p-4 flex flex-col gap-4 stage-height">
    <h1 class="text-xl text-white">Notifications</h1>
    <div class="flex flex-col gap-2 bg-gray-800 rounded pb-4 relative">
      <div class="divide-y divide-gray-700 text-white">
        <div v-if="loading">
          <Spinner />
        </div>
        <ListItem
          v-else
          v-for="notification in notifications"
          :notification="notification"
          :key="notification.id"
        />
        <div class="mt-4">
          <Pagination
            :actual="actual"
            :max="per_page"
            :total="total"
            @togglePage="togglePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  collection,
  where,
  query,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import ListItem from "@/components/notifications/ListItem.vue";
import Pagination from "@/components/default/Pagination.vue";
import { debounce } from "@/utils/formatter";
import { notificationsApi } from "@/utils/apis/notificationsApi";
import Spinner from "@/components/ui/atoms/Spinner.vue";

export default {
  components: {
    ListItem,
    Pagination,
    Spinner
  },
  data() {
    return {
      actual: 0,
      notifications: [],
      controller: new AbortController(),
      per_page: 30,
      total: 0,
      loading: false
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  async mounted() {
    this.getNotifications();
    document.title = `Launchpad - Crewfare - Notifications`;
  },
  methods: {
    async getData(signal, alertOnErr){
      let options = {
        page: this.actual,
        per_page: this.per_page
      }
      this.loading=true;
      const notificationsRes = await notificationsApi.list(options, signal)
      if(notificationsRes.error){
        alert(notificationsRes.message || 'An error occurred while fetching notifications')
        this.loading = false;
        return;
      }
      if(signal === this.controller.signal){
        this.notifications = notificationsRes.data.data;
        this.total = notificationsRes.data.total;
        this.loading = false;
      }
    },
    getNotifications: debounce(async function(alertOnErr=false){
      this.controller.abort();
      this.controller = new AbortController();
      this.getData(this.controller.signal, alertOnErr);
    }),
    togglePage(page) {
      this.actual = page;
      this.getNotifications();
    },
  },
};
</script>
