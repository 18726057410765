<template>
  <div class="flex flex-col text-white p-6 gap-4">
    <div class="flex flex-col gap-2 bg-crewfareGreyDark p-4 rounded-md">
      <div class="text-base font-bold">User Groups</div>
      <UserGroupList />
    </div>
  </div>
</template>

<script>
import UserGroupList from './UserGroupList.vue';

export default {
  components: {
    UserGroupList,
  },
};
</script>
