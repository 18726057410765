<template>
    <div class="flex items-center">
    <span
          v-if="selected"
          class="text-gray-800 bg-crewfareGreen rounded w-[18px] h-[18px] flex items-center justify-center"
        >
          <img src="/icons/small-check.svg" />
        </span>
        <span v-else class="border rounded w-[18px] h-[18px]"></span>
    </div>
</template>
<script>
export default {
    props: ["selected"],
};
</script>