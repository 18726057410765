<template>
  <span class="material-symbols-rounded animate-spin text-crewfareGreen" :class="sizeClass"> autorenew </span>
</template>

<script>
export default {
  props: {
    size: Number,
  },

  computed: {
    sizeClass() {
      return this.size ? `w-${this.size} h-${this.size} text-[${this.size * 4}px]` : 'w-6 h-6';
    },
  },
};
</script>
