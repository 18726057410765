<template>
  <div v-if="account" class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <h1 class="text-xl text-white">Hotels</h1>
      <div class="flex items-center gap-4">
        <router-link :to="{ name: 'groupHotelAdd' }" class="green-button">
          Add hotel
        </router-link>
        <button @click="exportHotels" class="green-button gap-2">
          <span class="material-symbols-rounded"> download </span>
          Export Hotels
        </button>
        <button @click="$refs['fileUpload'].click()" class="green-button gap-2">
          <span
            class="material-symbols-rounded"
            :class="importing && 'animate-spin'"
          >
            {{ importing ? "autorenew" : "upload" }}
          </span>
          {{ importing ? `Importing Hotels` : "Upload Hotels" }}
        </button>
        <input
          type="file"
          ref="fileUpload"
          id="fileUpload"
          @change="importHotels"
          class="cursor-pointer fixed -left-[100vw]"
        />
      </div>
    </div>
    <div class="flex flex-col gap-2 rounded pb-4 relative">
      <div
        class="flex p-4 items-center bg-gray-800 justify-between sticky top-0"
      >
        <div class="flex gap-2 items-center text-crewfareGreen">
          <strong class="rounded-full bg-black px-3 py-1">{{
            hotelsFound
          }}</strong>
          Hotels
        </div>
        <Filters
          v-if="hotels.length > 0"
          :filteredHotels="filteredHotels"
          @filter="filter"
        />
      </div>
      <div>
        <div v-if="!loading">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 px-4">
            <ListItemHotel
              v-for="hotel in sliceHotels"
              :key="hotel.id"
              :hotel="hotel"
              @getData="getData"
            />
          </div>
          <Pagination
            :max="16"
            :actual="actual"
            :total="filteredHotels.length"
            @togglePage="togglePage"
          />
        </div>
        <div v-else class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 px-4">
          <ListItemSkeleton v-for="item in range" :key="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  getDocs,
  query,
  collection,
  where,
  documentId,
} from "firebase/firestore";
import { ListItemHotel, ListItemSkeleton } from "@/components/group";
import { Filters } from "@/components/hotels";
import { importHotels, exportHotels, filterHotels } from "@/utils/hotel.jsx";
import Pagination from "@/components/default/Pagination.vue";
import {getHotelsIdsByGroup} from "@/utils/hotel";

export default {
  components: {
    ListItemHotel,
    ListItemSkeleton,
    Pagination,
    Filters,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    sliceHotels() {
      const sliceStart = this.actual * 15;
      return this.filteredHotels.slice(sliceStart, sliceStart + 15);
    },
    hotelsFound() {
      return this.filteredHotels.length;
    },
    hotelImporting() {
      return this.$store.state.hotelImporting;
    },
  },
  data() {
    return {
      importing: false,
      search: "",
      actual: 0,
      range: [...Array(3).keys()],
      loading: false,
      loadingMore: false,
      hotels: [],
      filteredHotels: [],
      linesToImport: null,
      linesProccessed: 0,
      report: {},
    };
  },
  watch: {
    hotelImporting() {
      this.importing = this.hotelImporting;
      if (!this.hotelImporting) this.getData();
    },
    search() {
      if (this.search.length > 3) {
        this.filteredHotels = this.hotels.filter(
          (hotel) =>
            hotel
              .data()
              .name.toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0
        );
      } else {
        this.filteredHotels = this.hotels;
      }
      this.actual = 0;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    togglePage(page) {
      this.actual = page;
    },
    async importHotels() {
      this.importing = true;
      const file = document.getElementById("fileUpload").files[0];
      await importHotels(file);
      this.getData();
      this.importing = false;
    },
    async exportHotels() {
      exportHotels(this.hotels);
    },
    async getData() {
      this.loading = true;
      const hotel_ids = await getHotelsIdsByGroup();
      const qHotels = query(
        collection(firestore, "hotels"),
        where(documentId(), "in", hotel_ids)
      );
      const hotels = await getDocs(qHotels);
      const hotelsDocs = hotels.docs;
      this.hotels = this.filteredHotels = hotelsDocs;
      document.title = `Launchpad - Crewfare - Hotels`;
      this.loading = false;
    },
    filter(filters) {
      this.filteredHotels = filterHotels(this.hotels, filters);
    },
  },
};
</script>
