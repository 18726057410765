<template>
  <div class="flex flex-col gap-1 w-full">
    <label
      class="text-sm font-bold flex justify-between items-center mb-2"
      :class="has_changes && 'text-yellow-500'"
    >
      <div class="flex gap-2 items-center">
        <span
          class="material-symbols-rounded text-[16px]"
          :class="checked ? 'text-crewfareGreen' : 'text-white'"
        >
          {{ checked ? "check_box" : "check_box_outline_blank" }}
        </span>
        Group Rate Honored:
      </div>

      <div class="flex gap-4 print:hidden">
        <ButtonNotesDialog
          v-if="!isNSO"
          type="Comment on concession"
          :subtitle="rfp.name"
          title="Comments at Group Date Honored"
          :id="`${rfp.id}-group-date-honored`"
          :rfp="rfp.id"
          field="Group Date Honored"
          :hotel_id="rfp.hotel_id"
          text="Comment"
        />
        <span
          v-if="is_locked"
          class="text-[18px] flex items-center px-4 print:px-0 material-symbols-rounded text-crewfarePurple py-1"
        >
          lock
        </span>
      </div>
    </label>
    <select
      :disabled="is_locked || is_blocked"
      class="w-full bg-gray-700 h-[40px] px-4 rounded"
      v-model="group_date_honored"
      @change="save_group_date_honored"
      :class="
        has_groupDateHonored_changes
          ? 'bg-yellow-500 text-gray-900'
          : 'bg-gray-700 text-white print:text-black'
      "
    >
      <option value="">Select</option>
      <option>Yes</option>
      <option>No</option>
    </select>

    <div v-if="group_date_honored === 'No'" class="flex gap-4 mt-2">
      <div class="flex gap-2 items-center bg-gray-700 justify-center rounded">
        <span class="flex px-2 items-center justify-center">$</span>
        <input
          :disabled="is_blocked || is_locked"
          type="text"
          @change="save_group_date_honored_pre_rate"
          v-model="group_date_honored_pre_rate"
          placeholder="Pre Rate"
          class="px-4 print:px-0 pl-0 bg-transparent w-full text-right"
          :class="
              has_groupDateHonored_pre_rate_changes
                ? 'bg-yellow-500 text-gray-900'
                : 'bg-gray-700 text-white print:text-black'
            "
        />
      </div>
      <div class="flex gap-2 items-center bg-gray-700 justify-center rounded">
        <span class="flex px-2 items-center justify-center">$</span>
        <input
          :disabled="is_blocked || is_locked"
          type="text"
          @change="save_group_date_honored_post_rate"
          v-model="group_date_honored_post_rate"
          placeholder="Post Rate"
          class="px-4 print:px-0 pl-0 bg-transparent w-full text-right"
          :class="
              has_groupDateHonored_post_rate_changes
                ? 'bg-yellow-500 text-gray-900'
                : 'bg-gray-700 text-white print:text-black'
            "
        />
      </div>
    </div>
    <div class="flex gap-4 flex-col">
      <div class="flex flex-col gap-2">
        <label 
          class="flex gap-1 w-full items-center"
          :class="
              has_groupDateHonored_pre_changes
                ? 'text-yellow-500'
                : ''"
          >
          <input
            type="checkbox"
            @change="save_group_date_honored_pre"
            :disabled="is_blocked || is_locked"
            v-model="group_date_honored_pre"
            :class="
              has_groupDateHonored_pre_changes
                ? 'bg-yellow-500'
                : ''
            "
          />
          <span>Pre Event</span>
        </label>
        <label
          v-if="group_date_honored_pre"
          class="text-sm font-bold flex justify-between items-center mb-2"
        >
          Days Pre Event
        </label>
        <select
          v-if="group_date_honored_pre"
          :disabled="is_blocked || is_locked"
          class="w-full bg-gray-700 h-[40px] px-4 rounded"
          v-model="group_date_honored_pre_days"
          @change="save_group_date_honored_pre_days"
          :class="
            has_groupDateHonored_pre_days_changes
              ? 'bg-yellow-500 text-gray-900'
              : 'bg-gray-700 text-white print:text-black'
          "
        >
          <option value="">Select days</option>
          <option>Seven (7) Days</option>
          <option>Six (6) Days</option>
          <option>Five (5) Days</option>
          <option>Four (4) Days</option>
          <option>Three (3) Days</option>
          <option>Two (2) Days</option>
          <option>One (1) Day</option>
          <option>No Days</option>
        </select>
      </div>
      <div class="flex flex-col gap-2">
        <label 
          class="flex gap-1 w-full items-center" 
          :class="
              has_groupDateHonored_post_changes
                ? 'text-yellow-500'
                : ''">
          <input
            type="checkbox"
            @change="save_group_date_honored_post"
            :disabled="is_blocked || is_locked"
            v-model="group_date_honored_post"
            :class="
              has_groupDateHonored_post_changes
                ? 'bg-yellow-500'
                : ''
            "
          />
          <span>Post Event</span>
        </label>
        <label
          v-if="group_date_honored_post"
          class="text-sm font-bold flex justify-between items-center mb-2"
        >
          Days Post Event
        </label>
        <select
          v-if="group_date_honored_post"
          :disabled="is_blocked || is_locked"
          class="w-full bg-gray-700 h-[40px] px-4 rounded"
          v-model="group_date_honored_post_days"
          @change="save_group_date_honored_post_days"
          :class="
            has_groupDateHonored_post_days_changes
              ? 'bg-yellow-500 text-gray-900'
              : 'bg-gray-700 text-white print:text-black'
          "
        >
          <option value="">Select days</option>
          <option>Seven (7) Days</option>
          <option>Six (6) Days</option>
          <option>Five (5) Days</option>
          <option>Four (4) Days</option>
          <option>Three (3) Days</option>
          <option>Two (2) Days</option>
          <option>One (1) Day</option>
          <option>No Days</option>
        </select>
      </div>
    </div>
    <small v-if="has_changes" class="text-yellow-500">{{ changes_by }}</small>
  </div>
</template>

<script>
import { noteAdded, noteDone } from "@/utils/messages.jsx";
import {
  saveField,
  checkIsLocked,
  checkHasChanges,
  getChangesBy,
} from "@/utils/rfp.jsx";
import ButtonNotesDialog from "@/components/default/ButtonNotesDialog.vue";

export default {
  props: ["rfp", "account", "is_blocked"],
  components: {
    ButtonNotesDialog,
  },
  data() {
    return {
      group_date_honored: "",
      group_date_honored_post: "",
      group_date_honored_post_rate: "",
      group_date_honored_post_days: "",
      group_date_honored_pre: "",
      group_date_honored_pre_rate: "",
      group_date_honored_pre_days: "",
      type: "",
      is_locked: false,
      changes_by: null,
      has_changes: null,
      has_groupDateHonored_changes: null,
      has_groupDateHonored_post_changes: null,
      has_groupDateHonored_post_rate_changes: null,
      has_groupDateHonored_post_days_changes: null,
      has_groupDateHonored_pre_changes: null,
      has_groupDateHonored_pre_rate_changes: null,
      has_groupDateHonored_pre_days_changes: null,
    };
  },
  computed: {
    checked() {
      return this.group_date_honored === this.rfp.groupDateHonored;
    },
    rfpSession() {
      return this.$store.state.rfpSession;
    },
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
  },
  watch: {
    rfpSession() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.group_date_honored = this.rfp.groupDateHonored;
      this.group_date_honored_post = this.rfp.groupDateHonoredPost;
      this.group_date_honored_post_rate =
        this.rfp.groupDateHonoredPostRate;
      this.group_date_honored_post_days =
        this.rfp.groupDateHonoredPostDays;
      this.group_date_honored_pre = this.rfp.groupDateHonoredPre;
      this.group_date_honored_pre_rate =
        this.rfp.groupDateHonoredPreRate;
      this.group_date_honored_pre_days =
        this.rfp.groupDateHonoredPreDays;
      if (this.account) {
        const account_type = this.account.type.toLowerCase();
        this.has_groupDateHonored_changes = checkHasChanges(
          this.rfp,
          "groupDateHonored",
          account_type
        );
        this.has_groupDateHonored_post_changes = checkHasChanges(
          this.rfp,
          "groupDateHonoredPost",
          account_type
        );
        this.has_groupDateHonored_post_rate_changes = checkHasChanges(
          this.rfp,
          "groupDateHonoredPostRate",
          account_type
        );
        this.has_groupDateHonored_post_days_changes = checkHasChanges(
          this.rfp,
          "groupDateHonoredPostDays",
          account_type
        );
        this.has_groupDateHonored_pre_changes = checkHasChanges(
          this.rfp,
          "groupDateHonoredPre",
          account_type
        );
        this.has_groupDateHonored_pre_rate_changes = checkHasChanges(
          this.rfp,
          "groupDateHonoredPreRate",
          account_type
        );
        this.has_groupDateHonored_pre_days_changes = checkHasChanges(
          this.rfp,
          "groupDateHonoredPreDays",
          account_type
        );
        this.has_changes =
          checkHasChanges(this.rfp, "groupDateHonored", account_type) ||
          checkHasChanges(this.rfp, "groupDateHonoredPost", account_type) ||
          checkHasChanges(
            this.rfp,
            "groupDateHonoredPostRate",
            account_type
          ) ||
          checkHasChanges(
            this.rfp,
            "groupDateHonoredPostDays",
            account_type
          ) ||
          checkHasChanges(this.rfp, "groupDateHonoredPreDays", account_type) ||
          checkHasChanges(
            this.rfp,
            "groupDateHonoredPreRate",
            account_type
          ) ||
          checkHasChanges(
            this.rfp,
            "groupDateHonoredPreDays",
            account_type
          );
        this.is_locked = checkIsLocked(this.rfp, "groupDateHonored");
        if (this.has_changes) {
          this.changes_by = getChangesBy(this.has_changes, this.rfp);
        }
      }
    },
    async note_done() {
      noteDone("Group Date Honored", this.rfp);
      this.$emit("refreshData");
    },
    async note_added() {
      noteAdded("Group Date Honored", this.rfp);
      this.$emit("refreshData");
    },
    async save_group_date_honored() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Group Date Honored",
          before: this.rfp.groupDateHonored,
          after: this.group_date_honored || "",
        },
        {
          groupDateHonored: this.group_date_honored || "",
        },
        "groupDateHonored"
      );
      this.$emit("refreshData");
    },
    async save_group_date_honored_post() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Group Date Honored Post",
          before: this.rfp.groupDateHonoredPost,
          after: this.group_date_honored_post || "",
        },
        {
          groupDateHonoredPost: this.group_date_honored_post || "",
        },
        "groupDateHonoredPost"
      );
      this.$emit("refreshData");
    },
    async save_group_date_honored_post_rate() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Group Date Honored Post Rate",
          before: this.rfp.groupDateHonoredPostRate,
          after: this.group_date_honored_post_rate || "",
        },
        {
          groupDateHonoredPostRate: this.group_date_honored_post_rate || "",
        },
        "groupDateHonoredPostRate"
      );
      this.$emit("refreshData");
    },
    async save_group_date_honored_post_days() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Group Date Honored Post Days",
          before: this.rfp.groupDateHonoredPostDays,
          after: this.group_date_honored_post_days || "",
        },
        {
          groupDateHonoredPostDays: this.group_date_honored_post_days || "",
        },
        "groupDateHonoredPostDays"
      );
      this.$emit("refreshData");
    },
    async save_group_date_honored_pre() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Group Date Honored Pre",
          before: this.rfp.groupDateHonoredPre,
          after: this.group_date_honored_pre || "",
        },
        {
          groupDateHonoredPre: this.group_date_honored_pre || "",
        },
        "groupDateHonoredPre"
      );
      this.$emit("refreshData");
    },
    async save_group_date_honored_pre_rate() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Group Date Honored Pre Rate",
          before: this.rfp.groupDateHonoredPreRate,
          after: this.group_date_honored_pre_rate || "",
        },
        {
          groupDateHonoredPreRate: this.group_date_honored_pre_rate || "",
        },
        "groupDateHonoredPreRate"
      );
      this.$emit("refreshData");
    },
    async save_group_date_honored_pre_days() {
      if (this.is_blocked) return;
      await saveField(
        this.rfp,
        {
          label: "Group Date Honored Pre Days",
          before: this.rfp.groupDateHonoredPreDays,
          after: this.group_date_honored_pre_days || "",
        },
        {
          groupDateHonoredPreDays: this.group_date_honored_pre_days || "",
        },
        "groupDateHonoredPreDays"
      );
      this.$emit("refreshData");
    },
  },
};
</script>
