<template>
  <div v-if="message" class="w-full flex flex-col flex-grow">
    <div class="text-xl text-white gap-4 p-2 flex items-center justify-between">
      <div class="flex flex-col">
        <div class="text-xl text-white p-2 flex items-center justify-between">
          <span class="flex items-center gap-2">
            {{ message?.title || hotel?.data()?.name }}
          </span>
        </div>
        <p v-if="rfp" class="text-sm text-gray-200 px-2">
          RFP: {{ rfp.data().name }}
        </p>
      </div>
    </div>
    <div class="flex flex-col flex-grow">
      <div
        ref="messageList"
        class="flex-grow flex flex-col gap-4 flex-grow h-full overflow-auto p-2 chat-holder"
      >
        <ListItem
          v-for="message in messages"
          :message="message"
          :key="message.id"
        />
      </div>
      <Form @addFile="addFile" @addChat="addChat" :message="message" />
    </div>
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  query,
  collection,
  where,
  orderBy,
} from "firebase/firestore";
import { Form, ListItem } from "@/components/messages/chat";
import { addChat, addFile } from "@/utils/messages.jsx";
import { messagesApi } from "@/utils/apis/messagesApi";
import { isCrewfareAccount } from "@/utils/account";

export default {
  components: {
    ListItem,
    Form,
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  data: () => {
    return {
      message: null,
      rfp: null,
      messages: [],
      hotel: null,
    };
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.getMessage();
      }
    },
  },
  async mounted() {
    this.getMessage();
  },
  methods: {
    async getMessage() {
      const messageRes = await messagesApi.get(this.$route.params.id);
      if(messageRes.error){
        alert(messageRes.message||"Error getting message");
        return;
      }
      this.message = messageRes.data;
      if(isCrewfareAccount(this.account)){
        const messageGroupId = this.message.toUserGroup || this.message.initiatorGroupId;
        this.$store.commit("setGroupId", messageGroupId);
        const accountId = this.account?.id;
        if(!this.message.accountIds?.[accountId]){
            this.$emit("refreshFilteredMine", false);
        }
      }
      if (this.message.hotel_id) {
        const hotelRef = doc(firestore, "hotels", this.message.hotel_id);
        this.hotel = await getDoc(hotelRef);
        document.title = `Launchpad - Crewfare - Chat - ${
          this.hotel.data()?.name
        }`;
      } else {
        document.title = `Launchpad - Crewfare - Chat - ${
          this.message.title
        }`;
      }
      await setDoc(doc(firestore, "messages", this.message.id), {
        ...this.message,
        new_message: false,
      });
      if (this.message.proposal_id) {
        const rfpHotelRef = doc(
          firestore,
          "rfp_hotel",
          this.message.proposal_id
        );
        this.rfp = await getDoc(rfpHotelRef);
      }
      this.getData();
    },
    async getData() {
      onSnapshot(
        query(
          collection(firestore, "chat"),
          where("message_id", "==", this.$route.params.id),
          orderBy("created_at", "asc")
        ),
        (doc) => {
          this.messages = doc.docs;
          setTimeout(() => {
            this.$refs.messageList.scrollTop =
              this.$refs.messageList?.scrollHeight || 0;
          }, 200);
        }
      );
    },
    async addChat(content) {
      addChat(this.account, this.message, content);
    },
    async addFile({ url, name }) {
      addFile(this.account, this.message, url, name);
    },
  },
};
</script>
