<template>
  <div class="flex flex-col gap-4">
    <div class="text-white text-base font-medium">{{ title }}</div>
    <div
      :disabled="isDisabled"
      class="box-border min-w-[255px] rounded-[8px] px-4 py-3 text-sm hover:border-crewfarePurple appearance-none border-2 focus:outline-none font-normal text-white focus:ring-0 focus:outline-none focus:border-transparent w-full relative cursor-pointer"
      :placeholder="placeholder"
      :class="currentClass"
      @click="showOptions = !showOptions"
      :value="modelValue"
    >
      {{ modelValue?.name || 'Select an option' }}
      <img src="/icons/i-arrow-down.svg" class="w-3 h-3 absolute right-4 top-1/2 transform -translate-y-1/2" />

      <div
        v-if="showOptions"
        class="absolute w-full max-h-[300px] left-0 top-[55px] bg-crewfareGrey rounded-[8px] z-[999] overflow-y-auto"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          :class="[
            'py-3 px-4 cursor-pointer hover:bg-crewfareGreyDark',
            option.value === modelValue?.value && 'border-l-4 border-crewfareGreen',
          ]"
          @click="updateSelection(option)"
        >
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    title: String,
    placeholder: String,
    isError: Boolean,
    isLocked: Boolean,
    isDisabled: Boolean,
    errorMessage: String,
    modelValue: Object,
    selected: String || Number,
    options: Array<{
      name: String;
      value: String | Number;
    }>,
  },
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    currentClass() {
      return {
        'bg-crewfareGrey border-transparent': !this.isError && !this.isLocked,
        'bg-crewfareRed bg-opacity-[4%] border-crewfareRed': this.isError,
        'bg-crewfareGrey hover:border-transparent border-transparent': this.isLocked,
        'bg-crewfareGrey text-crewfareGrey cursor-not-allowed border-crewfareGreyLighter hover:border-crewfareGreyLighter':
          this.isDisabled,
      };
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateSelection(value: any, toggleOptions = true) {
      // For some reason this needs to be set to true to close the dropdown
      if (!toggleOptions) this.showOptions = true;
      this.$emit('update:modelValue', value);
    },
    updateSelectionIfMatch(options: any[]) {
      options.forEach((option: { value: string | number }) => {
        if (option.value === this.selected) {
          this.updateSelection(option, true);
        }
      });
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(newOptions) {
        if (newOptions && this.selected) {
          this.updateSelectionIfMatch(newOptions);
        }
      },
    },
    selected: {
      immediate: true,
      handler(newSelected) {
        if (this.options && newSelected) {
          this.updateSelectionIfMatch(this.options);
        }
      },
    },
  },
};
</script>
