<template>
  <div class="w-full flex flex-col gap items-center">
    <div class="flex flex-col gap-2 items-center w-full text-left" v-if="!isLoading">
      <div class="grid grid-cols-[1fr,1fr,0.3fr] gap-y-4 items-center w-full font-bold text-crewfareGreen">
        <div>Name</div>
        <div>Last Updated</div>
        <div>Actions</div>
      </div>
      <Divider class="mb-2" />
      <div class="grid grid-cols-[1fr,1fr,0.3fr] gap-y-4 items-center w-full" v-for="template in templates">
        <div class="text-base">{{ template.name }}</div>
        <div class="text-base">{{ formatDate(template.updated_at) }}</div>
        <div class="flex gap-4">
          <img src="/icons/i-pencil.svg" class="cursor-pointer w-4" @click="() => editTemplate(template)" />
          <img src="/icons/i-change.svg" class="cursor-pointer w-4" @click="() => showLastChangesModal(template)" />
        </div>
      </div>
      <Button :passed-function="addTemplate" class="w-fit self-end mt-4">Add Template</Button>
    </div>
    <Loading v-else />
  </div>
  <LastChanges v-if="showLastChanges" :close-modal="closeModal" :template="modalData" />
</template>

<script>
import Button from '@/components/ui/atoms/Button.vue';
import Loading from '@/components/ui/atoms/Loading.vue';
import { templatesApi } from '@/utils/apis/templatesApi';
import { getDateObjFromFirebaseDate } from '@/utils/dateUtils';
import Divider from '@/components/ui/atoms/Divider.vue';
import LastChanges from '../LastChanges.vue';

export default {
  data() {
    return {
      templates: [],
      isLoading: true,
      showLastChanges: false,
      modalData: null,
    };
  },
  components: {
    Button,
    Loading,
    LastChanges,
    Divider,
  },
  methods: {
    editTemplate(template) {
      this.$router.push({ path: '/settings/templates/editor', query: { templateId: template.id } });
    },
    async deleteTemplate(template) {
      await templatesApi.remove(template.id);
      this.getAllTemplates();
    },
    addTemplate() {
      this.$router.push({ path: '/settings/templates/editor' });
    },
    async getAllTemplates() {
      this.templates = await templatesApi.list().then(res => res.data);
      this.isLoading = false;
    },
    formatDate(date) {
      return getDateObjFromFirebaseDate(date).toDateString();
    },
    showLastChangesModal(template) {
      this.modalData = template;
      this.showLastChanges = true;
    },
    closeModal() {
      this.showLastChanges = false;
    },
  },
  mounted() {
    this.getAllTemplates();
  },
};
</script>
