const contractExecutedEmailContent = `
                <tr>
                  <td style="width: 100%;border-radius: 8px;background: rgba(34, 197, 94, 0.04);border: 1px dashed rgba(34, 197, 94, 1);padding: 48px 24px;text-align:center; ">
                    <table style="margin: 0 auto 40px;">
                      <tr>
                        <td valign="top">
                          <table style="margin: 0 auto;">
                            <tr>
                              <td valign="center" align="center" style="width: 60px;height:60px;background: rgba(34, 197, 94, 0.08);border-radius:40px;">
                                <img src="https://dev-api-launchpad.crewfare.com/email/email-check.png" />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <div style="margin-top:24px; font: bold 24px Poppins, Helvetica, Arial;color: #292929;">Contract executed!</div>
                    <div style="margin-top: 24px;font: bold 14px Poppins, Helvetica, Arial;color: #292929;">
                    The ##name## contract for ##hotel_name## has just been signed by both sides!</div>
                  </td>
                </tr>
                <tr>
                  <td height="24px"></td>
                </tr>
                <tr>
                  <td style="width: 100%;border-radius: 8px;background: #F5F5F5;padding:24px;text-align:center;">
                    <div style="font: 14px Poppins, Helvetica, Arial;color: #292929;">Click the button below to view the contract.</div>
                    <a href="##link##"
                      style="border-radius: 8px;font: bold 16px Poppins, Helvetica, Arial;display:block;color: #fff;background: #4323FF;text-align: center;max-width: 180px;width:100%;margin: 24px auto 0;text-decoration: none;padding: 16px 18px;">View Contract</a>

                    <div style="font: 14px Poppins, Helvetica, Arial;color: #292929;margin-top: 24px;">Introducing our Crewfare team who will be your contacts moving forward!</div>
                    <table class="isDesktop" width="100%" style="margin-top:24px">
                      <tr>
                      <td width="50%" style="border-radius: 8px;background: #fff; padding: 17px;text-align:center;" valign="top">
                      <div style="font: bold 14px Poppins, Helvetica, Arial;color: #292929;">Carolina Garcia</div>
                      <div style="margin-top:10px;font: 14px Poppins, Helvetica, Arial;color: #292929;">Operations Manager</div>
                      <a href="mailto:carolina@crewfare.com" style="display:block;margin-top:10px;font: 14px Poppins, Helvetica, Arial;color: #4323FF;">carolina@crewfare.com</a>
                      </td>
                      <td style="min-width:16px"></td>
                      <td width="50%" style="border-radius: 8px;background: #fff; padding: 17px;text-align:center;" valign="top">
                      <div style="font: bold 14px Poppins, Helvetica, Arial;color: #292929;">Tony Tohme</div>
                      <div style="margin-top:10px;font: 14px Poppins, Helvetica, Arial;color: #292929;">Director of Finance & Accounting</div>
                      <a href="mailto:accounting@crewfare.com" style="display:block;margin-top:10px;font: 14px Poppins, Helvetica, Arial;color: #4323FF;">accounting@crewfare.com</a>
                      </td>
                      </tr>
                    </table>
                    <table class="isMobile" width="100%" style="margin-top:24px;">
                      <tr>
                      <td width="100%" style="border-radius: 8px;background: #fff; padding: 17px;text-align:center;" valign="top">
                      <div style="font: bold 14px Poppins, Helvetica, Arial;color: #292929;">Carolina Garcia</div>
                      <div style=" margin-top:10px;font: 14px Poppins, Helvetica, Arial;color: #292929;">Operations Manager</div>
                      <a href="mailto:carolina@crewfare.com" style=" display:block;margin-top:10px;font: 14px Poppins, Helvetica, Arial;color: #4323FF;">carolina@crewfare.com</a>
                      </td>
                      </tr>
                      <tr>
                        <td height="16px"></td>
                      </tr>
                      <tr>
                      <td width="100%" style="border-radius: 8px;background: #fff; padding: 17px;text-align:center;" valign="top">
                      <div style="font: bold 14px Poppins, Helvetica, Arial;color: #292929;">Tony Tohme</div>
                      <div style="margin-top:10px;font: 14px Poppins, Helvetica, Arial;color: #292929;">Director of Finance & Accounting</div>
                      <a href="mailto:accounting@crewfare.com" style="display:block;margin-top:10px;font: 14px Poppins, Helvetica, Arial;color: #4323FF;">accounting@crewfare.com</a>
                      </td>
                      </tr>
                    </table>
                    <div style="margin-top:24px;font: 14px Poppins, Helvetica, Arial;color: #292929; margin-top:24px;">Please loop in the direct contact from your team as well that will be handling the room reservations.</div>
                    <div style="margin-top:24px;font: 14px Poppins, Helvetica, Arial;color: #292929; margin-top:24px;">Please direct all credit card authorizations to <a href="mailto:accounting@crewfare.com" style="font: 14px Poppins, Helvetica, Arial;color: #4323FF;">accounting@crewfare.com</a>.<br/> Please contact Tony for approval before proceeding with deposit payments and/or group charges.</div>
                    <div style="margin-top:24px;font: 12px Poppins, Helvetica, Arial;color: #292929;">If you have any questions, please reply to this email to reach our team and we look forward to working together!</div>
                  </td>
                </tr>`;

const contractExecutedSubject = 'Crewfare’s Launchpad - Contract Executed - ##name## - ##hotel_name##';

export { contractExecutedEmailContent, contractExecutedSubject };
