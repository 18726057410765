export enum MESSAGE_TYPES {
  MESSAGE = 'message',
  CONCESSIONS = 'Comment on concession',
  NOTES = 'Notes on RFP',
  ALL = 'all',
}

export enum RECIPIENTS {
  NSO = 'nso',
  CHAIN = 'chain',
  CREWFARE = 'crewfare',
  HOTEL = 'hotel',
  PROPOSAL = 'proposal',
  RFP = 'rfp',
  DIRECT = 'direct',
}
