import { BaseApi, getToken } from './apiFactory';

class TemplatesApi extends BaseApi {
  async listAllowed(userGroupId: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/allowed`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        groupid: userGroupId,
      },
    });
  }
}

export const templatesApi = new TemplatesApi({ name: 'templates' });
