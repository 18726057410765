import { AnyObject } from '@crewfare/commons/src/model';
import { BaseApi, generateQueryParams, getToken } from './apiFactory';

class MessagesApi extends BaseApi {
  listRecipients = async (params: AnyObject, signal: any) => {
    const token = await getToken();
    return this.axios(
      {
        method: 'GET',
        url: `${this.apiUrl}/recipients?${generateQueryParams(params)}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      signal,
    );
  };
  createMessage = async (data: AnyObject, signal: any) => {
    const token = await getToken();
    return this.axios({
      method: 'POST',
      url: `${this.apiUrl}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal,
      data,
    });
  };
}

export const messagesApi = new MessagesApi({ name: 'messages' });
